import { convertLatitudeDmToDdd } from './LatitudeUtils';
import { convertLongitudeDmToDdd } from './LongitudeUtils';
import { processSolutionType } from './SolutionTypes';

export function convertGgaToDecodedPositionMessage(message: string[]) {
  let errors = false;
  let latitude = '0.0';
  let longitude = '0.0';
  let altitude = 0;
  let solution = 'No Solution';
  let satellitesNumber = 0;
  let age = 0;
  if (
    typeof message[2] !== 'undefined' &&
    typeof message[3] !== 'undefined' &&
    message[2] !== '' &&
    message[3] !== ''
  ) {
    latitude = convertLatitudeDmToDdd(message[2], message[3]);
  } else {
    errors = true;
  }

  if (
    !errors &&
    typeof message[4] !== 'undefined' &&
    typeof message[5] !== 'undefined' &&
    message[4] !== '' &&
    message[5] !== ''
  ) {
    longitude = convertLongitudeDmToDdd(message[4], message[5]);
  } else {
    errors = true;
  }

  if (
    !errors &&
    typeof message[9] !== 'undefined' &&
    typeof message[11] !== 'undefined' &&
    message[9] !== '' &&
    message[11] !== ''
  ) {
    altitude = Number(message[9]) + Number(message[11]);
  } else {
    errors = true;
  }

  if (!errors && typeof message[6] !== 'undefined') {
    solution = processSolutionType(Number(message[6]));
  } else {
    errors = true;
  }

  if (!errors && typeof message[7] !== 'undefined' && message[7] !== '') {
    satellitesNumber = Number(message[7]);
  } else {
    errors = true;
  }

  if (!errors && typeof message[13] !== 'undefined') {
    age = Number(message[13]) || 0;
  } else {
    errors = true;
  }
  return {
    errors,
    latitude,
    longitude,
    altitude,
    solution,
    satellitesNumber,
    age,
    time: message[1]
  };
}
