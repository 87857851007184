export const sleep = async (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getSignedByte = (bits: number, value: number) =>
  value && 1 << (bits - 1) ? value - (1 << bits) : value;

export const toBytesInt32 = (int: number): Uint8Array => {
  const arr = new ArrayBuffer(4);
  const view = new DataView(arr);
  view.setInt32(0, int, true);
  return new Uint8Array(arr);
};

export const isMobile = (): boolean => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }

  return false;
};
