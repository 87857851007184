import {
  GpsActions,
  GpsTypes,
  Satellites,
  PositionMessageAction,
  DopsMessageAction,
  SatellitesMessageAction,
  MacAddrAction
} from '../types/Gps';
import { WebsocketTypes } from '../types/Websocket';
import { convertLatitudeDmToDdd } from './LatitudeUtils';
import { convertLongitudeDmToDdd } from './LongitudeUtils';
import { processSolutionType } from './SolutionTypes';
// eslint-disable-next-line import/no-cycle
import store from '../index';
import { convertGgaToDecodedPositionMessage } from './ProcessMessage';

class NmeaDecoder {
  message: string[];

  decodedPositionMessage: PositionMessageAction | null;

  decodedDopsMessage: DopsMessageAction | null;

  macAddr: MacAddrAction | null;

  errors: {
    latitudeError: string;
    longitudeError: string;
    altitudeError: string;
  };

  satelliteList: {
    GPS: Satellites[];
    GLONASS: Satellites[];
    GALILEO: Satellites[];
    BEIDOU: Satellites[];
  };

  decodedSatelliteMessage: SatellitesMessageAction | null;

  constructor(message: string) {
    this.message = message.substring(0, message.indexOf('*')).split(',');
    this.decodedPositionMessage = null;
    this.decodedDopsMessage = null;
    this.errors = {
      latitudeError: '0',
      longitudeError: '0',
      altitudeError: '0'
    };
    this.macAddr = null;
    const savedSatellite = localStorage.getItem('satelliteList');
    if (savedSatellite) {
      this.satelliteList = JSON.parse(savedSatellite);
    } else {
      this.satelliteList = {
        GPS: [],
        GLONASS: [],
        GALILEO: [],
        BEIDOU: []
      };
    }
    this.decodedSatelliteMessage = null;
  }

  processMessage(): GpsActions | null {
    if (this.message[0].includes('GGA') || this.message[0].includes('GST')) {
      if (this.message[0].includes('GGA')) {
        localStorage.setItem('GgaMessage', JSON.stringify(this.message));
      } else {
        this.processGstMessage();
        return this.decodedPositionMessage;
      }
    }
    if (this.message[0].includes('GSA')) {
      this.processGsaMessage();
      return this.decodedDopsMessage;
    }
    if (this.message[0].includes('GSV')) {
      this.processGsvMessage();
      return this.decodedSatelliteMessage;
    }
    if (this.message[0].includes('XMB')) {
      this.processXMBMessage();
      return this.macAddr;
    }
    return null;
  }

  private processXMBMessage(): void {
    try {
      if (typeof this.message[1] !== 'undefined' && this.message[1] !== '')
        this.macAddr = {
          type: GpsTypes.MAC_ADDR,
          payload: {
            addr: String(this.message[1])
          }
        };
      if (typeof this.message[2] !== 'undefined' && this.message[2] !== '') {
        if (this.message[2] === '1')
          store.dispatch({
            type: WebsocketTypes.FIX_BASE_HOTSPOT
          });
        if (this.message[2] === '0')
          store.dispatch({
            type: WebsocketTypes.UNFIX_BASE_HOTSPOT
          });
      }

      if (typeof this.message[3] !== 'undefined' && this.message[3] !== '') {
        if (this.message[3] === '1')
          store.dispatch({
            type: WebsocketTypes.FIX_BASE_NTRIP
          });
        if (this.message[3] === '0')
          store.dispatch({
            type: WebsocketTypes.UNFIX_BASE_NTRIP
          });
      }

      interface Position {
        latitude: string;
        longitude: string;
        altitude: string;
        data: string;
        dist: string;
      }

      const positionsPayload = { positions: [] as Position[] };

      for (let i = 4; i <= 24; i += 8) {
        if (this.message[i] !== undefined && this.message[i] !== '') {
          const position: Position = {
            latitude: `${this.message[i]}+-${this.message[i + 1]}`,
            longitude: `${this.message[i + 2]}+-${this.message[i + 3]}`,
            altitude: `${this.message[i + 4]}+-${this.message[i + 5]}`,
            data: `${this.message[i + 6]}`,
            dist: `${this.message[i + 7]}`
          };
          positionsPayload.positions.push(position);
        }
      }
      if (
        positionsPayload.positions.every(position => position !== undefined)
      ) {
        store.dispatch({
          type: WebsocketTypes.NEAR_POSITIONS,
          payload: positionsPayload
        });
      }

      if (typeof this.message[28] !== 'undefined' && this.message[28] !== '')
        store.dispatch({
          type: WebsocketTypes.SECONDS_TO_CAPTURE,
          payload: {
            secondsToCapture: String(this.message[28])
          }
        });

      if (typeof this.message[29] !== 'undefined' && this.message[29] !== '')
        store.dispatch({
          type: WebsocketTypes.GREY_OPERATION,
          payload: {
            greyOperation: String(this.message[29])
          }
        });

      if (typeof this.message[30] !== 'undefined' && this.message[30] !== '')
        store.dispatch({
          type: WebsocketTypes.SECONDS_IN_COLETE,
          payload: {
            secondsInColete: String(this.message[30])
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  private processGgaMessage(time: string): void {
    const ggaMessage = localStorage.getItem('GgaMessage');
    if (ggaMessage) {
      this.message = JSON.parse(ggaMessage);
      if (time === this.message[1]) {
        const convert = convertGgaToDecodedPositionMessage(this.message);
        if (!convert.errors) {
          this.decodedPositionMessage = {
            type: GpsTypes.POSITION_MESSAGE,
            payload: {
              ...this.errors,
              latitude: convert.latitude,
              longitude: convert.longitude,
              altitude: convert.altitude,
              solution: convert.solution,
              satellitesNumber: convert.satellitesNumber,
              age: convert.age,
              time: this.message[1]
            }
          };
        }
      }
    }
  }

  private processGstMessage(): void {
    if (
      this.message[6] !== '' &&
      this.message[7] !== '' &&
      this.message[8] !== ''
    ) {
      this.errors = {
        latitudeError: this.message[6],
        longitudeError: this.message[7],
        altitudeError: this.message[8]
      };

      this.processGgaMessage(this.message[1]);
    }
  }

  private processGsaMessage(): void {
    if (
      typeof this.message[15] !== 'undefined' &&
      typeof this.message[16] !== 'undefined' &&
      typeof this.message[17] !== 'undefined'
    ) {
      this.decodedDopsMessage = {
        type: GpsTypes.DOPS_MESSAGE,
        payload: {
          gdop: Number(this.message[15]),
          tdop: Number(this.message[15]),
          hdop: Number(this.message[16]),
          vdop: Number(this.message[17])
        }
      };
    }
  }

  private processGsvMessage(): void {
    if (this.message[0] === '$GPGSV') {
      this.retrieveSatellitesInformation('GPS');
    } else if (this.message[0] === '$GLGSV') {
      this.retrieveSatellitesInformation('GLONASS');
    } else if (this.message[0] === '$GAGSV') {
      this.retrieveSatellitesInformation('GALILEO');
    } else if (this.message[0] === '$GBGSV') {
      this.retrieveSatellitesInformation('BEIDOU');
    }
  }

  private retrieveSatellitesInformation(
    type: 'GPS' | 'GLONASS' | 'GALILEO' | 'BEIDOU'
  ): void {
    try {
      for (let i = 0; i <= 3; i += 1) {
        const index = 4 + 4 * i;
        let prn = -1;
        let elevation = -1.0;
        let azimuth = -1.0;
        let carrier = 0;
        if (
          this.message[index] !== '' &&
          typeof this.message[index] !== 'undefined'
        ) {
          prn = Number(this.message[index]);
        }

        if (
          this.message[index + 1] !== '' &&
          typeof this.message[index + 1] !== 'undefined'
        ) {
          elevation = Number(this.message[index + 1]);
        }

        if (
          this.message[index + 2] !== '' &&
          typeof this.message[index + 2] !== 'undefined'
        ) {
          azimuth = Number(this.message[index + 2]);
        }

        if (
          this.message[index + 3] !== '' &&
          typeof this.message[index + 3] !== 'undefined'
        ) {
          carrier = Number(this.message[index + 3]);
        }

        if (prn !== -1 && elevation !== -1.0 && azimuth !== -1.0) {
          this.satelliteList[type].push({
            prn: prn || 0,
            elevation,
            azimuth,
            carrier
          });
        }
      }
      if (this.message[1] === this.message[2]) {
        this.decodedSatelliteMessage = {
          type: GpsTypes.SATELLITES_MESSAGE,
          payload: {
            type,
            totalSatellites: Number(this.message[3]),
            satellites: [...this.satelliteList[type]]
          }
        };
        this.satelliteList[type] = [];
        localStorage.setItem(
          'satelliteList',
          JSON.stringify(this.satelliteList)
        );
      } else {
        localStorage.setItem(
          'satelliteList',
          JSON.stringify(this.satelliteList)
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default NmeaDecoder;
