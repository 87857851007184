import { combineReducers } from 'redux';
import reducerLayout from './layout';
import { LayoutState } from '../types/Layout';
import reducerWebsocket from './websocket';

import reducerGps from './gps';
import reducerRover from './rover';
import { GpsState } from '../types/Gps';
import { RoverState } from '../types/Rover';
import { WebsocketState } from '../types/Websocket';

export interface ApplicationState {
  layout: LayoutState;
  websocket: WebsocketState;
  gps: GpsState;
  rover: RoverState;
}

const reducers = combineReducers({
  layout: reducerLayout,
  websocket: reducerWebsocket,
  gps: reducerGps,
  rover: reducerRover
});

export default reducers;
