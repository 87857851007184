type Colors = {
  fillColor: string;
  fontColor: string;
};

export const drawBackground = (
  canvasWidth: number,
  canvasHeight: number,
  canvas: HTMLCanvasElement
) => {
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.clearRect(0, 0, canvasWidth, canvasHeight);
  context.font = '16px Arial';
  context.fillStyle = 'white';
  context.fillText('N', canvasWidth / 2 - 6, 16);
  context.fillText('NO', canvasWidth / 2 - 79.1 - 30, canvasHeight / 2 - 79.1);
  context.fillText('NE', canvasWidth / 2 + 79.1 + 10, canvasHeight / 2 - 79.1);
  context.fillText('S', canvasWidth / 2 - 6, canvasHeight - 6);
  context.fillText(
    'SE',
    canvasWidth / 2 + 79.1 + 10,
    canvasHeight / 2 + 79.1 + 10
  );
  context.fillText(
    'SO',
    canvasWidth / 2 - 79.1 - 30,
    canvasHeight / 2 + 79.1 + 10
  );
  context.fillText('L', canvasWidth - 16, canvasHeight / 2 + 6);
  context.fillText('O', 6, canvasHeight / 2 + 6);

  context.beginPath();
  context.moveTo(canvasWidth / 2, 28);
  context.lineTo(canvasWidth / 2, canvasHeight - 28);
  context.strokeStyle = '#ffffff';
  context.stroke();
  context.closePath();

  context.beginPath();
  context.moveTo(28, canvasHeight / 2);
  context.lineTo(canvasHeight - 29, canvasHeight / 2);
  context.strokeStyle = '#ffffff';
  context.stroke();
  context.closePath();

  context.beginPath();
  context.arc(canvasWidth / 2, canvasHeight / 2, 112, 0, 2 * Math.PI);
  context.strokeStyle = '#ffffff';
  context.stroke();
  context.closePath();

  context.beginPath();
  context.arc(canvasWidth / 2, canvasHeight / 2, 74.66, 0, 2 * Math.PI);
  context.strokeStyle = '#ffffff';
  context.stroke();
  context.closePath();

  context.beginPath();
  context.arc(canvasWidth / 2, canvasHeight / 2, 37.33, 0, 2 * Math.PI);
  context.strokeStyle = '#ffffff';
  context.stroke();
  context.closePath();

  context.font = '12px Arial';
  context.fillText('0', canvasWidth / 2 + 2, 40);
  context.fillText('30', canvasWidth / 2 + 2, 80);
  context.fillText('60', canvasWidth / 2 + 2, 118);
};

export const drawCircules = (
  prn: number,
  colors: Colors,
  width: number,
  height: number,
  canvas: HTMLCanvasElement
) => {
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.beginPath();
  context.arc(width, height, 10, 0, 2 * Math.PI);
  context.fillStyle = colors.fillColor;
  context.fill();
  context.closePath();

  context.font = '12px Arial';
  context.fillStyle = colors.fontColor;
  const xFix = prn <= 9 ? 3 : 7;
  context.fillText(prn.toString(), width - xFix, height + 4);
};

export const drawRectangles = (
  prn: number,
  colors: Colors,
  width: number,
  height: number,
  canvas: HTMLCanvasElement
) => {
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.beginPath();
  context.fillStyle = colors.fillColor;
  context.rect(width, height, 20, 20);
  context.fill();
  context.closePath();

  context.font = '12px Arial';
  context.fillStyle = colors.fontColor;
  context.fillText(prn.toString(), width + 4, height + 14);
};
