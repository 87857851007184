import { Reducer } from 'redux';
import { LayoutState, LayoutTypes, LayoutActions } from '../../types/Layout';

const INITIAL_STATE: LayoutState = {
  modeType: 'Avançado',
  notification: {
    show: false,
    type: '',
    message: '',
    description: ' '
  }
};

const reducerLayout: Reducer<LayoutState> = (
  state = INITIAL_STATE,
  action: LayoutActions
) => {
  switch (action.type) {
    case LayoutTypes.SET_MODE_TYPE:
      return { ...state, modeType: action.payload };
    case LayoutTypes.TOGGLE_SIDEBAR:
      return { ...state, modeType: state.modeType };
    case LayoutTypes.SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          show: true,
          type: action.payload.type,
          message: action.payload.message,
          description: action.payload.description
        }
      };
    default:
      return state;
  }
};

export default reducerLayout;
