/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Row, PageHeader, Card, Badge } from 'antd';

import {
  ThunderboltOutlined,
  BarsOutlined,
  WarningOutlined
} from '@ant-design/icons';

import { ApplicationState } from '../../store/rootReducer';
import * as WebsocketActions from '../../store/websocket/actions';
import * as GpsAction from '../../store/gps/actions';
import {
  ConfigurationTypes,
  ConfigurationLocationTypes
} from '../../types/Gps';

import './style.css';

import one from './images/one.svg';
import two from './images/two.svg';
import three from './images/three.svg';
import four from './images/four.svg';
import five from './images/five.svg';

import on from './images/on.svg';
import off from './images/off.svg';

interface DispatchProps {
  configGps(
    location: ConfigurationLocationTypes,
    type: ConfigurationTypes
  ): void;
  selectGpsModel(model: string): void;
}
interface StateProps {
  connectionStatus: number;
  gpsModel: string | null;
}

type Props = StateProps & DispatchProps;

const Extra = ({ connectionStatus, selectGpsModel }: Props) => {
  const [color, setColor] = useState(0);
  const [charging, setCharging] = useState(0);
  const [level, setLevel] = useState(0);
  const [cicles, setCicles] = useState(0);
  const [hours, setHours] = useState(0);
  const [timesOn, setTimesOn] = useState(0);

  useEffect(() => {
    if (connectionStatus === 0) {
      selectGpsModel('184b');
    }
  }, [connectionStatus]);

  const fetchData = async () => {
    const requestOptions = {
      method: 'GET'
    };

    fetch(`http://127.0.0.1:8080/monitor`, requestOptions)
      .then(response => response.text())
      .then(data => {
        const [, x, y, z, h, n, c] = data.split(',');
        setCharging(parseInt(x, 10));
        setColor(parseInt(y, 10));
        setLevel(parseInt(z, 10));
        setHours(parseInt(h, 10));
        setTimesOn(parseInt(n, 10));
        setCicles(parseInt(c, 10));
      })
      .catch(error => {
        console.log(` Erro: ${error.message}`);
        // $BAT,x,y,z,h, n, c
        // x pode ser 0 ou 1
        // 0 se não estiver carregando
        // 1 se estiver carregando
        // y pode ser 0, 1, 2 ou 3, 4 , 5
        // significa a cor do LED que está
        // z indica a porcentagem de 0 a 100
        // h indica numero de horas da base ligada (Horas da base ligada)
        // n indica o numero de vezes que a base foi ligada
        // c indica numero de ciclos (Ciclos da bateria)
        // cor do led
        // GREEN = 0;
        // YELLOW = 1;
        // RED = 2;
        // GREEN  + MSG= 3;
        // YELLOW  + MSG= 4;
        // RED + MSG = 5;

        // para testes offline homeoffice
        // const data = '$BAT,1,1,58,10,5,15';
        // const [, x, y, z, h, n, c] = data.split(',');
        // setCharging(parseInt(x, 10));
        // setColor(parseInt(y, 10));
        // setLevel(parseInt(z, 10));
        // setHours(parseInt(h, 10));
        // setTimesOn(parseInt(n, 10));
        // setCicles(parseInt(c, 10));
      });
  };

  const getColor = (colorChoose: number) => {
    switch (colorChoose) {
      case 0:
        return 'green';
      case 1:
        return 'yellow';
      case 2:
        return 'red';
      case 3:
        return 'green';
      case 4:
        return 'yellow';
      case 5:
        return 'red';

      default:
        return 'green';
    }
  };

  const colorIcon = <Badge color={getColor(color)} dot />;
  const chargeIcon = charging ? (
    <img src={on} alt="Ícone de bateria" />
  ) : (
    <img src={off} alt="Ícone de bateria" />
  );

  useEffect(() => {
    fetchData();
  }, []);

  let imageSrc;

  if (level <= 20) {
    imageSrc = one;
  } else if (level <= 40) {
    imageSrc = two;
  } else if (level <= 60) {
    imageSrc = three;
  } else if (level <= 80) {
    imageSrc = four;
  } else if (level <= 100) {
    imageSrc = five;
  }

  return (
    <>
      <PageHeader title="Estado do XRTK">
        <p className="header-subtitle">
          Aqui você encontra informações sobre o seu XRTK
        </p>

        <Row style={{ justifyContent: 'center' }}>
          <Card className="batery-card">
            <div className="card-content-batery">
              <h5 className="card_h2">Nível de bateria</h5>
              <span>
                {chargeIcon}
                <img
                  className="card-batery-img"
                  src={imageSrc}
                  alt="Ícone de bateria"
                />
                {colorIcon}
              </span>
              <span className="card-info"> {level}% </span>
            </div>
          </Card>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Card className="batery-card">
            <div className="card-content-batery">
              <h5 className="card_h2">Ciclos de bateria</h5>
              <span
                className="card-info"
                style={{ fontSize: '25px', fontWeight: 'bold' }}
              >
                {cicles}
              </span>
            </div>
          </Card>
          <Card className="batery-card">
            <div className="card-content-batery">
              <h5 className="card_h2">Horas ligada</h5>
              <span
                className="card-info"
                style={{ fontSize: '25px', fontWeight: 'bold' }}
              >
                {hours}
              </span>
            </div>
          </Card>
          <Card className="batery-card">
            <div className="card-content-batery">
              <h5 className="card_h2">Vezes da base ligada</h5>
              <span
                className="card-info"
                style={{ fontSize: '25px', fontWeight: 'bold' }}
              >
                {timesOn}
              </span>
            </div>
          </Card>
        </Row>
        {color > 2 && (
          <div style={{ color: '#e6e6e6' }}>
            <WarningOutlined className="altWarning" />
            Temperatura de bateria está acima de 40°C, e por segurança o
            carregamento de bateria foi interrompido. Mas você pode continuar
            utilizando o XRTK normalmente conectado na fonte externa.
          </div>
        )}
      </PageHeader>
    </>
  );
};
const mapStateToProps = ({ websocket, gps }: ApplicationState) => ({
  connectionStatus: websocket.connectionStatus,
  gpsModel: gps.model
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions, ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Extra);
