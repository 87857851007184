import { Reducer } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { message } from 'antd';
import { GpsState, GpsTypes, GpsActions } from '../../types/Gps';

const INITIAL_STATE: GpsState = {
  model: null,
  isLogEnabled: false,
  sf: '7',
  files: [],
  freeSpace: 0,
  position: {
    latitude: '',
    longitude: '',
    altitude: 0,
    solution: '',
    satellitesNumber: 0,
    age: 0,
    uuid: '',
    time: ''
  },
  positionError: {
    latitude: '',
    longitude: '',
    altitude: ''
  },
  dops: {
    tdop: [],
    gdop: [],
    vdop: [],
    hdop: [],
    uuid: '',
    time: []
  },
  satellitesList: {
    GPS: [],
    GLONASS: [],
    GALILEO: [],
    BEIDOU: []
  },
  satellitesTotal: {
    GPS: 0,
    GLONASS: 0,
    GALILEO: 0,
    BEIDOU: 0
  },
  xrtk: {
    batteryPercentage: 100,
    isCharging: false,
    isOverheated: false
  },
  configType: 'base',
  isConfirmRinexOpen: false,
  isRtcmMessage: false,
  macAddr: ''
};

const reducerBase: Reducer<GpsState, GpsActions> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GpsTypes.SELECT_GPS_MODEL:
      return {
        ...state,
        model: action.payload.model
      };
    case GpsTypes.MAC_ADDR:
      return {
        ...state,
        macAddr: action.payload.addr
      };
    case GpsTypes.SELECT_SF_TYPE:
      return {
        ...state,
        sf: action.payload.sf
      };
    case GpsTypes.SET_FILE_LIST:
      message.destroy();
      return {
        ...state,
        files: action.payload.files
      };
    case GpsTypes.SET_FREE_SPACE:
      message.destroy();
      return {
        ...state,
        freeSpace: action.payload.freeSpace
      };
    case GpsTypes.POSITION_MESSAGE: {
      return {
        ...state,
        position: {
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
          altitude: action.payload.altitude,
          satellitesNumber: action.payload.satellitesNumber,
          solution: action.payload.solution,
          age: action.payload.age,
          uuid: uuidv4(),
          time: action.payload.time
        },
        positionError: {
          latitude: action.payload.latitudeError,
          longitude: action.payload.longitudeError,
          altitude: action.payload.altitudeError
        }
      };
    }
    case GpsTypes.DOPS_MESSAGE: {
      // calculando a hora atual e verificando se o array de hora já nao tem 80 elementos
      const date = new Date();

      const hour = date.getHours();
      const min =
        date.getMinutes() > 9
          ? date.getMinutes()
          : '0'.concat(String(date.getMinutes()));
      const sec =
        date.getSeconds() > 9
          ? date.getSeconds()
          : '0'.concat(String(date.getSeconds()));

      const time = `${hour}:${min}:${sec}`;

      let newTime;
      if (state.dops.time.length < 80) {
        newTime = [...state.dops.time, time];
      } else {
        newTime = [...state.dops.time.slice(1), time];
      }

      // adicionando novo valor ao gdop
      let newGdop;
      if (state.dops.gdop.length < 80) {
        newGdop = [...state.dops.gdop, action.payload.gdop];
      } else {
        newGdop = [...state.dops.gdop.slice(1), action.payload.gdop];
      }

      // adicionando novo valor ao tdop
      let newTdop;
      if (state.dops.tdop.length < 80) {
        newTdop = [...state.dops.tdop, action.payload.tdop];
      } else {
        newTdop = [...state.dops.tdop.slice(1), action.payload.tdop];
      }

      // adicionando novo valor ao vdop
      let newVdop;
      if (state.dops.vdop.length < 80) {
        newVdop = [...state.dops.vdop, action.payload.vdop];
      } else {
        newVdop = [...state.dops.vdop.slice(1), action.payload.vdop];
      }

      // adicionando novo valor ao hdop
      let newHdop;
      if (state.dops.hdop.length < 80) {
        newHdop = [...state.dops.hdop, action.payload.hdop];
      } else {
        newHdop = [...state.dops.hdop.slice(1), action.payload.hdop];
      }

      return {
        ...state,
        dops: {
          tdop: newTdop,
          gdop: newGdop,
          vdop: newVdop,
          hdop: newHdop,
          uuid: uuidv4(),
          time: newTime
        }
      };
    }
    case GpsTypes.SATELLITES_MESSAGE: {
      if (action.payload.type === 'GPS') {
        return {
          ...state,
          satellitesList: {
            ...state.satellitesList,
            GPS: [...action.payload.satellites]
          },
          satellitesTotal: {
            ...state.satellitesTotal,
            GPS: action.payload.totalSatellites
          }
        };
      }

      if (action.payload.type === 'GALILEO') {
        return {
          ...state,
          satellitesList: {
            ...state.satellitesList,
            GALILEO: [...action.payload.satellites]
          },
          satellitesTotal: {
            ...state.satellitesTotal,
            GALILEO: action.payload.totalSatellites
          }
        };
      }
      if (action.payload.type === 'BEIDOU') {
        return {
          ...state,
          satellitesList: {
            ...state.satellitesList,
            BEIDOU: [...action.payload.satellites]
          },
          satellitesTotal: {
            ...state.satellitesTotal,
            BEIDOU: action.payload.totalSatellites
          }
        };
      }
      return {
        ...state,
        satellitesList: {
          ...state.satellitesList,
          GLONASS: [...action.payload.satellites]
        },
        satellitesTotal: {
          ...state.satellitesTotal,
          GLONASS: action.payload.totalSatellites
        }
      };
    }
    case GpsTypes.XRTK_INFO_MESSAGE: {
      return {
        ...state,
        xrtk: {
          batteryPercentage: action.payload.batteryPercentage,
          isCharging: action.payload.isCharging,
          isOverheated: action.payload.isOverheated
        }
      };
    }
    case GpsTypes.RESET_STATE:
      return {
        ...INITIAL_STATE,
        model: state.model,
        configType: state.configType
      };
    case GpsTypes.TOGGLE_LOG: {
      return {
        ...state,
        isLogEnabled: !state.isLogEnabled
      };
    }
    case GpsTypes.SET_CONFIG_TYPE:
      return {
        ...state,
        configType: action.payload.configType
      };
    case GpsTypes.SET_CONFIRM_RINEX_OPEN:
      return {
        ...state,
        isConfirmRinexOpen: action.payload.isOpen
      };
    case GpsTypes.RTCM_MESSAGE:
      return {
        ...state,
        isRtcmMessage: action.payload.isRtcm
      };
    default:
      return state;
  }
};

export default reducerBase;
