import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';

import { ApplicationState } from '../../../store/rootReducer';

type Props = {
  tdop: number[];
  gdop: number[];
  vdop: number[];
  hdop: number[];
  dopsUUID: string;
  time: string[];
  greyOperation: string;
};
type DataReference = {
  time: string;
  type: string;
  total: number;
  uuid: string;
};
type DopsReference = {
  tdopList: DataReference[];
  gdopList: DataReference[];
  vdopList: DataReference[];
  hdopList: DataReference[];
};
const LineGraph = ({
  tdop,
  gdop,
  vdop,
  hdop,
  dopsUUID,
  time,
  greyOperation
}: Props) => {
  const dops = useRef<DopsReference>({
    tdopList: [],
    gdopList: [],
    vdopList: [],
    hdopList: []
  });

  useEffect(() => {
    const gdopWithTime = gdop.map((value, index) => ({
      time: time[index],
      type: 'GDOP',
      total: value,
      uuid: dopsUUID
    }));
    dops.current.gdopList.push(...gdopWithTime);

    const tdopWithTime = tdop.map((value, index) => ({
      time: time[index],
      type: 'TDOP',
      total: value,
      uuid: dopsUUID
    }));
    dops.current.tdopList.push(...tdopWithTime);

    const vdopWithTime = vdop.map((value, index) => ({
      time: time[index],
      type: 'VDOP',
      total: value,
      uuid: dopsUUID
    }));
    dops.current.vdopList.push(...vdopWithTime);

    const hdopWithTime = hdop.map((value, index) => ({
      time: time[index],
      type: 'HDOP',
      total: value,
      uuid: dopsUUID
    }));
    dops.current.hdopList.push(...hdopWithTime);
  }, []);

  useEffect(() => {
    if (tdop.length !== 0) {
      if (dops.current.tdopList.length >= 80) {
        dops.current.tdopList.shift();
      }
      dops.current.tdopList.push({
        time: time[time.length - 1],
        type: 'TDOP',
        total: tdop[tdop.length - 1],
        uuid: dopsUUID
      });

      if (dops.current.gdopList.length >= 80) {
        dops.current.gdopList.shift();
      }

      dops.current.gdopList.push({
        time: time[time.length - 1],
        type: 'GDOP',
        total: gdop[gdop.length - 1],
        uuid: dopsUUID
      });

      if (dops.current.hdopList.length >= 80) {
        dops.current.hdopList.shift();
      }
      dops.current.hdopList.push({
        time: time[time.length - 1],
        type: 'HDOP',
        total: hdop[hdop.length - 1],
        uuid: dopsUUID
      });

      if (dops.current.vdopList.length >= 80) {
        dops.current.vdopList.shift();
      }
      dops.current.vdopList.push({
        time: time[time.length - 1],
        type: 'VDOP',
        total: vdop[vdop.length - 1],
        uuid: dopsUUID
      });
    }
  }, [tdop, gdop, vdop, hdop, dopsUUID]);

  const data = [
    ...dops.current.tdopList,
    ...dops.current.gdopList,
    ...dops.current.hdopList,
    ...dops.current.vdopList
  ];
  const cols = {};
  return (
    <div style={{ color: '#FFFFFFF2' }}>
      <Chart
        height={300}
        data={data}
        scale={cols}
        forceFit
        style={{ width: '600px' }}
      >
        <Legend />
        <Axis name="total" />
        <Axis
          name="time"
          visible
          label={{
            formatter: (text, item, index) => {
              return index % 10 === 0 ? text : '';
            }
          }}
        />
        <Tooltip
          crosshairs={{
            type: 'y'
          }}
        />
        <Geom
          type="line"
          position="time*total"
          size={2}
          color={
            Number(greyOperation)
              ? ['type', ['grey', 'grey', 'grey', 'grey']]
              : ['type', ['#2f7d94', '#8fa956', '#fb5959', '#fbee59']]
          }
          shape="smooth"
        />
        <Geom
          type="point"
          position="time*total"
          size={4}
          shape="circle"
          color={
            Number(greyOperation)
              ? 'grey'
              : ['type', ['#2f7d94', '#8fa956', '#fb5959', '#fbee59']]
          }
          style={{
            stroke: '#fff',
            lineWidth: 1
          }}
        />
      </Chart>
    </div>
  );
};

const mapStateToProps = ({ gps, websocket }: ApplicationState) => ({
  tdop: gps.dops.tdop,
  gdop: gps.dops.gdop,
  vdop: gps.dops.vdop,
  hdop: gps.dops.hdop,
  dopsUUID: gps.dops.uuid,
  time: gps.dops.time,
  greyOperation: websocket.greyOperation
});

export default connect(mapStateToProps)(LineGraph);
