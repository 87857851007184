export function convertLatitudeDmToDdd(
  latidude: string,
  indicadorLatitude: string
): string {
  let finalLatitude = 0.0;

  const degreessAndMinute: string = latidude.substring(
    0,
    latidude.indexOf('.')
  );

  let degree = 0;
  let minute = 0;
  if (degreessAndMinute.length === 4) {
    degree = Number(latidude.substring(0, 2));
    minute = Number(latidude.substring(2, latidude.length));
  } else if (degreessAndMinute.length === 3) {
    degree = Number(latidude.substring(0, 1));
    minute = Number(latidude.substring(1, latidude.length));
  }
  finalLatitude = degree + minute / 60;

  if (indicadorLatitude.match('S')) {
    finalLatitude *= -1;
  }
  return finalLatitude.toString();
}
