export enum GpsTypes {
  SELECT_GPS_MODEL = '@base/SELECT_GPS_MODEL',
  RTCM_MESSAGE = '@gps/RTCM_MESSAGE',
  CONFIG_GPS = '@gps/CONFIG_GPS',
  POSITION_MESSAGE = '@gps/POSITION_MESSAGE',
  POSITION_ERROR_MESSAGE = '@gps/POSITION_ERROR_MESSAGE',
  DOPS_MESSAGE = '@gps/DOPS_MESSAGE',
  SATELLITES_MESSAGE = '@gps/SATELLITES_MESSAGE',
  START_LORA = '@gps/START_LORA',
  STOP_LORA = '@gps/STOP_LORA',
  XRTK_INFO_MESSAGE = '@gps/XRTK_INFO_MESSAGE',
  RESET_STATE = '@gps/RESET_STATE',
  SELECT_SF_TYPE = '@gps/SELECT_SF_TYPE',
  SET_FREE_SPACE = '@gps/SET_FREE_SPACE',
  SET_FILE_LIST = '@gps/SET_FILE_LIST',
  TOGGLE_LOG = '@gps/TOGGLE_LOG',
  SET_CONFIG_TYPE = '@gps/SET_CONFIG_TYPE',
  SET_CONFIRM_RINEX_OPEN = '@gps/SET_CONFIRM_RINEX_OPEN',
  MAC_ADDR = '@base/GET_BASE_MAC_ADDR'
}

export type ConfigurationTypes = 'initial_config' | 'stop_nmea' | 'fix_base';

export type ConfigurationLocationTypes = 'base' | 'rover' | 'rinex' | '';

interface SetConfirmRinexOpen {
  type: typeof GpsTypes.SET_CONFIRM_RINEX_OPEN;
  payload: {
    isOpen: boolean;
  };
}

interface SetRtcmMessage {
  type: typeof GpsTypes.RTCM_MESSAGE;
  payload: {
    isRtcm: boolean;
  };
}

interface SelectBaseModelAction {
  type: typeof GpsTypes.SELECT_GPS_MODEL;
  payload: {
    model: string;
  };
}

interface SetConfigType {
  type: typeof GpsTypes.SET_CONFIG_TYPE;
  payload: {
    configType: ConfigurationLocationTypes;
  };
}

interface SelectSfTypeAction {
  type: typeof GpsTypes.SELECT_SF_TYPE;
  payload: {
    sf: string;
  };
}

interface SetFileListAction {
  type: typeof GpsTypes.SET_FILE_LIST;
  payload: {
    files: string[];
  };
}

interface SetFreeSpaceAction {
  type: typeof GpsTypes.SET_FREE_SPACE;
  payload: {
    freeSpace: number;
  };
}

interface GpsConfigAction {
  type: typeof GpsTypes.CONFIG_GPS;
  payload: {
    location: 'base' | 'rover';
    type: ConfigurationTypes;
  };
}

interface GpsStartLoraAction {
  type: typeof GpsTypes.START_LORA;
}

export interface PositionMessageAction {
  type: GpsTypes.POSITION_MESSAGE;
  payload: {
    latitude: string;
    longitude: string;
    altitude: number;
    solution: string;
    satellitesNumber: number;
    age: number;
    latitudeError: string;
    longitudeError: string;
    altitudeError: string;
    time: string;
  };
}

export interface DopsMessageAction {
  type: GpsTypes.DOPS_MESSAGE;
  payload: {
    gdop: number;
    tdop: number;
    hdop: number;
    vdop: number;
  };
}

export type Satellites = {
  prn: number;
  carrier: number;
  elevation: number;
  azimuth: number;
};

export interface MacAddrAction {
  type: GpsTypes.MAC_ADDR;
  payload: {
    addr: string;
  };
}

export interface SatellitesMessageAction {
  type: GpsTypes.SATELLITES_MESSAGE;
  payload: {
    type: string;
    totalSatellites: number;
    satellites: Satellites[];
  };
}

interface GpsStopLoraAction {
  type: typeof GpsTypes.STOP_LORA;
}

interface GpsToggleLogAction {
  type: typeof GpsTypes.TOGGLE_LOG;
}

export interface XrtkInformationMessageAction {
  type: GpsTypes.XRTK_INFO_MESSAGE;
  payload: {
    batteryPercentage: number;
    isCharging: boolean;
    isOverheated: boolean;
  };
}

interface ResetStateAction {
  type: typeof GpsTypes.RESET_STATE;
}

export type GpsActions =
  | SelectBaseModelAction
  | SelectSfTypeAction
  | GpsConfigAction
  | GpsStartLoraAction
  | PositionMessageAction
  | DopsMessageAction
  | SatellitesMessageAction
  | GpsStopLoraAction
  | XrtkInformationMessageAction
  | ResetStateAction
  | SetFileListAction
  | SetFreeSpaceAction
  | GpsToggleLogAction
  | SetConfigType
  | SetRtcmMessage
  | SetConfirmRinexOpen
  | MacAddrAction;

export interface GpsState {
  model: string | null;
  isLogEnabled: boolean;
  sf: string;
  position: {
    latitude: string;
    longitude: string;
    altitude: number;
    solution: string;
    satellitesNumber: number;
    age: number;
    uuid: string;
    time: string;
  };
  positionError: {
    latitude: string;
    longitude: string;
    altitude: string;
  };
  dops: {
    tdop: number[];
    gdop: number[];
    vdop: number[];
    hdop: number[];
    uuid: string;
    time: string[];
  };
  satellitesList: {
    GPS: Satellites[];
    GLONASS: Satellites[];
    GALILEO: Satellites[];
    BEIDOU: Satellites[];
  };
  satellitesTotal: {
    GPS: number;
    GLONASS: number;
    GALILEO: number;
    BEIDOU: number;
  };
  xrtk: {
    batteryPercentage: number;
    isCharging: boolean;
    isOverheated: boolean;
  };
  freeSpace: number;
  files: string[];
  configType: ConfigurationLocationTypes;
  isConfirmRinexOpen: boolean;
  isRtcmMessage: boolean;
  macAddr: string;
}
