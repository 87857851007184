import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';


import {Table, ConfigProvider, Empty, Popconfirm, Button } from 'antd';

import type { ColumnsType } from 'antd/es/table';

import { DeleteOutlined } from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import { ApplicationState } from '../../../../store/rootReducer';

import './style.css';

import { LayoutTypes } from '../../../../types/Layout';
import { Dispatch, bindActionCreators } from 'redux';
import * as WebsocketActions from '../../../../store/websocket/actions';
import * as GpsAction from '../../../../store/gps/actions';


interface DataType {
  key: string;
  lat: string;
  long:string;
  alt:string;
  date:string;
  dist: string;
}

interface Position {
  latitude: string;
  longitude:string;
  altitude:string;
  data:string;
  dist: string;
}

interface DispatchProps {
  connectToWebsocket(): void;
  selectGpsModel(model: string): void;
}

interface StateProps {
  connectionStatus: number;
  positions: Position[];
}

type Props = StateProps & DispatchProps;

const CloseSpotsPanel = ({   
  connectToWebsocket,
  selectGpsModel, 
  connectionStatus, 
  positions }: Props) => {
  const [isLoading, setIsLoading] = useState(true);  
  const [tableData, setTableData] = useState<DataType[]>([]);



  const changeTimeZone = (date: string) => {
    const [year, month, day, hour, minute, second] = date.split('_').map(Number);
    const utcOffset = -3 * 60;
    const utcTime = new Date(year, month - 1, day, hour, minute, second).getTime();

    return new Date(utcTime + (utcOffset * 60000)).toLocaleString("pt-BR");
  };
  const fetchData = async () => {
    setIsLoading(true);

    const formattedData: DataType[] = positions
  .filter(position => Number(position.latitude.split('+')[0]) !== 0 && Number(position.longitude.split('+')[0]) !== 0)
  .map((position, index) => ({
    key: String(index),
    lat: position.latitude,
    long: position.longitude,
    alt: position.altitude,
    date: changeTimeZone(position.data),
    dist: position.dist
  }));

  
    setTableData(formattedData);
  
    setIsLoading(false);
  
  };

  useEffect(() => {
    if (connectionStatus === 0) {
      selectGpsModel('184b');
      //connectToWebsocket();
    }
    fetchData();
  }, [positions]); 


  const [isDeleting, setIsDeleting] = useState(false);

  const handleRemove = (selectedLat: string,selectedLong: string, selectedAlt: string ) => {
    setIsDeleting(true);

    const requestOptions = {
      method: 'POST',

    };

    fetch(`http://localhost:8080/delete_close_spot?lat=${selectedLat}&long=${selectedLong}&alt=${selectedAlt}`, requestOptions)
      .then(response => response.text())
      .then(data => {
        console.log(` Exclusão realizada com sucesso `);
        const updatedTableData = tableData.filter(item => !(item.lat === selectedLat && item.long === selectedLong && item.alt === selectedAlt));
        setTableData(updatedTableData);
      })
      .catch(error => {
        console.log(` Erro: ${error.message}`);
      })
      .finally(() => setIsDeleting(false));
  };


  const columns: ColumnsType<DataType> = [
    {
      title: 'Latitude',
      dataIndex: 'lat',
      key: 'lat',
      width: '20%',
      render: (text: string) => (
        <div className="latitude-cell">
          <span>{text.split('+')[0]}</span>
          <div className='error-box'>
            <div className='signals-box'>
              <span className='plus-signal'>
                +
              </span>
              <span className='min-signal'>-</span>
            </div>    
            <div className='error'>
              <span>
          
                {text.split('+-')[1]}
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Longitude',
      dataIndex: 'long',
      key: 'long',
      width: '20%',
      render: (text: string) => (
        <div className="latitude-cell">
          <span>{text.split('+')[0]}</span>
          <div className='error-box'>
            <div className='signals-box'>
              <span className='plus-signal'>
                +
              </span>
              <span className='min-signal'>-</span>
            </div>    
            <div className='error'>
              <span>
          
                {text.split('+-')[1]}
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Altitude',
      dataIndex: 'alt',
      key: 'alt',
      width: '20%',
      render: (text: string) => (
        <div className="latitude-cell">
          <span>{text.split('+')[0]}</span>
          <div className='error-box'>
            <div className='signals-box'>
              <span className='plus-signal'>
                +
              </span>
              <span className='min-signal'>-</span>
            </div>    
            <div className='error'>
              <span>
          
                {text.split('+-')[1]}
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      width: '20%'
    },
    {
      title: 'Distancia (m)',
      dataIndex: 'dist',
      key: 'dist',
      width: '20%'
    },
    
    {
      title: '',
      key: 'action',
      align: 'center',
      width: '20%',
      render: (_, record) => (
        <div className="table-action-buttons">
          <Popconfirm
            title="Tem certeza de que deseja excluir este voo?"
            onConfirm={() => handleRemove(String(Number(record.lat)),String(Number(record.long)),String(Number(record.alt)))}
            okText="Sim"
            cancelText="Não"
            placement="bottomRight"
          >
            <Button
              title="Excluir voo"
              className="trash-button"
            > 
              <DeleteOutlined />
              Excluir
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  
  return (
    <div className="table-div">
      <ConfigProvider
        renderEmpty={() => (
          <Empty description={
            <>
              Não foi possível localizar nenhum ponto próximo ao marcador, você será direcionado para o Modo Relativo Novo. 
              <Link
                to="/guia-relativo"
                onClick={e => {
                  e.preventDefault();
                  dispatch({
                    type: LayoutTypes.SET_MODE_TYPE,
                    payload: 'Relativo novo'
                  });
                  history.push('/guia-relativo');
                }}
              >
                
                Clique aqui para continuar.
              </Link>
            </>
          }
          />
            )}
      >
        <Table
          className="table"
          loading={isLoading} 
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered={false}
        
        />
      </ConfigProvider>

    </div>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  connectionStatus: state.websocket.connectionStatus,
  positions: state.websocket.positions, 
});


const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions, ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(CloseSpotsPanel);