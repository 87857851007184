import { MiddlewareAPI, Dispatch, AnyAction } from 'redux';

import { message } from 'antd';
import { WebsocketTypes } from '../types/Websocket';
import { GpsTypes } from '../types/Gps';
import XrtkWebsocket from '../utils/XrtkWebsocket';

let xrtk: XrtkWebsocket;

const websocketMiddleware = (store: MiddlewareAPI) => (
  next: Dispatch
) => async (action: AnyAction) => {
  if (typeof xrtk === 'undefined') {
    xrtk = new XrtkWebsocket(store);
  }

  switch (action.type) {
    case WebsocketTypes.START_CONNECTION:

      xrtk.connect();


      break;
    case WebsocketTypes.START_DISCONNECTION:
      localStorage.setItem('connectionStatus', '0');
      message.destroy();
      store.dispatch({
        type: WebsocketTypes.ISNT_CONNECTED
      });

      store.dispatch({
        type: GpsTypes.RESET_STATE
      });
      xrtk.disconnect();
      break;

    default:
      break;
  }
  return next(action);
};

export default websocketMiddleware;
