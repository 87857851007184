import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, notification } from 'antd';
import { Dispatch, bindActionCreators } from 'redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import SiderMenu from '../../layout/menu/index';
import Content from '../../layout/content/Content';
import BaseControl from '../base-control/BaseControl';
import TableControl from '../table-control/TableControl';
import CoordinateTableControl from '../coordinate-table-control/CoordinateTableControl';
import { NotificationPayload } from '../../types/Layout';
import RelativoControl from '../tutorial-control/RelativoControl';

import { ApplicationState } from '../../store/rootReducer';
import NtripControl from '../ntrip-control/NtripControl';
import Extra from '../connection-control/Extra';
import InicialLayout from '../initial-view/InicialLayout';

import * as WebsocketActions from '../../store/websocket/actions';

import * as GpsAction from '../../store/gps/actions';

import Bateria from '../batery-control/Bateria';
import Processamento from '../file-process-control/Processamento';

import store from '../../index';
import { WebsocketTypes } from '../../types/Websocket';

import './style.css';
import { IconMap } from 'antd/lib/result';

interface StateProps {
  notificationControl: NotificationPayload;
  modeType: string;
  openIBGEsocket: number;
  openFetchFile: number;
  connectionStatus: number;
}

interface DispatchProps {
  connectToWebsocket(): void;
  selectGpsModel(model: string): void;
  setSocketResponseIbge(responseIbge: string): void;
}

type Props = StateProps & DispatchProps;
let flag = 0;

function Main({
  notificationControl,
  connectToWebsocket,
  selectGpsModel,
  setSocketResponseIbge,
  modeType,
  openIBGEsocket,
  openFetchFile,
  connectionStatus
}: Props) {
  const [socket, setSocket] = useState<WebSocket | null>(null);

  //new WebSocket('wss://xrtk-homolog.xmobots.com/api/rinex_status')
  //ws://localhost:8080

  useEffect(() => {
    selectGpsModel('184b');
    if (flag === 0) {
      const intervalId = setInterval(() => {
        if (!openFetchFile) connectToWebsocket();
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [openFetchFile]);

  const [timeConnected, setTimeConnected] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (connectionStatus >= 2) {
      timer = setInterval(() => {
        setTimeConnected(prevTime => {
          const newTime = prevTime + 1;
          localStorage.setItem('timeConnected', newTime.toString());
          return newTime;
        });
      }, 990);
    } else {
      setTimeConnected(0);
      localStorage.setItem('timeConnected', '0');
    }

    return () => {
      clearInterval(timer);
    };
  }, [connectionStatus]);

  useEffect(() => {
    setEletronMode(process.env.REACT_APP_ELECTRON === 'true');
    if (socket) {
      if (openIBGEsocket && !socket.readyState) {
        // Conexão aberta
        socket.addEventListener('open', event => {
          const text = 'Enviando para o servidor da xmobots';
          store.dispatch({
            type: WebsocketTypes.SET_SOCKET_RESPONSE_IBGE,
            payload: text
          });
          setSocketResponseIbge(text);
        });

        // Escute por mensagens
        socket.addEventListener('message', event => {
          const text = event.data;
          console.log('chegou mensagem:', text);
          store.dispatch({
            type: WebsocketTypes.SET_SOCKET_RESPONSE_IBGE,
            payload: text
          });
          setSocketResponseIbge(text);
        });
      }
    }
  }, [socket, socket?.readyState]);

  useEffect(() => {
    console.log('alteração no openIBGE:', openIBGEsocket);
    if (openIBGEsocket && (!socket || socket?.readyState === 3)) {
      console.log('abriu socket');
      setSocket(new WebSocket('wss://xrtk.xmobots.com/api/rinex_status'));
    }

    if (!openIBGEsocket && socket) {
      //Conexão fechada
      socket.addEventListener('close', event => {
        console.log('Conexão fechada', event);
      });
      store.dispatch({
        type: WebsocketTypes.SET_SOCKET_RESPONSE_IBGE,
        payload: ''
      });

      socket.close();
    }
  }, [openIBGEsocket]);

  const handlePositionSucess = (data: boolean) => {};

  useEffect(() => {
    if (notificationControl.show && notificationControl.type !== '') {
      switch (notificationControl.type) {
        case 'info':
          notification.info({
            message: notificationControl.message,
            description: notificationControl.description,
            placement: 'bottomRight'
          });
          break;
        case 'success':
          notification.success({
            message: notificationControl.message,
            description: notificationControl.description,
            placement: 'bottomRight'
          });
          break;
        case 'warning':
          notification.warning({
            message: notificationControl.message,
            description: notificationControl.description,
            placement: 'bottomRight'
          });
          break;
        default:
          notification.error({
            message: notificationControl.message,
            description: notificationControl.description,
            placement: 'bottomRight',
            className: 'error'
          });
          break;
      }
    }
  });

  // eletron mode para produção
  const [progress, setProgress] = useState<number>(0);
  const [clickedButton, setClickedButton] = useState<string>('');
  const [eletronMode, setEletronMode] = useState<Boolean>(true);
  const [portPaths, setPortPaths] = useState<string[]>([]);
  const [selectedPort, setSelectedPort] = useState<string | null>(null);
  let descriptionGNSS: string = 'Gravação GNSS em progresso';
  let descriptionESP32: string = 'Gravação ESP32 em progresso';

  useEffect(() => {
    async function fetchPorts() {
      try {
        const ports = await window.electron.listPorts();
        const paths = ports.map(port => port.path);
        setPortPaths(paths);
      } catch (error) {
        console.error('Error fetching ports:', error);
      }
    }
    fetchPorts();

    const intervalId = setInterval(() => {
      if (clickedButton === '') {
        fetchPorts();
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, [clickedButton]);

  const year = new Date().getFullYear();

  const resetProgress = () => {
    setProgress(0);
    setClickedButton('');
  };

  const handleEsp32Upload = () => {
    if (selectedPort) {
      window.electron.ipcRenderer.send('run-esp32-upload', {
        port: selectedPort,
        bootloader: 'bootloader.bin',
        partition: 'partition-table.bin',
        firmware: 'firmware.bin',
        ota: 'ota_data_initial.bin'
      });
    }
  };

  const handleGnssUpload = () => {
    if (selectedPort) {
      window.electron.ipcRenderer.send('run-gnss-upload', {
        port: selectedPort,
        firmware: 'gnss_firmware.bin'
      });
    }
  };

  const handleClick = (buttonName: string) => {
    setClickedButton(buttonName); //posso mudar o nome dado ao progresso via resposta da função também

    window.electron.onProgressBar((value: any) => {
      setProgress(value);
      console.log('valor do progresso: ', value);
    });

    if (buttonName === descriptionGNSS) {
      handleGnssUpload();
    } else if (buttonName === descriptionESP32) {
      handleEsp32Upload();
    }
  };

  const handlePortClick = (port: string) => {
    setSelectedPort(port);
  };

  return (
    <>
      {eletronMode ? (
        <div className="App-eletron">
          <div className="serial-ports-container">
            <div className="serial-ports-buttons">
              {portPaths.map(port => (
                <button
                  key={port}
                  className={`serial-port-button ${
                    selectedPort === port ? 'selected' : ''
                  }`}
                  onClick={() => handlePortClick(port)}
                >
                  {port}
                </button>
              ))}
            </div>
            <span>
              {portPaths.length === 0
                ? 'Nenhuma porta encontrada'
                : selectedPort
                ? `Porta ${selectedPort} selecionada`
                : 'Selecione uma das portas para iniciar'}
            </span>
          </div>
          <div className="button-container">
            <button
              onClick={() => handleClick(descriptionGNSS)}
              className={`${
                !selectedPort ||
                clickedButton === descriptionESP32 ||
                clickedButton === descriptionGNSS
                  ? 'disabled'
                  : 'gnss'
              }`}
              disabled={!selectedPort || clickedButton === descriptionESP32}
            >
              GNSS
            </button>
            <button
              onClick={() => handleClick(descriptionESP32)}
              className={`${
                !selectedPort ||
                clickedButton === descriptionGNSS ||
                clickedButton === descriptionESP32
                  ? 'disabled'
                  : 'esp32'
              }`}
              disabled={!selectedPort || clickedButton === descriptionGNSS}
            >
              ESP32
            </button>
          </div>
          <div className="progress-container">
            <span>
              {progress !== 100 && progress !== -1 ? clickedButton : ''}
            </span>
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          {progress === 100 && (
            <div className="completion-message">
              <p>Gravação concluída</p>
              <button className="again-button" onClick={resetProgress}>
                Gravar novamente?
              </button>
            </div>
          )}
          {progress === -1 && (
            <div>
              <p className="incompletion-message">Falha na gravação</p>
              <button className="again-button" onClick={resetProgress}>
                Gravar novamente?
              </button>
            </div>
          )}
          <span className="version">
            XRTK PROD: v{process.env.REACT_APP_XGBAS_VERSION} | ESP FIRMWARE: v
            {process.env.REACT_APP_ESP_FIRMWARE_VERSION} | GNSS FIRMWARE: v
            {process.env.REACT_APP_GNSS_FIRMWARE_VERSION} | GNSS CONFIGURAÇÃO: v
            {process.env.REACT_APP_GNSS_CONFIGURACAO_VERSION}
          </span>
        </div>
      ) : (
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <InicialLayout />
            </Route>
            <Route exact path="/guia-relativo">
              <RelativoControl />
            </Route>
            <Route path="/">
              <Layout>
                <SiderMenu />
                <Content>
                  <Route exact path="/extra">
                    <Extra />
                  </Route>
                  <Route exact path="/base">
                    <BaseControl />
                  </Route>
                  <Route exact path="/table">
                    <TableControl />
                  </Route>
                  <Route exact path="/coordinate-table">
                    <CoordinateTableControl />
                  </Route>
                  <Route exact path="/ntrip">
                    <NtripControl
                      tutorialWifi={false}
                      tutorialNTRIP={false}
                      onPositionSucess={handlePositionSucess}
                    />
                  </Route>
                  <Route exact path="/bateria">
                    <Bateria />
                  </Route>
                  <Route exact path="/processamento">
                    <Processamento />
                  </Route>
                </Content>
              </Layout>
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </>
  );
}

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions, ...GpsAction }, dispatch);

const mapStateToProps = ({ layout, websocket }: ApplicationState) => ({
  connectionStatus: websocket.connectionStatus,
  notificationControl: layout.notification,
  modeType: layout.modeType,
  openIBGEsocket: websocket.openIBGEsocket,
  openFetchFile: websocket.openFetchFile
});

export default connect(mapStateToProps, mapDispacthToProps)(Main);
