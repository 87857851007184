import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  drawBackground,
  drawRectangles,
  drawCircules
} from '../../../utils/SatelliteCanvas';

import { Satellites } from '../../../types/Gps';

import { ApplicationState } from '../../../store/rootReducer';

type Colors = {
  fillColor: string;
  fontColor: string;
};

type SymbolLocation = {
  x: number;
  y: number;
};

type Props = {
  satelliteList: {
    GPS: Satellites[];
    GLONASS: Satellites[];
    GALILEO: Satellites[];
    BEIDOU: Satellites[];
  };
  greyOperation: string;
};

const SatellitesConstellation = ({ satelliteList, greyOperation }: Props) => {
  const processColor = (carrier: number): Colors => {
    let fillColor = '';
    let fontColor = 'white';
    if (!Number(greyOperation)) {
      if (carrier >= 0 && carrier <= 20) {
        fillColor = '#fb5959';
      } else if (carrier > 20 && carrier <= 40) {
        fillColor = '#fbee59';
        fontColor = 'black';
      } else {
        fillColor = '#8fa956';
      }
    } else {
      fillColor = 'grey';
    }
    return {
      fillColor,
      fontColor
    };
  };

  const processPosition = (
    elevation: number,
    azimuth: number,
    width: number,
    height: number
  ): SymbolLocation => {
    const scale = 112 / (Math.PI / 2);
    const elevationRadians = (90 - elevation) * (Math.PI / 180);
    const azimuthRadians = -(azimuth - 90) * (Math.PI / 180);

    return {
      x: elevationRadians * Math.cos(azimuthRadians) * scale + width / 2,
      y: elevationRadians * Math.sin(azimuthRadians) * scale + height / 2
    };
  };

  useEffect(() => {
    const canvas = document.getElementById(
      'satellites-constellation'
    ) as HTMLCanvasElement;
    if (canvas) {
      const finalSatelliteList = [
        ...satelliteList.GPS,
        ...satelliteList.GLONASS,
        ...satelliteList.GALILEO,
        ...satelliteList.BEIDOU
      ];
      if (finalSatelliteList.length >= 1) {
        const canvasWidth = canvas.clientWidth;
        const canvasHeight = canvas.offsetHeight;
        drawBackground(canvasWidth, canvasHeight, canvas);
        finalSatelliteList.forEach(satellite => {
          const position = processPosition(
            satellite.elevation,
            satellite.azimuth,
            canvasWidth,
            canvasHeight
          );
          if (satellite.prn >= 0 && satellite.prn <= 37) {
            const colors = processColor(satellite.carrier);
            drawCircules(satellite.prn, colors, position.x, position.y, canvas);
          } else {
            const colors = processColor(satellite.carrier);

            drawRectangles(
              satellite.prn,
              colors,
              position.x,
              position.y,
              canvas
            );
          }
        });
      }
    }
  }, [satelliteList]);

  return <canvas id="satellites-constellation" width="280" height="280" />;
};
const mapStateToProps = ({ gps, websocket }: ApplicationState) => ({
  satelliteList: gps.satellitesList,
  greyOperation: websocket.greyOperation
});

export default connect(mapStateToProps)(SatellitesConstellation);
