import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

import Main from './view/main/Main';

import XGBASImg from './XGBAS.png';

import './App.css';

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Spin size="large" />
      <p
        style={{
          color: 'rgba(255, 255, 255, 0.45)',
          textAlign: 'center',
          fontFamily: 'Helvetica',
          fontSize: '0.875rem',

          lineHeight: '1.375rem'
        }}
      >
        Carregando...
      </p>
    </div>
  );
};

const ShowLogo = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <img
        style={{
          width: '14.5rem',
          height: '3.69619rem'
        }}
        alt="XRTK"
        src={XGBASImg}
      />
    </div>
  );
};

function App() {
  const [loading, setLoading] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  useEffect(() => {
    const logoTimer = setTimeout(() => {
      setShowLogo(false);
      setLoading(true);
    }, 2000);

    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearTimeout(logoTimer);
      clearTimeout(loadingTimer);
    };
  }, []);
  // eslint-disable-next-line no-nested-ternary
  return <>{showLogo ? <ShowLogo /> : loading ? <Loading /> : <Main />}</>;
}

export default App;
