import { Reducer } from 'redux';
import {
  RoverState,
  RoverTypes,
  RoverActions,
  DataCoord
} from '../../types/Rover';

const INITIAL_STATE: RoverState = {
  countdownVisibility: false,
  dataRover: {
    cinematic: [],
    static: [],
    snapshot: []
  },
  selectedPoint: {
    name: 'Selecione um ponto',
    latitude: -1,
    longitude: -1,
    altitude: -1
  }
};

const reducerRover: Reducer<RoverState, RoverActions> = (
  state = INITIAL_STATE,
  action: RoverActions
) => {
  let localDataRover: DataCoord[] = [];
  let localId = 1;
  let index = -1;
  switch (action.type) {
    case RoverTypes.CHANGE_COUNTDOWN_VISIBILITY:
      return {
        ...state,
        countdownVisibility: !state.countdownVisibility
      };
    case RoverTypes.INSERT_DATA_ROVER_TABLE:
      if (state.dataRover && state.dataRover[action.payload.type]) {
        localDataRover = state.dataRover[action.payload.type];
        if (state.dataRover[action.payload.type][0]) {
          localId = Number(state.dataRover[action.payload.type][0].id) + 1;
        }
      }
      localDataRover.unshift({
        ...action.payload,
        key: `point-${action.payload.id || localId}`,
        id: action.payload.id || localId,
        name: action.payload.name || `Ponto ${localId}`,
        time: action.payload.time
      });
      return {
        ...state,
        dataRover: {
          ...state.dataRover,
          [action.payload.type]: [...localDataRover]
        }
      };
    case RoverTypes.REMOVE_POINT:
      localDataRover = state.dataRover[action.payload.type].filter(
        point => point.key !== action.payload.key
      );
      return {
        ...state,
        dataRover: {
          ...state.dataRover,
          [action.payload.type]: [...localDataRover]
        }
      };
    case RoverTypes.EDIT_POINT:
      localDataRover = state.dataRover[action.payload.type];
      index = localDataRover.findIndex(
        point => point.key === action.payload.key
      );
      if (index > -1) {
        const item = localDataRover[index];
        localDataRover.splice(index, 1, {
          ...item,
          name: action.payload.name
        });
      }
      return {
        ...state,
        dataRover: {
          ...state.dataRover,
          [action.payload.type]: [...localDataRover]
        }
      };
    case RoverTypes.REMOVE_ALL_DATA_ROVER_TABLE:
      return {
        ...state,
        dataRover: {
          cinematic: [],
          static: [],
          snapshot: []
        }
      };
    case RoverTypes.SET_SELECTED_POINT:
      return {
        ...state,
        selectedPoint: {
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default reducerRover;
