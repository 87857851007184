import React, { useState, useEffect } from 'react';
import { PageHeader, Table, Layout, Button, ConfigProvider, Empty, Popconfirm, Spin, notification } from 'antd';

import type { ColumnsType } from 'antd/es/table';

import './style.css';
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, LoadingOutlined, DownloadOutlined } from '@ant-design/icons';

import { Dispatch, bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import * as WebsocketActions from '../../store/websocket/actions';
import * as GpsAction from '../../store/gps/actions';

import store from '../../index';
import { WebsocketTypes } from '../../types/Websocket';
import { ApplicationState } from '../../store/rootReducer';



const { Content } = Layout;

interface DataType {
  key: string;
  name: string;
}

interface StateProps {
  openFetchFile: number;
}

interface DispatchProps {
  connectToWebsocket(): void;
}

type Props = StateProps & DispatchProps;


const TableControl = ({ connectToWebsocket, openFetchFile}: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalElements, setTotalElements] = useState(11); // total de arquivos (contando todas as paginas)
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  //  carregamento da pagina enquanto todos dados da tabela não são pegos
  const [tableData, setTableData] = useState<DataType[]>([]);

  const [loading, setLoading] = useState<Record<string, boolean>>({});

  const fetchData = async () => {
    setIsLoading(true);

    const requestOptions = {
      method: 'GET',
    };

    fetch(`http://localhost:8080/list_files?page=${currentPage}`, requestOptions)
    .then(response => response.text())
      .then(data => {
        const filesArray = data.split(';');
        const elements = parseInt(filesArray[0], 10);
        filesArray.shift();
        filesArray.reverse()
        const formattedData = filesArray.map((name, index) => ({ key: String(index), name }));
        setTableData(formattedData);
        setTotalElements(elements);
      })
      .catch(error => {
        // para testar quando nao esta recebendo dados
        // const fakeData = "1;xrtk_Altura-da-antena_H2092_Data_2024-3-20_Hora_9-21-58";
        // const filesArray = fakeData.split(';');
        // const elements = parseInt(filesArray[0], 10);
        // filesArray.shift();
        // filesArray.reverse()
        // const formattedData = filesArray.map((name, index) => ({ key: String(index), name }));
        // setTableData(formattedData);
        // setTotalElements(elements);
        console.log(` Erro: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  const handleRemove = (currentName: string) => {
    setIsDeleting(true);

    const requestOptions = {
      method: 'POST',

    };

    fetch(`http://localhost:8080/delete_file?name=${currentName}`, requestOptions)
      .then(response => response.text())
      .then(data => {
        console.log(` Exclusão realizada com sucesso `);
        const updatedTableData = tableData.filter(item => item.name !== currentName);
        
        setTableData(updatedTableData);
        fetchData();
        
      })
      .catch(error => {
        console.log(` Erro: ${error.message}`);
      })
      .finally(() => setIsDeleting(false));
  };

  const handleDownload = async (currentName: string) => {

    const requestOptions = {
      method: 'GET',
      // headers: { 'Content-Type': 'application/octet-stream' },
    };

    try {
 
      store.dispatch({
        type: WebsocketTypes.OPEN_FETCH_FILE
      });
      const response = await fetch(`http://localhost:8080/get_file?name=${currentName}`, requestOptions);
  
      const blob = await response.blob();
      store.dispatch({
        type: WebsocketTypes.CLOSE_FETCH_FILE
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = currentName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      store.dispatch({
        type: WebsocketTypes.CLOSE_FETCH_FILE
      });
      console.error(`Erro: ${error}`);
      notification.error({
        message: 'Erro de Download',
        description: `Houve um erro ao baixar o arquivo ${currentName}. Por favor, tente novamente.`,
        className: 'error' 
      });
    }

    setLoading({ ...loading, [currentName]: false });
  
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]); 


  const columns: ColumnsType<DataType> = [
    {
      title: 'Nome do arquivo',
      dataIndex: 'name',
      key: 'name',
      width: '40%'
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: '25%',
      render: (_, record) => (
        <div className="table-action-buttons">
          <Button
            title="Download de imagens"
            onClick={() => {
              setLoading({ ...loading, [record.name]: true });
              handleDownload(record.name)}}
            className="trash-button"
            style={{height: '4rem'}}
          >
            {loading[record.name] ? (
              <span>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
              </span>
                ) : (
                  <>
                    <DownloadOutlined />
                    Baixar do XRTK 
                    <br /> 
                    para o Controle
                  </>     
                )}
          </Button>

    

          <Popconfirm
            title="Tem certeza de que deseja excluir este voo?"
            onConfirm={() => handleRemove(record.name)}
            okText="Sim"
            cancelText="Não"
            placement="bottomRight"
          >
            <Button
              title="Excluir voo"
              className="trash-button"
            > 
              <DeleteOutlined />
              <span style={{margin: '0rem'}}>Excluir do XRTK</span>
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ];
 
  return (
    <PageHeader title="Arquivos">
      <div className="table-page-header-content">
        <p className="header-subtitle">
          Aqui você encontra os arquivos Rinex gerados pelo XRTK em modo base, podendo baixar estes arquivos do XRTK para o controle.
        </p>
  

      </div>
      
      <Layout className="table-page">
        <Content className="table-page-content">
          <ConfigProvider
            renderEmpty={() => (
              <Empty description="Não há arquivos a serem exibidos" />
            )}
          >

            <Table
              className="table"
              loading={isLoading || isDeleting} 
              columns={columns}
              dataSource={tableData}
              pagination={{
                defaultCurrent: currentPage + 1,
                pageSize: 10,
                total: totalElements,
                showSizeChanger: false,
                onChange: (page) => setCurrentPage(page - 1),
                itemRender: (current, type, originalElement) => {
                  if (type === 'prev') {
                    return (<Button className="pagination-item"><ArrowLeftOutlined /></Button>);
                  }
                  if (type === 'next') {
                    return (<Button className="pagination-item"><ArrowRightOutlined /></Button>);
                  }
                  return originalElement;
                },
              }}
            />
          </ConfigProvider>
        </Content>
      </Layout>
    </PageHeader>
  );
};

const mapStateToProps = ({ websocket }: ApplicationState) => ({
  openFetchFile: websocket.openFetchFile
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(TableControl);

