import { Reducer } from 'redux';
import {
  WebsocketState,
  WebsocketTypes,
  WebsocketActions
} from '../../types/Websocket';

type Actions = WebsocketActions;
const INITIAL_STATE: WebsocketState = {
  fixBaseHotspot: 0,
  openIBGEsocket: 0,
  openFetchFile: 0,
  responseIbge: '',
  fixBaseNtrip: 0,
  connectionStatus: 0,
  validPositions: false,
  isSendingorrections: false,
  isCapturing: false,
  enableCorrection: false,
  downloadingFileKey: '',
  downloadPercentage: 0,
  downloadStatus: 'success',
  fileName: '',
  positions: [],
  secondsToCapture: '0',
  secondsInColete: '0',
  greyOperation: '0'
};

const reducerWebsocket: Reducer<WebsocketState, Actions> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case WebsocketTypes.IS_CONNECTING:
      return {
        ...state,
        connectionStatus: 1
      };
    case WebsocketTypes.IS_CONNECTED:
      return {
        ...state,
        connectionStatus: 2
      };
    case WebsocketTypes.FIX_BASE_HOTSPOT:
      return {
        ...state,
        fixBaseHotspot: 1
      };
    case WebsocketTypes.OPEN_IBGE_SOCKET:
      return {
        ...state,
        openIBGEsocket: 1
      };
    case WebsocketTypes.FIX_BASE_NTRIP:
      return {
        ...state,
        fixBaseNtrip: 1
      };
    case WebsocketTypes.UNFIX_BASE_HOTSPOT:
      return {
        ...state,
        fixBaseHotspot: 0
      };
    case WebsocketTypes.CLOSE_IBGE_SOCKET:
      return {
        ...state,
        openIBGEsocket: 0
      };
    case WebsocketTypes.OPEN_FETCH_FILE:
      return {
        ...state,
        openFetchFile: 1
      };
    case WebsocketTypes.CLOSE_FETCH_FILE:
      return {
        ...state,
        openFetchFile: 0
      };
    case WebsocketTypes.UNFIX_BASE_NTRIP:
      return {
        ...state,
        fixBaseNtrip: 0
      };

    case WebsocketTypes.ISNT_CONNECTED:
      return {
        ...state,
        connectionStatus: 0,
        fixBaseHotspot: 0,

        responseIbge: '',
        fixBaseNtrip: 0,
        validPositions: false,
        downloadingFileKey: '',
        downloadPercentage: 0,
        downloadStatus: 'success',
        fileName: '',
        positions: [],
        secondsToCapture: '0',
        secondsInColete: '0',
        greyOperation: '0'
      };
    case WebsocketTypes.IS_SENDING_VALID_POSITION: {
      return {
        ...state,
        validPositions: true,
        connectionStatus: 3
      };
    }
    case WebsocketTypes.ISNT_SENDING_VALID_POSITION: {
      return {
        ...state,
        validPositions: false
      };
    }
    case WebsocketTypes.ENABLE_CORRECTION:
      return {
        ...state,
        enableCorrection: true
      };

    case WebsocketTypes.CHANGE_SENDING_CORRECTIONS:
      return {
        ...state,
        isSendingorrections: !state.isSendingorrections
      };
    case WebsocketTypes.CHANGE_CAPTURING:
      return {
        ...state,
        isCapturing: !state.isCapturing
      };
    case WebsocketTypes.DOWNLOAD_FILE:
      return {
        ...state,
        downloadingFileKey: action.payload.downloadingFileKey,
        downloadStatus: 'active'
      };
    case WebsocketTypes.SET_DOWNLOAD_PERCENTAGE:
      return {
        ...state,
        downloadPercentage: action.payload.percentage
      };
    case WebsocketTypes.CHANGE_DOWNLOAD_STATUS: {
      return {
        ...state,
        downloadStatus: action.payload.downloadStatus,
        downloadPercentage:
          action.payload.downloadStatus === 'active'
            ? 0
            : state.downloadPercentage
      };
    }
    case WebsocketTypes.SET_RINEX_FILENAME: {
      return {
        ...state,
        fileName: action.payload.fileName
      };
    }

    case WebsocketTypes.SECONDS_TO_CAPTURE: {
      return {
        ...state,
        secondsToCapture: action.payload.secondsToCapture
      };
    }

    case WebsocketTypes.SECONDS_IN_COLETE: {
      return {
        ...state,
        secondsInColete: action.payload.secondsInColete
      };
    }

    case WebsocketTypes.GREY_OPERATION: {
      return {
        ...state,
        greyOperation: action.payload.greyOperation
      };
    }

    case WebsocketTypes.SET_SOCKET_RESPONSE_IBGE: {
      return {
        ...state,
        responseIbge: action.payload.responseIbge
      };
    }

    case WebsocketTypes.NEAR_POSITIONS: {
      return {
        ...state,
        positions:
          action.payload && action.payload.positions
            ? action.payload.positions.map(
                (position: {
                  latitude: string;
                  longitude: string;
                  altitude: string;
                  data: string;
                  dist: string;
                }) => ({
                  latitude: position.latitude,
                  longitude: position.longitude,
                  altitude: position.altitude,
                  data: position.data,
                  dist: position.dist
                })
              )
            : []
      };
    }

    default:
      return state;
  }
};

export default reducerWebsocket;
