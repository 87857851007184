import React from 'react';
import { connect } from 'react-redux';

import { Card } from 'antd';
import { ApplicationState } from '../../store/rootReducer';

type Props = {
  altitude: number;
  altitudeError: number;
  greyOperation: string;
};
const Altitude = ({ altitude, altitudeError, greyOperation }: Props) => (
  <span style={Number(greyOperation) ? { color: '#9b9b9b' } : {}}>
    {altitude.toFixed(3)}
    &nbsp;&#xB1;
    {altitudeError.toFixed(3)}
  </span>
);

const mapStateToProps = ({ gps, websocket }: ApplicationState) => ({
  altitude: Number(gps.position.altitude),
  altitudeError: Number(gps.positionError.altitude),
  greyOperation: websocket.greyOperation
});

export default connect(mapStateToProps)(Altitude);
