import { action } from 'typesafe-actions';
import { WebsocketTypes } from '../../types/Websocket';

export const connectToWebsocket = () => action(WebsocketTypes.START_CONNECTION);
export const disconnectFromWebsocket = () =>
  action(WebsocketTypes.START_DISCONNECTION);
export const changeSendingCorrections = () =>
  action(WebsocketTypes.CHANGE_SENDING_CORRECTIONS);
export const changeCapturing = () => action(WebsocketTypes.CHANGE_CAPTURING);
export const removeFile = (fileName: string) =>
  action(WebsocketTypes.REMOVE_FILE, {
    fileName
  });
export const downloadFile = (downloadingFileKey: string, fileName: string) =>
  action(WebsocketTypes.DOWNLOAD_FILE, {
    downloadingFileKey,
    fileName
  });

export const setRinexFilename = (fileName: string) =>
  action(WebsocketTypes.SET_RINEX_FILENAME, {
    fileName
  });

export const setSocketResponseIbge = (responseIbge: string) =>
  action(WebsocketTypes.SET_SOCKET_RESPONSE_IBGE, {
    responseIbge
  });

export const toggleRinexLog = (isActive: boolean, projectName: string) =>
  action(WebsocketTypes.TOGGLE_RINEX_LOG, {
    isActive,
    projectName
  });

export const setNearPositions = (
  positions: {
    latitude: string;
    longitude: string;
    altitude: string;
    data: string;
    dist: string;
  }[]
) => action(WebsocketTypes.NEAR_POSITIONS, { positions });

export const setSecondsToCapture = (secondsToCapture: string) =>
  action(WebsocketTypes.SECONDS_TO_CAPTURE, { secondsToCapture });

export const setSecondsInColete = (secondsInColete: string) =>
  action(WebsocketTypes.SECONDS_IN_COLETE, { secondsInColete });

export const setGreyOperation = (greyOperation: string) =>
  action(WebsocketTypes.GREY_OPERATION, { greyOperation });
