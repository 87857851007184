/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';

import { Button, Layout, Menu, Modal } from 'antd';

import {
  CloseCircleTwoTone,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  EnvironmentFilled,
  FileFilled
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi, faCircle, faUpload } from '@fortawesome/free-solid-svg-icons';

import { ApplicationState } from '../../store/rootReducer';
import * as WebsocketActions from '../../store/websocket/actions';
import * as LayoutActions from '../../store/layout/actions';
import * as GpsAction from '../../store/gps/actions';
import './style.css';
import FullLogo from '../../images/logo.png';
import IconBase from '../../icons/iconBase.png';
import IconMode from '../../icons/engine.png';
import IconBattery from '../../icons/battery.png';
import {
  ConfigurationTypes,
  ConfigurationLocationTypes
} from '../../types/Gps';
import Processamento from '../../view/file-process-control/Processamento';
import NtripControl from '../../view/ntrip-control/NtripControl';

import wifi from '../../view/tutorial-control/images/wifi.png';

import hotspot from '../../view/tutorial-control/images/hotspot.png';

const { confirm } = Modal;

const { Sider } = Layout;

interface StateProps {
  connectionStatus: number;
  modeType: string;
  configType: ConfigurationLocationTypes;
  isConfirmRinexOpen: boolean;
  isRtcmMessage: boolean;
  fixBaseHotspot: number;
  openIBGEsocket: number;
  openFetchFile: number;
}

interface DispatchProps {
  disconnectFromWebsocket(): void;
  connectToWebsocket(): void;
  toggleSideBar(): void;
  configGps(
    location: ConfigurationLocationTypes,
    type: ConfigurationTypes
  ): void;
  setConfigType(configType: ConfigurationLocationTypes): void;
  setConfirmRinexOpen(isOpen: boolean): void;
  SetRtcmMessage(isRtcm: boolean): void;
}

type Props = StateProps & DispatchProps;

const SiderMenu = ({
  modeType,
  toggleSideBar,
  disconnectFromWebsocket,
  connectToWebsocket,
  configGps,
  setConfigType,
  configType,
  connectionStatus,
  isConfirmRinexOpen,
  setConfirmRinexOpen,
  isRtcmMessage,
  SetRtcmMessage,
  fixBaseHotspot,
  openIBGEsocket,
  openFetchFile
}: Props) => {
  const location = useLocation();
  const windowWidth = document.getElementsByTagName('body')[0].clientWidth;
  const history = useHistory();

  const handleMenuClick = (
    locationTo:
      | '/extra'
      | '/base'
      | '/table'
      | '/coordinate-table'
      | '/ntrip'
      | '/bateria'
      | '/processamento'
  ) => {
    history.push(locationTo);
  };

  // const [color, setColor] = useState('#00c880');
  const [modalPage, setModalPage] = useState(0);
  const [sendFilesVisible, setSendFilesVisible] = useState(false);

  useEffect(() => {
    if (connectionStatus === 2) {
      configGps(configType, 'initial_config');
    }
  }, [configGps, configType, connectionStatus]);

  useEffect(() => {
    if (isConfirmRinexOpen) {
      confirm({
        title: 'Deseja abrir a janela do Rinex?',
        icon: <ExclamationCircleOutlined />,
        okButtonProps: { href: 'xgbastransfer://' }
      });
    }
  }, [isConfirmRinexOpen, setConfirmRinexOpen]);

  useEffect(() => {
    setConfigType('base');
  }, [location, setConfigType]);

  const [modalVisible, setModalVisible] = useState(false);

  const handleLinkClick = (e: { preventDefault: () => void }) => {
    if (!modalVisible) {
      e.preventDefault();
      setModalVisible(true);
    }
  };

  const handleProcessLinkClick = (e: { preventDefault: () => void }) => {
    if (!sendFilesVisible && !(fixBaseHotspot > 0)) {
      setSendFilesVisible(true);
    } else if (!sendFilesVisible) history.push('/processamento');
  };

  const handleModalConfirm = () => {
    setModalVisible(false);

    history.push('/');
  };

  const handleModalFilesConfirm = () => {
    if (connectionStatus >= 2 && modalPage === 0) {
      setModalPage(modalPage + 2);
    } else if (modalPage < 2) {
      setModalPage(modalPage + 1);
    } else {
      setModalPage(1);
      setSendFilesVisible(false);

      history.push('/processamento');
    }
  };

  const handleModalFilesFinish = (data: boolean) => {
    if (data) {
      setModalPage(0);
      setSendFilesVisible(false);

      history.push('/processamento');
    } else {
      setModalPage(modalPage - 1);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleModalFilesCancel = () => {
    if (connectionStatus >= 2 && modalPage === 1) {
      setModalPage(0);
      setSendFilesVisible(false);
    } else if (modalPage < 1) {
      setModalPage(0);
      setSendFilesVisible(false);
    } else if (modalPage >= 1) {
      setModalPage(modalPage - 1);
    }
  };

  const steps = [
    {
      title: (
        <div>
          <p className="file-mode-title">
            Conexão da base ao controle do drone
          </p>
        </div>
      ),
      content: (
        <div>
          <p className="description-file">
            Conecte o controle do drone na base XRTK utilizando o wifi, busque
            pela rede “XRTK” e insira a senha “12345678”.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={wifi} alt="Marcação" className="img-file" />
    },
    {
      title: (
        <div>
          <p className="file-mode-title">Habilitando o hotspot ou wifi</p>
        </div>
      ),
      content: (
        <div>
          <p className="description-file">
            Compartilhe a internet do seu celular ou certifique-se de que esteja
            no alcance de uma rede wifi para continuar.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={hotspot} alt="Marcação" className="img-file" />,
      wifi: true
    },
    {
      title: (
        <div>
          <p className="file-mode-title">Conecte a base à internet</p>
        </div>
      ),
      content: (
        <NtripControl
          tutorialWifi={true}
          tutorialNTRIP={false}
          onPositionSucess={handleModalFilesFinish}
        />
      ),
      extra: false,
      wifi: true,
      image: (
        <div>
          <p className="description-file">
            Preencha os campos para conectar sua base à internet.
          </p>
        </div>
      )
    }
  ];

  const countFlag = () => {
    const controller = new AbortController();
    const ms = 5000;
    const timeoutId = setTimeout(() => controller.abort(), ms);

    const requestOptions = {
      method: 'POST',
      signal: controller.signal
    };

    let show = 0;
    if (modeType === 'Relativo marcado' || modeType === 'Relativo novo') {
      show = 1;
    }

    fetch(
      `http://localhost:8080/set_autoconvergence?status=${show}`,
      requestOptions
    )
      .then(response => {
        response.text();
        clearTimeout(timeoutId);
      })
      .catch(error => {
        console.log(` Erro: ${error.message}`);
        clearTimeout(timeoutId);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!openFetchFile) countFlag();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [openFetchFile]);

  return (
    <Sider
      // collapsedWidth={windowWidth <= 479.98 ? 0 : 80}
      // collapsible
      // collapsed={isCollapsed}
      // onCollapse={toggleSideBar}
      className="sider-menu"
    >
      <div className="logo-div">
        <img src={FullLogo} alt="" className="logo" />
      </div>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        className="menu-opts"
      >
        <Link
          to="#"
          onClick={e => {
            if (!(openIBGEsocket > 0)) {
              handleLinkClick(e);
            }
          }}
          className="menuopt"
        >
          <Menu.Item
            key="#"
            title="Modo"
            className={location.pathname === '#' ? 'rover_opt' : ''}
            disabled={openIBGEsocket > 0}
          >
            <img src={IconMode} alt="" className="logo" />
          </Menu.Item>
          <span
            style={{
              color: openIBGEsocket > 0 ? 'gray' : 'inherit'
            }}
          >
            Modo
          </span>
          <Modal
            visible={modalVisible}
            onOk={handleModalConfirm}
            onCancel={handleModalCancel}
            okText="Sim"
            cancelText="Não, cancelar"
            okButtonProps={{ className: 'next-button' }}
            cancelButtonProps={{ className: 'back-button' }}
          >
            <h5 className="card_h5">Alterar modo</h5>
            <span style={{ color: '#ffffffa6' }}>
              Atualmente você esta no modo "{modeType}". Deseja voltar ao menu
              inicial?
            </span>
          </Modal>
        </Link>
        <Link
          to="/extra"
          onClick={e => {
            e.preventDefault();
            if (connectionStatus > 1 && !(openIBGEsocket > 0)) {
              handleMenuClick('/extra');
            }
          }}
          className="menuopt"
        >
          <Menu.Item
            key="/extra"
            title="Conexão"
            className={location.pathname === '/extra' ? 'rover_opt' : ''}
            disabled={openIBGEsocket > 0}
          >
            <FontAwesomeIcon icon={faWifi} style={{ color: '#FFFFFFF2' }} />
          </Menu.Item>
          <span
            style={{
              color: openIBGEsocket > 0 ? 'gray' : 'inherit'
            }}
          >
            Conexão
          </span>
        </Link>

        <Link
          to="/base"
          onClick={e => {
            e.preventDefault();
            if (connectionStatus > 1 && !(openIBGEsocket > 0)) {
              handleMenuClick('/base');
            }
          }}
          className="menuopt"
        >
          <Menu.Item
            key="/base"
            title="Base"
            className={location.pathname === '/base' ? 'rover_opt' : ''}
            disabled={
              connectionStatus < 2 || isRtcmMessage || openIBGEsocket > 0
            }
          >
            <img
              src={IconBase}
              alt=""
              className="logo"
              style={{
                opacity: connectionStatus < 2 ? 0.5 : 1
              }}
            />
          </Menu.Item>
          <span
            style={{
              color:
                connectionStatus < 2 || openIBGEsocket > 0 ? 'gray' : 'inherit'
            }}
          >
            Operação
          </span>
        </Link>

        <Link
          to="/coordinate-table"
          onClick={e => {
            e.preventDefault();
            if (connectionStatus > 1 && !(openIBGEsocket > 0)) {
              handleMenuClick('/coordinate-table');
            }
          }}
          className="menuopt"
        >
          <Menu.Item
            key="/coordinate-table"
            title="Tabela de coordenadas"
            className={
              location.pathname === '/coordinate-table' ? 'rover_opt' : ''
            }
            disabled={
              connectionStatus < 2 || isRtcmMessage || openIBGEsocket > 0
            }
          >
            <EnvironmentFilled
              style={{ opacity: connectionStatus < 2 ? 0.5 : 1 }}
            />
          </Menu.Item>
          <span
            style={{
              color:
                connectionStatus < 2 || openIBGEsocket > 0 ? 'gray' : 'inherit'
            }}
          >
            Locais
          </span>
        </Link>

        {(modeType === 'Absoluto sem internet' ||
          modeType === 'Avançado' ||
          modeType === 'Ponto pós-processado' ||
          modeType === 'Voo pós processado' ||
          modeType === null) && (
          <Link
            to="/table"
            onClick={e => {
              e.preventDefault();
              if (connectionStatus > 1 && !(openIBGEsocket > 0)) {
                handleMenuClick('/table');
              }
            }}
            className="menuopt"
          >
            <Menu.Item
              key="/table"
              title="Tabela de arquivos"
              className={location.pathname === '/table' ? 'rover_opt' : ''}
              disabled={
                connectionStatus < 2 || isRtcmMessage || openIBGEsocket > 0
              }
            >
              <FileFilled style={{ opacity: connectionStatus < 2 ? 0.5 : 1 }} />
            </Menu.Item>
            <span
              style={{
                color:
                  connectionStatus < 2 || openIBGEsocket > 0
                    ? 'gray'
                    : 'inherit'
              }}
            >
              Arquivos
            </span>
          </Link>
        )}
        <Link
          to="/bateria"
          onClick={e => {
            e.preventDefault();
            if (connectionStatus > 1 && !(openIBGEsocket > 0)) {
              handleMenuClick('/bateria');
            }
          }}
          className="menuopt"
        >
          <Menu.Item
            key="/bateria"
            title="Bateria"
            className={location.pathname === '/bateria' ? 'rover_opt' : ''}
            disabled={openIBGEsocket > 0}
          >
            <img
              src={IconBattery}
              alt=""
              className="logo"
              style={{
                opacity: connectionStatus < 2 ? 0.5 : 1
              }}
            />
          </Menu.Item>
          <span
            style={{
              color:
                connectionStatus < 2 || openIBGEsocket > 0 ? 'gray' : 'inherit',
              width: '5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Status XRTK
          </span>
        </Link>
        {(modeType === 'Avançado' ||
          modeType === 'Ponto pós-processado' ||
          modeType === 'Voo pós processado' ||
          modeType === null) && (
          <Link
            to="#"
            onClick={e => {
              if (!(openIBGEsocket > 0)) {
                handleProcessLinkClick(e);
              }
            }}
            className="menuopt"
          >
            <Menu.Item
              key="#"
              title="Processamento"
              className={location.pathname === '#' ? 'rover_opt' : ''}
              disabled={openIBGEsocket > 0}
            >
              <FontAwesomeIcon icon={faUpload} style={{ color: '#FFFFFFF2' }} />
            </Menu.Item>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Enviar para
            </span>
            <span>IBGE</span>

            <Modal
              visible={sendFilesVisible && !openIBGEsocket}
              onOk={handleModalFilesConfirm}
              onCancel={handleModalFilesCancel}
              okText="Avançar"
              cancelText="Voltar"
              okButtonProps={{ className: 'next-button' }}
              cancelButtonProps={{ className: 'back-button' }}
              className="modal-file-tutorial"
              footer={
                modalPage < 2
                  ? [
                      <>
                        <Button
                          key="back"
                          onClick={handleModalFilesCancel}
                          className="back-button"
                        >
                          Voltar
                        </Button>

                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleModalFilesConfirm}
                          className="next-button"
                        >
                          Avançar
                        </Button>
                      </>
                    ]
                  : [<></>]
              }
            >
              {modalPage === 0 && connectionStatus >= 2 ? (
                <>
                  <div className="progress-content-text">
                    {steps[modalPage + 1].title}
                  </div>
                  <div className="steps-box">
                    <div style={{ width: '100%' }}>
                      {steps[modalPage + 1].content}
                    </div>
                    <div className="steps-content">
                      {steps[modalPage + 1].image}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="progress-content-text">
                    {steps[modalPage].title}
                  </div>
                  <div className="steps-box">
                    <div style={{ width: '100%' }}>
                      {steps[modalPage].content}
                    </div>
                    <div className="steps-content">
                      {steps[modalPage].image}
                    </div>
                  </div>
                </>
              )}
            </Modal>
          </Link>
        )}
        <Menu.Item
          key=""
          title="Estado de conexão"
          className="menu-icon"
          style={{
            marginTop: '1rem'
          }}
          disabled
        >
          <>
            {connectionStatus === 0 ? (
              <>
                <CloseCircleTwoTone twoToneColor="#ff0000" />
              </>
            ) : connectionStatus >= 2 ? (
              <>
                <FontAwesomeIcon icon={faCircle} className="faCircle" />
              </>
            ) : (
              <>
                <ExclamationCircleTwoTone twoToneColor="#ffa500" />
              </>
            )}
          </>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const mapStateToProps = ({ websocket, layout, gps }: ApplicationState) => ({
  modeType: layout.modeType,
  connectionStatus: websocket.connectionStatus,
  configType: gps.configType,
  isConfirmRinexOpen: gps.isConfirmRinexOpen,
  isRtcmMessage: gps.isRtcmMessage,
  openIBGEsocket: websocket.openIBGEsocket,
  fixBaseHotspot: websocket.fixBaseHotspot,
  openFetchFile: websocket.openFetchFile
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { ...WebsocketActions, ...LayoutActions, ...GpsAction },
    dispatch
  );

export default connect(mapStateToProps, mapDispacthToProps)(SiderMenu);
