import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, Store, applyMiddleware, compose } from 'redux';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import App from './App';
import websocketMiddleware from './middleware/websocketMiddleware';
import * as serviceWorker from './serviceWorker';
import rootReducer, { ApplicationState } from './store/rootReducer';

require('dotenv').config();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store: Store<ApplicationState> = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(websocketMiddleware))
);

export default store;

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={ptBR}>
      <App />
    </ConfigProvider>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
