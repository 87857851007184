export function processSolutionType(type: number): string {
  switch (type) {
    case 0:
      return 'No Solution';
    case 11:
      return 'Dead Reckoning';
    case 1:
      return 'Autonomous';
    case 2:
      return 'Differential';
    case 10:
      return 'Precise';
    case 5:
      return 'Float';
    case 4:
      return 'Fixed';
    default:
      return 'No Recognized Solution';
  }
}

export function getSolutionTypePriority(type: string): number {
  switch (type) {
    case 'Fixed':
      return 4;
    case 'Float':
      return 3;
    case 'Differential':
      return 2;
    case 'Autonomous':
      return 1;
    case 'No Solution':
    default:
      return 0;
  }
}

export function getSolutionTypePriorityByNumber(type: number): string {
  switch (type) {
    case 4:
      return 'Fixed';
    case 3:
      return 'Float';
    case 2:
      return 'Differential';
    case 1:
      return 'Autonomous';
    case 0:
    default:
      return 'No Solution';
  }
}
