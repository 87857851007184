import React from 'react';
import { connect } from 'react-redux';

import { Card } from 'antd';
import { ApplicationState } from '../../store/rootReducer';

type Props = {
  satellitesNumber: number;
  satellitesTotal: number;
  greyOperation: string;
};
const Satelites = ({
  satellitesNumber,
  satellitesTotal,
  greyOperation
}: Props) => (
  <span style={Number(greyOperation) ? { color: '#9b9b9b' } : {}}>
    {satellitesNumber}
    &nbsp; /&nbsp; {satellitesTotal}
  </span>
);

const mapStateToProps = ({ gps, websocket }: ApplicationState) => ({
  satellitesNumber: Number(gps.position.satellitesNumber),
  greyOperation: websocket.greyOperation,
  satellitesTotal:
    gps.satellitesTotal.GPS +
    gps.satellitesTotal.GLONASS +
    gps.satellitesTotal.GALILEO +
    gps.satellitesTotal.BEIDOU
});

export default connect(mapStateToProps)(Satelites);
