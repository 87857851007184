export declare type NotificationTypes =
  | 'success'
  | 'info'
  | 'error'
  | 'warning'
  | '';

export declare type NotificationPayload = {
  show: boolean;
  type: NotificationTypes;
  message: string;
  description: string;
};

export enum LayoutTypes {
  TOGGLE_SIDEBAR = '@layout/TOGGLE_SIDEBAR',
  SHOW_NOTIFICATION = '@layout/SHOW_NOTIFICATION',
  SET_MODE_TYPE = '@layout/SET_MODE_TYPE'
}

interface ToggleSideBarAction {
  type: typeof LayoutTypes.TOGGLE_SIDEBAR;
}

interface ShowNotificationAction {
  type: typeof LayoutTypes.SHOW_NOTIFICATION;
  payload: NotificationPayload;
}

export interface SetModeTypeAction {
  type: LayoutTypes.SET_MODE_TYPE;
  payload: string;
}

export type LayoutActions =
  | ToggleSideBarAction
  | ShowNotificationAction
  | SetModeTypeAction;

export interface LayoutState {
  readonly modeType: string;
  readonly notification: NotificationPayload;
}
