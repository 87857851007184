import React from 'react';
import { connect } from 'react-redux';

import { Card } from 'antd';
import { ApplicationState } from '../../store/rootReducer';

type Props = {
  longitude: number;
  longitudeError: number;
  greyOperation: string;
};
const Longitude = ({ longitude, longitudeError, greyOperation }: Props) => (
  <span style={Number(greyOperation) ? { color: '#9b9b9b' } : {}}>
    {longitude.toFixed(8)}
    &nbsp; &#xB1;
    {longitudeError.toFixed(3)}
  </span>
);

const mapStateToProps = ({ gps, websocket }: ApplicationState) => ({
  longitude: Number(gps.position.longitude),
  longitudeError: Number(gps.positionError.longitude),
  greyOperation: websocket.greyOperation
});

export default connect(mapStateToProps)(Longitude);
