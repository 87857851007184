/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  PageHeader,
  Row,
  Col,
  Collapse,
  Button,
  Input,
  Select,
  Table,
  notification,
  Spin,
  Form,
  Modal,
  Checkbox
} from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  DeleteOutlined,
  ExclamationCircleTwoTone,
  EyeOutlined
} from '@ant-design/icons';
import './style.css';
// eslint-disable-next-line import/no-unresolved
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { shutdownNtripConn } from '../../utils/NtripRequest';
import { ApplicationState } from '../../store/rootReducer';
import Password from 'antd/lib/input/Password';

const { Option } = Select;

interface NtripRecord {
  key: string;
  ip: string;
  port: string;
  base: string;
  username: string;
  password: string;
}

interface StateProps {
  fixBaseHotspot: number;
  fixBaseNtrip: number;
  connectionStatus: number;
  latitude: number;
  longitude: number;
}

interface DispatchProps {
  tutorialWifi: boolean;
  tutorialNTRIP: boolean;
  onPositionSucess(data: any): void;
}

type Props = StateProps & DispatchProps;

const NtripControl = ({
  connectionStatus,
  fixBaseHotspot,
  tutorialWifi,
  tutorialNTRIP,
  fixBaseNtrip,
  latitude,
  longitude,
  onPositionSucess
}: Props) => {
  const history = useHistory();
  const status = (history.location.state as { status: string })?.status;
  const [options, setOptions] = useState<string[]>(['']);

  const [selectedBase, setSelectedBase] = useState(
    localStorage.getItem('selectedBase') || 'EESC0'
  );

  const [ipValue, setIpValue] = useState(
    localStorage.getItem('ipValue') || '170.84.40.52'
  );
  const [portValue, setPortValue] = useState(
    localStorage.getItem('portValue') || '2101'
  );

  const [username, setUsername] = useState(
    localStorage.getItem('username') || 'xrtk'
  );
  const [password, setPassword] = useState(
    localStorage.getItem('password') || '$x7XRTK$'
  );
  const [ssidValue, setSsidValue] = useState(
    localStorage.getItem('ssidValue') || 'admin'
  );
  const [passwordSsidValue, setPasswordSsidValue] = useState(
    localStorage.getItem('passwordSsidValue') || 'admin'
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<{
    key: string;
    ip: string;
    port: string;
    base: string;
    username: string;
    password: string;
    ssid: string;
    passwordSsid: string;
  } | null>(null); // salva o registro selecionado
  const [connected, setConnected] = useState(false);
  const [autoConf, setAutoConf] = useState(1);
  const [savedData, setSavedData] = useState<
    Array<{
      passwordSsid: string;
      ssid: string;
      key: string;
      ip: string;
      port: string;
      base: string;
      username: string;
      password: string;
    }>
  >([
    {
      key: '0',
      ip: '170.84.40.52',
      port: '2101',
      base: 'EESC0',
      username: 'xrtk',
      password: '$x7XRTK$',
      ssid: 'admin',
      passwordSsid: 'admin'
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingNtrip, setIsLoadingNtrip] = useState(false);

  const [color, setColor] = useState('#00c880');

  const passwordInputRef = useRef<Password>(null);
  const portInputRef = useRef<Input>(null);
  const userInputRef = useRef<Input>(null);
  const ssidPasswordInputRef = useRef<Password>(null);

  const handleEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    ref: React.MutableRefObject<Input | Password | null>
  ) => {
    if (e.key === 'Enter' && ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    // Carregar dados da tabela
    const savedDataString = localStorage.getItem('savedData');
    if (savedDataString) {
      const parsedData = JSON.parse(savedDataString);
      setSavedData(parsedData);
    }

    const timer = setInterval(() => {
      setColor(prevColor => (prevColor === '#00c880' ? '#00e670' : '#00c880'));
    }, 500);
    return () => clearInterval(timer);
  }, []);

  const handleInputChange = (
    input: string,
    event: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    let value: string;

    if (typeof event === 'string') {
      value = event;
    } else {
      value = event.target.value;
    }

    switch (input) {
      case 'ip':
        setIpValue(value);
        localStorage.setItem('ipValue', value);

        break;
      case 'port':
        setPortValue(value);
        localStorage.setItem('portValue', value);

        break;
      case 'username':
        setUsername(value);
        localStorage.setItem('username', value);

        break;
      case 'password':
        setPassword(value);
        localStorage.setItem('password', value);

        break;
      case 'base':
        setSelectedBase(value);
        localStorage.setItem('base', value);

        break;
      case 'ssid':
        setSsidValue(value);
        localStorage.setItem('ssidValue', value);

        break;
      default:
        setPasswordSsidValue(value);
        localStorage.setItem('passwordSsidValue', value);

        break;
    }
  };

  const handleBaseSelect = (value: string) => {
    setSelectedBase(value);
    localStorage.setItem('base', value);
  };

  const disconnectBtn = () => {
    shutdownNtripConn();
    setConnected(false);
  };

  // Salvar dados na tabela
  const saveDataToTable = () => {
    if (ipValue && portValue && selectedBase && username && password) {
      const newData = {
        key: Date.now().toString(),
        ip: ipValue,
        port: portValue,
        base: selectedBase,
        username,
        password,
        ssid: ssidValue,
        passwordSsid: passwordSsidValue
      };
      const isDataRepeated = savedData.some(
        item =>
          item.ip.trim() === newData.ip.trim() &&
          item.port.trim() === newData.port.trim() &&
          item.base.trim() === newData.base.trim() &&
          item.username.trim() === newData.username.trim() &&
          item.password.trim() === newData.password.trim() &&
          item.ssid.trim() === newData.ssid.trim() &&
          item.passwordSsid.trim() === newData.passwordSsid.trim()
      );
      if (!isDataRepeated) {
        const updatedData = [...savedData, newData];
        setSavedData(updatedData);

        localStorage.setItem('savedData', JSON.stringify(updatedData));
      } else {
        console.log('Data is repeated.');
      }
    } else {
      console.log('Data is empty.');
    }
  };

  const onConnectionSuccess = () => {
    setConnected(true);
    setIsModalVisible(false);
    setIsModalVisibleNtrip(false);
  };

  const onDisconnectionStatus = () => {
    setConnected(false);
    setIsModalVisible(false);
    setIsModalVisibleNtrip(false);
  };
  const validateName = (value: string) => {
    const pattern = /^[A-Za-z0-9_]+(?:\s+)?$/;

    return pattern.test(value);
  };
  const ConnectHotspot = async (ssid: string, passwordSsid: string) => {
    const isValidFileName = validateName(ssid);

    if (!isValidFileName) {
      notification.error({
        message: 'Erro',
        description:
          'Nome do arquivo inválido, não utilize caracteres especiais.',
        className: 'error'
      });
      return;
    }
    setIsLoading(true);
    const requestOptions = {
      method: 'POST'
    };
    const url = `http://localhost:8080/config_sta?sta_user=${ssid}&sta_pwd=${passwordSsid}`;
    await fetch(url, requestOptions)
      .then(res => {
        if (res.ok) {
          notification.success({
            message: 'Sucesso',
            description: 'Hotspot configurado'
          });
          onConnectionSuccess();
        }
      })
      .catch(e => {
        setIsLoading(false);
        notification.error({
          message: 'Erro',
          description: 'Não foi possível configurar o Hotspot',
          className: 'error'
        });
        onDisconnectionStatus();
      });
    saveDataToTable();
    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    onPositionSucess(true);
  };

  const ConnectNtrip = async (
    ip: string,
    port: string,
    base: string,
    // eslint-disable-next-line no-shadow
    username: string,
    // eslint-disable-next-line no-shadow
    password: string
  ) => {
    setIsLoadingNtrip(true);

    const requestOptions = {
      method: 'POST'
    };
    let baseAux = base.replace(/ \(.*\)/, '');
    baseAux = baseAux.trim();

    const url = `http://localhost:8080/config_caster_client?caster_user=${username}&caster_password=${password}&caster_port=${port}&caster_host=${ip}&caster_mountpoint=${baseAux}&need2autoconf=${autoConf}`;

    await fetch(url, requestOptions)
      .then(res => {
        if (res.ok) {
          notification.success({
            message: 'Sucesso',
            description: 'Conexão Ntrip configurada'
          });
          onConnectionSuccess();
        }
      })
      .catch(e => {
        setIsLoadingNtrip(false);
        notification.error({
          message: 'Erro',
          description: 'Não foi possível configurar a conexão Ntrip',
          className: 'error'
        });
        onDisconnectionStatus();
      });
    saveDataToTable();
    const timeoutDuration = connectionStatus < 2 ? 20000 : 10000;

    setTimeout(() => {
      setIsLoadingNtrip(false);
    }, timeoutDuration);
    onPositionSucess(true);
  };

  const handleDelete = (record: NtripRecord) => {
    const updatedData = savedData.filter(item => item.key !== record.key);
    setSavedData(updatedData);
    localStorage.setItem('savedData', JSON.stringify(updatedData));
  };

  // Configuração da tabela
  const columns = [
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip'
    },
    {
      title: 'Porta',
      dataIndex: 'port',
      key: 'port'
    },
    {
      title: 'Base',
      dataIndex: 'base',
      key: 'base'
    },
    {
      title: 'Usuário',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'SSID',
      dataIndex: 'ssid',
      key: 'ssid'
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: NtripRecord) => (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(record)}
        />
      )
    }
  ];

  const makeRequestBases = async () => {
    setIsLoading(true);

    const timeout = setTimeout(() => {
      setIsLoading(false);
      console.log('Tempo limite da requisição excedido');
      notification.error({
        message: 'Erro',
        description: 'Não foi possível obter novas bases',
        className: 'error'
      });
      clearTimeout(timeout);
    }, 5000);
    fetch(
      `https://xrtk.xmobots.com/api/sourcetable?latitude=${latitude}&longitude=${longitude}&IP=${ipValue}&port=${portValue}`
    )
      .then(response => response.text())
      .then(data => {
        clearTimeout(timeout);
        const aux = JSON.parse(data);
        const optionsAux = aux.map(
          (item: { name: string; distance: number }) =>
            `${item.name} (${item.distance.toFixed(2)}km)`
        );
        setOptions(optionsAux);
        setIsLoading(false);
        notification.success({
          message: 'Sucesso',
          description: 'Bases atualizadas.',
          className: 'sucess'
        });
      })
      .catch(error => {
        setIsLoading(false);
        // para testes home office
        // const dataAux = [
        //   {
        //     name: 'EESC0',
        //     latitude: -22.0,
        //     longitude: -47.9,
        //     distance: 0.2020223615876712
        //   },
        //   {
        //     name: 'SPBO0',
        //     latitude: -22.85,
        //     longitude: -48.43,
        //     distance: 109.29000614105745
        //   },
        //   {
        //     name: 'SPC11',
        //     latitude: -22.82,
        //     longitude: -47.06,
        //     distance: 125.6692228907008
        //   },
        //   {
        //     name: 'SPC10',
        //     latitude: -22.82,
        //     longitude: -47.06,
        //     distance: 125.6692228907008
        //   },
        //   {
        //     name: 'MGIN0',
        //     latitude: -22.32,
        //     longitude: -46.33,
        //     distance: 165.52368112810817
        //   },
        //   {
        //     name: 'SPS10',
        //     latitude: -23.48,
        //     longitude: -47.42,
        //     distance: 171.93456271379785
        //   },
        //   {
        //     name: 'SPFR0',
        //     latitude: -20.51,
        //     longitude: -47.39,
        //     distance: 173.70353211466806
        //   },
        //   {
        //     name: 'POLI1',
        //     latitude: -23.56,
        //     longitude: -46.73,
        //     distance: 211.01204903229703
        //   },
        //   {
        //     name: 'POLI0',
        //     latitude: -23.56,
        //     longitude: -46.73,
        //     distance: 211.01204903229703
        //   }
        // ];
        // const data = JSON.stringify(dataAux);
        // const aux = JSON.parse(data);
        // const optionsAux = aux.map(
        //   (item: { name: string; distance: number }) =>
        //     `${item.name} (${item.distance.toFixed(2)}km)`
        // );
        // setOptions(optionsAux);
        clearTimeout(timeout);
        console.log('Error occurred', error);
        notification.error({
          message: 'Erro',
          description: 'Não foi possível obter novas bases',
          className: 'error'
        });
      });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleNtrip, setIsModalVisibleNtrip] = useState(false);

  const showModal = () => {
    if (status === 'Internet') setIsModalVisible(true);
    else setIsModalVisibleNtrip(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisibleNtrip(false);
  };

  const handleLoadDefaultValues = () => {
    setIpValue('170.84.40.52');
    setPortValue('2101');
    setSelectedBase('EESC0');
    setUsername('xrtk');
    setPassword('$x7XRTK$');
    setSsidValue('admin');
    setPasswordSsidValue('admin');
    // ConnectNtrip('170.84.40.52', '2101', 'EESC0', 'xrtk', '$x7XRTK$');
    localStorage.removeItem('savedData');
  };

  // preenche campos ao clicar em linha no histórico de conexões
  const handleRowSelect = (record: typeof selectedRecord) => {
    if (record) {
      setSelectedRowKeys([record.key]);
      setSelectedRecord(record);
      setIpValue(record.ip);
      setPortValue(record.port);
      setSelectedBase(record.base);
      setUsername(record.username);
      setPassword(record.password);
      setSsidValue(record.ssid);
      setPasswordSsidValue(record.passwordSsid);

      showModal();
    }
  };
  return (
    <>
      {!(tutorialWifi || tutorialNTRIP) && (
        <PageHeader title={status} className="table-page-header">
          <div className="table-page-header-content">
            <p className="header-subtitle">
              {status === 'Internet'
                ? 'Veja aqui suas configurações de rede'
                : 'Siga os passos abaixo para finalizar as configurações'}
            </p>
            <div>
              <Button
                onClick={async e => {
                  e.preventDefault();
                  history.push('/extra');
                }}
                className="next-button"
              >
                Voltar
              </Button>
              <Button
                type="primary"
                onClick={showModal}
                className="next-button"
              >
                {status === 'Internet'
                  ? 'Adicionar nova rede'
                  : 'Conectar nova conta'}
              </Button>
            </div>
          </div>
          <div className="container">
            {(fixBaseHotspot === 0 ||
              (connectionStatus === 0 && status === 'Internet')) &&
            isLoading === false &&
            status === 'Internet' ? (
              <Row>
                <Col style={{ marginRight: '1rem', color: 'white' }}>
                  Desconectado
                </Col>
                <Col>
                  <CloseCircleTwoTone twoToneColor="#ff0000" />
                </Col>
              </Row>
            ) : isLoading === false && status === 'Internet' ? (
              <Row>
                <Col style={{ marginRight: '1rem', color: 'white' }}>
                  Conectado
                </Col>
                <Col>
                  <FontAwesomeIcon icon={faCircle} className="faCircle" />
                </Col>
                <Col style={{ marginLeft: '3rem', color: 'white' }}>Rede:</Col>
                <Col style={{ marginLeft: '1rem', color: 'white' }}>
                  {ipValue}
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {status === 'NTRIP' &&
            (fixBaseNtrip === 0 || connectionStatus === 0) &&
            isLoadingNtrip === false &&
            status === 'NTRIP' ? (
              <Row>
                <Col style={{ marginRight: '1rem', color: 'white' }}>
                  Desconectado
                </Col>
                <Col>
                  <CloseCircleTwoTone twoToneColor="#ff0000" />
                </Col>
              </Row>
            ) : isLoadingNtrip === false && status === 'NTRIP' ? (
              <Row>
                <Col style={{ marginRight: '1rem', color: 'white' }}>
                  Conectado
                </Col>
                <Col>
                  <FontAwesomeIcon icon={faCircle} className="faCircle" />
                </Col>
                <Col style={{ marginLeft: '3rem', color: 'white' }}>Login:</Col>
                <Col style={{ marginLeft: '1rem', color: 'white' }}>
                  {username}
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row>
              <Col span="20">
                <div>
                  <Modal
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    className="wifi-card"
                    footer={null}
                  >
                    <h5 className="card_h5">Rede WiFi</h5>

                    <Form>
                      <Form.Item
                        label="Nome da rede (SSID)"
                        className="input-data-class"
                      >
                        <Input
                          placeholder="Informe aqui o nome da rede"
                          value={ssidValue}
                          onChange={event => handleInputChange('ssid', event)}
                          defaultValue="admin"
                          className="data-input"
                          onPressEnter={e =>
                            handleEnterPress(e, passwordInputRef)
                          }
                        />
                      </Form.Item>

                      <Form.Item label="Senha" className="input-data-class">
                        <Password
                          placeholder="Informe aqui a senha da rede"
                          value={passwordSsidValue}
                          onChange={event =>
                            handleInputChange('passwordSsid', event)
                          }
                          defaultValue="admin"
                          className="data-input"
                          ref={passwordInputRef}
                          onPressEnter={async e => {
                            await ConnectHotspot(ssidValue, passwordSsidValue);
                          }}
                        />
                      </Form.Item>

                      {isLoading ? (
                        <Spin />
                      ) : (
                        <Button
                          key="connection-button"
                          type="primary"
                          onClick={() =>
                            ConnectHotspot(ssidValue, passwordSsidValue)
                          }
                          className="connection-button"
                        >
                          Conectar
                        </Button>
                      )}
                    </Form>
                  </Modal>
                  <Modal
                    visible={isModalVisibleNtrip}
                    onCancel={handleCancel}
                    className="wifi-card"
                    footer={null}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h5 className="card_h5">Conta NTRIP</h5>
                      <Button
                        key="connection-button"
                        type="primary"
                        onClick={handleLoadDefaultValues}
                        className="connection-button"
                        // disabled={fixBaseHotspot === 0 || connectionStatus === 0}
                      >
                        Carregar valores padrão
                      </Button>
                    </div>

                    <Form>
                      <Form.Item
                        label="Endereço IP da rede"
                        className="input-data-class"
                      >
                        <Input
                          className="data-input"
                          value={ipValue}
                          onChange={event => handleInputChange('ip', event)}
                          defaultValue="170.84.40.52"
                          onPressEnter={e => handleEnterPress(e, portInputRef)}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Porta da rede"
                        className="input-data-class"
                      >
                        <Input
                          className="data-input"
                          value={portValue}
                          onChange={event => handleInputChange('port', event)}
                          defaultValue="2101"
                          ref={portInputRef}
                          onPressEnter={e => handleEnterPress(e, userInputRef)}
                        />
                      </Form.Item>
                      <Row>
                        <Col xs={7} sm={7} md={7} className="loadBases">
                          {!isLoading ? (
                            <Button
                              onClick={makeRequestBases}
                              className="loadBasesButton"
                            >
                              Obter Bases
                            </Button>
                          ) : (
                            <Spin className="spin" />
                          )}
                        </Col>
                        <Col xs={15} sm={15} md={15}>
                          <Select
                            className="selectdata"
                            value={selectedBase}
                            onChange={value => handleInputChange('base', value)}
                            defaultValue="EESC0"
                          >
                            {options.map(option => (
                              <Option key={option} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                      <Form.Item>
                        <Checkbox
                          style={{ marginTop: '1rem', color: '#fff' }}
                          defaultChecked
                          onChange={e =>
                            e.target.checked ? setAutoConf(1) : setAutoConf(0)
                          }
                        >
                          Deseja configurar a base automaticamente quando
                          fixado?
                        </Checkbox>
                      </Form.Item>
                      <Form.Item label="Usuário" className="input-data-class">
                        <Input
                          className="data-input"
                          value={username}
                          onChange={event =>
                            handleInputChange('username', event)
                          }
                          defaultValue="xrtk"
                          ref={userInputRef}
                          onPressEnter={e =>
                            handleEnterPress(e, ssidPasswordInputRef)
                          }
                        />
                      </Form.Item>
                      <Form.Item label="Senha" className="input-data-class">
                        <Password
                          className="data-input"
                          defaultValue="$x7XRTK$"
                          value={password}
                          onChange={event =>
                            handleInputChange('password', event)
                          }
                          ref={ssidPasswordInputRef}
                          onPressEnter={async e => {
                            await ConnectNtrip(
                              ipValue,
                              portValue,
                              selectedBase,
                              username,
                              password
                            );
                          }}
                        />
                      </Form.Item>
                      <div className="btnContainer">
                        {isLoadingNtrip ? (
                          <Spin />
                        ) : (
                          <Button
                            key="connection-button"
                            type="primary"
                            onClick={() =>
                              ConnectNtrip(
                                ipValue,
                                portValue,
                                selectedBase,
                                username,
                                password
                              )
                            }
                            className="connection-button"
                            // disabled={fixBaseHotspot === 0 || connectionStatus === 0}
                          >
                            Confirmar dados
                          </Button>
                        )}
                      </div>
                    </Form>
                  </Modal>
                </div>
              </Col>
            </Row>
            <Col span="20">
              <div className="data-table">
                <Table
                  columns={
                    status === 'Internet' ? [columns[4], columns[5]] : columns
                  }
                  dataSource={savedData.slice().reverse()}
                  pagination={false}
                  rowSelection={{
                    type: 'radio',
                    selectedRowKeys,
                    onChange: (selectedRowKeys, selectedRows) => {
                      handleRowSelect(selectedRows[0]);
                    }
                  }}
                  onRow={record => ({
                    onClick: () => {
                      handleRowSelect(record);
                    }
                  })}
                  rowKey="key"
                  scroll={{ x: 100 }}
                  style={status === 'Internet' ? { width: '35%' } : {}}
                />
              </div>
            </Col>
          </div>
        </PageHeader>
      )}
      {tutorialWifi && (
        <div className="wifiForm">
          <Form className="input-data-class-wifi">
            <Form.Item label="Nome da rede (SSID)" className="input-data-class">
              <Input
                placeholder="Informe aqui o nome da rede"
                value={ssidValue}
                onChange={event => handleInputChange('ssid', event)}
                defaultValue="admin"
                className="data-input"
                onPressEnter={e => handleEnterPress(e, passwordInputRef)}
              />
            </Form.Item>

            <Form.Item label="Senha" className="input-data-class">
              <Password
                placeholder="Informe aqui a senha da rede"
                value={passwordSsidValue}
                onChange={event => handleInputChange('passwordSsid', event)}
                defaultValue="admin"
                className="data-input"
                ref={passwordInputRef}
                onPressEnter={async e => {
                  await ConnectHotspot(ssidValue, passwordSsidValue);
                }}
              />
            </Form.Item>

            {isLoading ? (
              <Spin />
            ) : (
              <div className="tutorial-buttons">
                <Button
                  className="back-button"
                  type="primary"
                  onClick={() => onPositionSucess(false)}
                >
                  Voltar
                </Button>
                <Button
                  key="connection-button"
                  type="primary"
                  onClick={() => ConnectHotspot(ssidValue, passwordSsidValue)}
                  className="next-button"
                >
                  Configurar
                </Button>
              </div>
            )}
          </Form>
        </div>
      )}
      {tutorialNTRIP && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              key="connection-button"
              type="primary"
              onClick={handleLoadDefaultValues}
              className="loadBasesButton"
              // disabled={fixBaseHotspot === 0 || connectionStatus === 0}
            >
              Carregar valores padrão
            </Button>
          </div>
          <Form>
            <Row gutter={20}>
              <Col xs={12} sm={12} md={12}>
                <Form.Item
                  label="Endereço IP da rede"
                  className="input-data-class"
                >
                  <Input
                    className="data-input"
                    value={ipValue}
                    onChange={event => handleInputChange('ip', event)}
                    defaultValue="170.84.40.52"
                    onPressEnter={e => handleEnterPress(e, portInputRef)}
                  />
                </Form.Item>
                <Form.Item label="Porta da rede" className="input-data-class">
                  <Input
                    className="data-input"
                    value={portValue}
                    onChange={event => handleInputChange('port', event)}
                    defaultValue="2101"
                    ref={portInputRef}
                    onPressEnter={e => handleEnterPress(e, userInputRef)}
                  />
                </Form.Item>
                <Row
                  gutter={20}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Col xs={7} sm={7} md={7} className="loadBases">
                    {!isLoading ? (
                      <Button
                        onClick={makeRequestBases}
                        className="loadBasesButton"
                      >
                        Obter Bases
                      </Button>
                    ) : (
                      <Spin className="spin" />
                    )}
                  </Col>
                  <Col xs={15} sm={15} md={15}>
                    <Select
                      className="selectdata"
                      value={selectedBase}
                      onChange={value => handleInputChange('base', value)}
                      defaultValue="EESC0"
                    >
                      {options.map(option => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Form.Item>
                  <Checkbox
                    style={{ marginTop: '1rem', color: '#fff' }}
                    defaultChecked
                    onChange={e =>
                      e.target.checked ? setAutoConf(1) : setAutoConf(0)
                    }
                  >
                    Deseja configurar a base automaticamente quando fixado?
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={10} sm={10} md={10}>
                <Form.Item label="Usuário" className="input-data-class">
                  <Input
                    className="data-input"
                    value={username}
                    onChange={event => handleInputChange('username', event)}
                    defaultValue="xrtk"
                    ref={userInputRef}
                    onPressEnter={e =>
                      handleEnterPress(e, ssidPasswordInputRef)
                    }
                  />
                </Form.Item>
                <Form.Item label="Senha" className="input-data-class">
                  <Password
                    className="data-input"
                    defaultValue="$x7XRTK$"
                    value={password}
                    onChange={event => handleInputChange('password', event)}
                    ref={ssidPasswordInputRef}
                    onPressEnter={async e => {
                      await ConnectNtrip(
                        ipValue,
                        portValue,
                        selectedBase,
                        username,
                        password
                      );
                    }}
                  />
                </Form.Item>
                <div className="btnContainer">
                  {isLoadingNtrip ? (
                    <Spin />
                  ) : (
                    <div className="tutorial-buttons-ntrip">
                      <Button
                        className="back-button"
                        type="primary"
                        onClick={() => onPositionSucess(false)}
                      >
                        Voltar
                      </Button>
                      <Button
                        key="connection-button"
                        type="primary"
                        onClick={() =>
                          ConnectNtrip(
                            ipValue,
                            portValue,
                            selectedBase,
                            username,
                            password
                          )
                        }
                        className="next-button"
                        // disabled={fixBaseHotspot === 0 || connectionStatus === 0}
                      >
                        Confirmar e Finalizar
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ websocket, gps }: ApplicationState) => ({
  fixBaseHotspot: websocket.fixBaseHotspot,
  fixBaseNtrip: websocket.fixBaseNtrip,
  connectionStatus: websocket.connectionStatus,
  latitude: Number(gps.position.latitude),
  longitude: Number(gps.position.longitude)
});

export default connect(mapStateToProps)(NtripControl);
