import React, { useState, useEffect } from 'react';
import { PageHeader, Table, Layout, Button, ConfigProvider, Empty, Popconfirm, Affix } from 'antd';

import type { ColumnsType } from 'antd/es/table';

import { DeleteOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import './style.css';

const { Content } = Layout;

interface DataType {
  key: string;
  lat: string;
  long:string;
  alt:string;
  sol: string;
  date:string;
}

const CoordinateTableControl = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalElements, setTotalElements] = useState(11); 
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  
  const [tableData, setTableData] = useState<DataType[]>([]);

  const changeTimeZone = (date: string) => {
    const [year, month, day, hour, minute, second] = date.split('_').map(Number);
    const utcOffset = -3 * 60;
  
    const utcTime = new Date(year, month - 1, day, hour, minute, second).getTime();

    return new Date(utcTime + (utcOffset * 60000)).toLocaleString("pt-BR");
  };

  const fetchData = async () => {
    setIsLoading(true);

    const requestOptions = {
      method: 'GET',
    };

    fetch(`http://localhost:8080/list_coordinates?page=${currentPage}`, requestOptions)
    .then(response => response.text())
      .then(data => {
        const formattedData: DataType[] = [];
        const filesArray = data.split(';');
      
        const elements = parseInt(filesArray[0], 10);
        filesArray.shift();
        
        const len = filesArray.length;
        const elementsArray = filesArray.slice(0, len  - (len/8));

        for (let i = 0, j = 0; i < elementsArray.length; i += 8, j+=1) {
          const chunk = filesArray.slice(i, i + 8);
          let date = 'N/A';
          let sol = '';
          if (chunk[7] && !chunk[7].startsWith('0000')) 
            date = changeTimeZone(chunk[7]);

            if(chunk[6] === 'ADV'){
              sol = 'AVANÇO'
            }
            else sol = `${chunk[6]}`;
                  
          formattedData.push({
            key: String(i/7),
            lat: `${chunk[0]}+-${chunk[1]}`,
            long: `${chunk[2]}+-${chunk[3]}`,
            alt: `${chunk[4]}+-${chunk[5]}`,
            sol,
            date,
          });
        }
        
        setTableData(formattedData);
        setTotalElements(elements);
      })
      .catch(error => {
        console.log(` Erro: ${error.message}`);
        // para teste enquanto ainda não tem endpoint:
    
        // const data = "7;-22.05295620;6.900;-47.87552320;0.00;835.110;0.00;ADV;0_0_0_0_0_0;-24.05192160;0.00;-47.05612160;0.00;835.110;0.00;ADV;0_0_0_0_0_0;0.00000000;0.00;0.00000000;0.00;0.000;0.00;AUTO;20178_55_55_55_67_247;0.00000000;0.00;0.00000000;0.00;1835.110;0.00;AUTO;20178_55_55_55_67_247;-22.00000000;0.00;-47.00000000;0.00;502.080;0.00;AUTO;20178_55_55_55_67_247;-24.05192160;0.00;-47.16545600;0.00;835.110;0.00;ADV;2024_3_21_11_48_52;10.00000000;0.00;12.00000000;0.00;1835.120;0.00;MANUAL;2024_3_21_11_54_48"
        // const formattedData: DataType[] = [];
        // const filesArray = data.split(';');
      
        // const elements = parseInt(filesArray[0], 10);
        // filesArray.shift();
        
        // const len = filesArray.length;
       

        // const elementsArray = filesArray.slice(0, len  - (len/8));
        // console.log(elementsArray);
        

        // for (let i = 0, j = 0; i < elementsArray.length; i += 8, j+=1) {
        //   const chunk = filesArray.slice(i, i + 8);
        //   let date = 'N/A';
        //   let sol = '';
        //   if (chunk[7] && !chunk[7].startsWith('0000')) 
        //     date = changeTimeZone(chunk[7]);

        //     if(chunk[6] === 'ADV'){
        //       sol = 'AVANÇO'
        //     }
        //     else sol = `${chunk[6]}`;
                  
        //   formattedData.push({
        //     key: String(i/7),
        //     lat: `${chunk[0]}+-${chunk[1]}`,
        //     long: `${chunk[2]}+-${chunk[3]}`,
        //     alt: `${chunk[4]}+-${chunk[5]}`,
        //     sol,
        //     date,
        //   });
        // }
        // console.log(formattedData);
        // setTableData(formattedData);
        // setTotalElements(elements);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  const handleRemove = (latitude: string, longitude: string) => {
    setIsDeleting(true);
    
  

    const requestOptions = {
      method: 'POST',

    };

    fetch(`http://localhost:8080/delete_coordinate?latitude=${latitude.split('+-')[0]}&longitude=${longitude.split('+-')[0]}`, requestOptions)

      .then(response => response.text())
      .then(data => {
        console.log(` Exclusão realizada com sucesso `);
        
        fetchData();
      })
      .catch(error => {
        console.log(` Erro: ${error.message}`);
      })
      .finally(() => setIsDeleting(false));
  };

  

  useEffect(() => {
    fetchData();
  }, [currentPage]); 


  const columns: ColumnsType<DataType> = [
    {
      title: 'Latitude',
      dataIndex: 'lat',
      key: 'lat',
      width: '16%',
      render: (text: string) => (
        <div className="latitude-cell">
          <span>{text.split('+')[0]}</span>
          <div className='error-box'>
            <div className='signals-box'>
              <span className='plus-signal'>
                +
              </span>
              <span className='min-signal'>-</span>
            </div>    
            <div className='error'>
              <span>
          
                {text.split('+-')[1]}
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Longitude',
      dataIndex: 'long',
      key: 'long',
      width: '20%',
      render: (text: string) => (
        <div className="latitude-cell">
          <span>{text.split('+')[0]}</span>
          <div className='error-box'>
            <div className='signals-box'>
              <span className='plus-signal'>
                +
              </span>
              <span className='min-signal'>-</span>
            </div>    
            <div className='error'>
              <span>
          
                {text.split('+-')[1]}
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Altitude',
      dataIndex: 'alt',
      key: 'alt',
      width: '16%',
      render: (text: string) => (
        <div className="latitude-cell">
          <span>{text.split('+')[0]}</span>
          <div className='error-box'>
            <div className='signals-box'>
              <span className='plus-signal'>
                +
              </span>
              <span className='min-signal'>-</span>
            </div>    
            <div className='error'>
              <span>
          
                {text.split('+-')[1]}
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Solução',
      dataIndex: 'sol',
      key: 'sol',
      width: '16%'
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      width: '20%'
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: '20%',
      render: (_, record) => (
        <div className="table-action-buttons">
          <Popconfirm
            title="Tem certeza de que deseja excluir este voo?"
            onConfirm={() => handleRemove(String(record.lat), String(record.long))}
            okText="Sim"
            cancelText="Não"
            placement="bottomRight"
          >
            <Button
              title="Excluir voo"
              className="trash-button"
            > 
              <DeleteOutlined />
              Excluir
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ];

  
  return (
    <PageHeader title="Locais">
      <p className="header-subtitle">
        Visualize aqui as coordenadas registradas pela sua base
      </p>
      <Layout className="table-page">
        <Content className="table-page-content">
          <ConfigProvider
            renderEmpty={() => (
              <Empty description="Você não possui coordenadas capturadas, tente acessar o menu operação para realizar uma captura " />
            )}
          >
            <div className='table-container'>
              <Affix offsetTop={0}>
                <Table
                  className="table"
                  loading={isLoading || isDeleting} 
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ y: 100 }}
                  pagination={{
                    defaultCurrent: currentPage + 1,
                    pageSize: 10,
                    total: totalElements,
                    showSizeChanger: false,
                    onChange: (page) => setCurrentPage(page - 1),
                    itemRender: (current, type, originalElement) => {
                      if (type === 'prev') {
                        return (<Button className="pagination-item"><ArrowLeftOutlined /></Button>);
                      }
                      if (type === 'next') {
                        return (<Button className="pagination-item"><ArrowRightOutlined /></Button>);
                      }
                      return originalElement;
                    },
                  }}
                
                />     
              </Affix>
            </div>
          </ConfigProvider>
        </Content>
      </Layout>
    </PageHeader>
  );
};

export default (CoordinateTableControl);
