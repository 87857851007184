import React from 'react';
import { connect } from 'react-redux';

import { Card } from 'antd';
import { ApplicationState } from '../../store/rootReducer';

type Props = {
  solution: string;
  isRtcmMessage: boolean;
  greyOperation: string;
};
const Solucao = ({ solution, isRtcmMessage, greyOperation }: Props) => {
  return Number(greyOperation) ? (
    <span>Base enviando correção para o drone</span>
  ) : (
    <span>{solution}</span>
  );
};

const mapStateToProps = ({ gps, websocket }: ApplicationState) => ({
  solution: gps.position.solution,
  isRtcmMessage: gps.isRtcmMessage,
  greyOperation: websocket.greyOperation
});

export default connect(mapStateToProps)(Solucao);
