/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  PageHeader,
  Row,
  Col,
  Collapse,
  Card,
  Tooltip,
  Button,
  Spin,
  Modal
} from 'antd';
import './style.css';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ApplicationState } from '../../store/rootReducer';
import * as WebsocketActions from '../../store/websocket/actions';
import LineGraph from '../../components/graphics/line-graph/LineGraph';
import SatellitesConstellation from '../../components/graphics/satellites/SatellitesConstellation';

import PositionPanel from '../../components/base/panels/position/PositionPanel';
import DinamicPositionPanel from '../../components/base/panels/position/DinamicPositionPanel';

import * as GpsAction from '../../store/gps/actions';
import CloseSpotsPanel from '../../components/base/panels/position/CloseSpotsPanel';
import DronePositionPanel from '../../components/base/panels/position/DronePositionPanel';

const { Panel } = Collapse;

interface DispatchProps {
  connectToWebsocket(): void;

  selectGpsModel(model: string): void;
}

interface StateProps {
  connectionStatus: number;
  isSendingCorrections: boolean;
  isValidPositions: boolean;
  modeType: string;
  greyOperation: string;
}

type Props = StateProps & DispatchProps;

const BaseControl = ({
  connectionStatus,
  isValidPositions,
  connectToWebsocket,
  selectGpsModel,
  modeType,
  greyOperation
}: Props) => {
  const [render] = useState(true);

  const [noUpdate] = useState(false);
  const [panelControl, setPanelControl] = useState({
    informationXrtk: false,
    informationAccuracy: false,
    dinamicPosition: true,
    referencePosition: true,
    satellitesConstellation: false
  });
  const [color, setColor] = useState('#00c880');

  useEffect(() => {
    const timer = setInterval(() => {
      setColor(prevColor => (prevColor === '#00c880' ? '#00e670' : '#00c880'));
    }, 500);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (connectionStatus === 2 || connectionStatus === 3) {
      setPanelControl({
        informationXrtk: true,
        informationAccuracy: true,
        referencePosition: true,
        dinamicPosition: true,
        satellitesConstellation: true
      });
    }
    if (connectionStatus === 0) {
      setPanelControl({
        informationXrtk: false,
        informationAccuracy: false,
        referencePosition: true,
        dinamicPosition: true,
        satellitesConstellation: false
      });
    }
  }, [connectionStatus]);

  const handlePanelClick = (panel: string) => {
    switch (panel) {
      case 'information-xrtk':
        setPanelControl({
          ...panelControl,
          informationXrtk: !panelControl.informationXrtk
        });
        break;
      case 'information-accuracy':
        setPanelControl({
          ...panelControl,
          informationAccuracy: !panelControl.informationAccuracy
        });
        break;
      case 'satellites-constellation':
        setPanelControl({
          ...panelControl,
          satellitesConstellation: !panelControl.satellitesConstellation
        });
        break;
      case 'dinamic-position':
        setPanelControl({
          ...panelControl,
          dinamicPosition: !panelControl.dinamicPosition
        });
        break;
      default:
        setPanelControl({
          ...panelControl,
          referencePosition: !panelControl.referencePosition
        });
        break;
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isModalDroneVisible, setIsModalDroneVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setIsModalDroneVisible(false);
  };

  return (
    <PageHeader title="Operação da base">
      <p className="header-subtitle">
        Veja aqui os dados sobre sua base em operação no momento
      </p>

      <Modal title={null} visible={isModalVisible} footer={null}>
        <PositionPanel
          closeModal={closeModal}
          tutorial={false}
          mode={1}
          onPositionSucess={closeModal}
        />
      </Modal>
      <Modal
        title={null}
        visible={isModalDroneVisible}
        footer={null}
        closeIcon={<span className="close-icon">X</span>}
        onCancel={closeModal}
      >
        <DronePositionPanel
          closeModal={closeModal}
          tutorial={false}
          onPositionSucess={closeModal}
        />
      </Modal>
      <Card className="base-card">
        <div className="base-card-header-constelation">
          <div className="base-card-tooltip">
            <h5 className="base-card-title">Constelação de satélites</h5>
            <Tooltip
              title="Estes são um grupo de satélites que orbitam a Terra,
saber sua posição é importante para se comunicar 
com eles ou usar seus dados."
            >
              <QuestionCircleOutlined
                style={{
                  fontSize: '16px',
                  marginLeft: '0.3rem',
                  color: '#FFFFFFF2'
                }}
              />
            </Tooltip>
          </div>
          <span className="constelation">
            <SatellitesConstellation />
          </span>
        </div>
      </Card>

      <Card className="base-card">
        <div className="base-card-header-constelation">
          <div className="base-card-tooltip">
            <h5 className="base-card-title">Dissolução da precisão</h5>
            <Tooltip
              title="Aqui são indicadas informações sobre a precisão
dos dados coletados."
            >
              <QuestionCircleOutlined
                style={{
                  fontSize: '16px',
                  marginLeft: '0.3rem',
                  color: '#FFFFFFF2'
                }}
              />
            </Tooltip>
          </div>
          <span className="graph">
            <LineGraph />
          </span>
        </div>
      </Card>
      <Card className="base-card">
        <div className="base-card-header">
          <div className="base-card-tooltip">
            <h5 className="base-card-title">Posição atual</h5>

            <Tooltip title="Esta é a sua posição atual capturada recentemente.">
              <QuestionCircleOutlined
                style={{
                  fontSize: '16px',
                  marginLeft: '0.3rem',
                  color: '#FFFFFFF2'
                }}
              />
            </Tooltip>
          </div>
          <div>
            {modeType !== 'Relativo novo' && modeType !== 'Relativo marcado' && (
              <Button className="base-card-btn" onClick={showModal}>
                {modeType === 'Absoluto com internet'
                  ? 'Definir posição e configurar como base'
                  : 'Definir posição da base'}
              </Button>
            )}

            {(modeType === 'Transporte de base' || modeType === 'Avançado') && (
              <Button
                className="base-card-btn"
                onClick={() => {
                  setIsModalDroneVisible(true);
                }}
                style={{ marginTop: '0.5rem' }}
              >
                Definir posição do drone
              </Button>
            )}
          </div>
        </div>

        <DinamicPositionPanel />
      </Card>

      <Card className="base-card">
        <div className="base-card-tooltip">
          <h5 className="base-card-title">Pontos mais próximos</h5>
          <Tooltip title="Estes são os 3 pontos registrados mais próximos a sua base.">
            <QuestionCircleOutlined
              style={{
                fontSize: '16px',
                marginLeft: '0.3rem',
                color: '#FFFFFFF2'
              }}
            />
          </Tooltip>
        </div>
        <span className="constelation">
          <CloseSpotsPanel />
        </span>
      </Card>
      <Row />
    </PageHeader>
  );
};

const mapStateToProps = ({ websocket, layout }: ApplicationState) => ({
  connectionStatus: websocket.connectionStatus,
  isSendingCorrections: websocket.isSendingorrections,
  isValidPositions: websocket.validPositions,
  modeType: layout.modeType,
  greyOperation: websocket.greyOperation
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions, ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(BaseControl);
