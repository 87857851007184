import React, { FunctionComponent, ReactNode } from 'react';

import { Layout } from 'antd';

const { Content: AntdContent } = Layout;

type Props = {
  children?: ReactNode;
};

const Content: FunctionComponent<{}> = ({ children }: Props) => (
  <AntdContent>{children}</AntdContent>
);

export default Content;
