/* eslint-disable no-bitwise */
import React, { useState, useEffect, useRef } from 'react';

import { Dispatch, MiddlewareAPI, bindActionCreators } from 'redux';
import { connect, useStore } from 'react-redux';
import {
  Button,
  Form,
  InputNumber,
  Col,
  notification,
  Spin,
  Row,
  Tooltip
} from 'antd';
import './style.css';

import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import * as GpsAction from '../../../../store/gps/actions';
import { ApplicationState } from '../../../../store/rootReducer';
import {
  ConfigurationTypes,
  ConfigurationLocationTypes
} from '../../../../types/Gps';
import { convertGgaToDecodedPositionMessage } from '../../../../utils/ProcessMessage';

interface DispatchProps {
  startLora(): void;
  stopLora(): void;
  configGps(
    location: ConfigurationLocationTypes,
    type: ConfigurationTypes,
    commands?: DataView
  ): void;
  closeModal(): void;
  tutorial: boolean;
  onPositionSucess(data: any): void;
}
interface StateProps {
  latitude: number;
  longitude: number;
  altitude: number;
  positionUuid: string;
  enableSendCorrection: boolean;
  modeType: string;
}

type Props = StateProps & DispatchProps;

const DronePositionPanel = ({
  latitude,
  longitude,
  altitude,
  positionUuid,
  enableSendCorrection,
  closeModal,
  tutorial,
  modeType,
  onPositionSucess
}: Props) => {
  let inputNumberRef: { focus: () => void } | null = null;
  let inputAltitudeNumberRef: { focus: () => void } | null = null;
  let inputAlturaNumberRef: { focus: () => void } | null = null;

  const saveInputNumberRef = (input: any) => {
    if (input) {
      inputNumberRef = input;
    }
  };

  const saveInputAltitudeNumberRef = (input: any) => {
    if (input) {
      inputAltitudeNumberRef = input;
    }
  };

  const saveInputAlturaNumberRef = (input: any) => {
    if (input) {
      inputAlturaNumberRef = input;
    }
  };

  const handleEnterPress = (e: { key: string }) => {
    if (e.key === 'Enter' && inputNumberRef) {
      inputNumberRef.focus();
    }
  };

  const handleSecondEnterPress = (e: { key: string }) => {
    if (e.key === 'Enter' && inputAlturaNumberRef) {
      inputAlturaNumberRef.focus();
    }
  };

  const handleThirdEnterPress = (e: { key: string }) => {
    if (e.key === 'Enter' && inputAltitudeNumberRef) {
      inputAltitudeNumberRef.focus();
    }
  };

  const [form] = Form.useForm();
  const [enableConfig, setEnableConfig] = useState(false);
  const [fileName, setFileName] = useState('');
  const [altitudeXRTK, setAltitudeXRTK] = useState(Number(altitude.toFixed(3)));
  const [alturaAntena, setAlturaAntena] = useState(2.092);

  const [isLoading, setIsLoading] = useState(false);

  const store = useStore();

  const sendBaseConfig = async () => {
    setIsLoading(true);
    const baseUrl = 'http://localhost:8080/add_adv_pos';
    const fields = form.getFieldsValue();
    let urlWithParams = '';
    if (
      modeType === 'Ponto pós-processado' ||
      modeType === 'Voo pós processado'
    ) {
      urlWithParams = `${baseUrl}?lat=${fields['latitude-insert']}&lon=${
        fields['longitude-insert']
      }&alt=${altitudeXRTK.toString()}&mode=PPP`;
    } else {
      urlWithParams = `${baseUrl}?lat=${fields['latitude-insert']}&lon=${
        fields['longitude-insert']
      }&alt=${altitudeXRTK.toString()}`;
    }

    const requestOptions = {
      method: 'POST'
    };
    fetch(urlWithParams, requestOptions)
      .then(response => response.text())
      .then(data => {
        console.log(data);
        setIsLoading(false);
        closeModal();
        onPositionSucess(true);
      })
      .catch(error => {
        notification.error({
          message: 'Erro',
          description: 'Não foi possível realizar a captura, tente novamente!',
          className: 'error'
        });
        console.error('Error:', error);
        setIsLoading(false);
        closeModal();
        onPositionSucess(true);
      });
  };

  const positions = useRef({
    latitude: 0,
    longitude: 0,
    altitude: 0,
    total: 0,
    uuid: ''
  });

  const validateInputs = () => {
    const fields = form.getFieldsValue();
    setEnableConfig(false);

    if (
      typeof fields['latitude-insert'] !== 'undefined' &&
      fields['latitude-insert'] !== null &&
      fields['latitude-insert'] !== '' &&
      typeof fields['longitude-insert'] !== 'undefined' &&
      fields['longitude-insert'] !== null &&
      fields['longitude-insert'] !== '' &&
      altitudeXRTK !== null
    )
      setEnableConfig(true);
  };

  useEffect(() => {
    validateInputs();
  }, [fileName]);

  useEffect(() => {
    sendreq(store);
  }, []);

  const sendreq = (stored: MiddlewareAPI) => {
    setIsLoading(true);
    fetch('http://localhost:8080/drone_pos')
      //fetch('http://localhost:5000') // para testes home office
      .then(response => {
        return response.text();
      })
      .then(res => {
        const decodedPosition = convertGgaToDecodedPositionMessage(
          res.substring(0, res.indexOf('*')).split(',')
        );
        form.setFieldsValue({
          'latitude-insert': decodedPosition.latitude,
          'longitude-insert': decodedPosition.longitude,
          'altura-insert': decodedPosition.altitude,
          'altitude-marcador-insert': decodedPosition.altitude - 2.092
        });

        setAltitudeXRTK(decodedPosition.altitude);
        setAlturaAntena(2.092);

        validateInputs();
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Ocorreu um erro:', error);

        setIsLoading(false);
      });
    return true;
  };

  return (
    <>
      <Col
        xs={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
        xl={{ span: 24 }}
      >
        <Form
          form={form}
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          name="position-insert"
          scrollToFirstError
        >
          <Row gutter={[16, 0]}>
            <Col span={8}>
              <Form.Item
                name="latitude-insert"
                label="Latitude (DD)"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  type="number"
                  className="pos_input"
                  min={-90}
                  max={90}
                  step={0.000001}
                  precision={8}
                  onChange={() => validateInputs()}
                  decimalSeparator="."
                  onPressEnter={e => handleEnterPress(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="longitude-insert"
                label="Longitude (DD)"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  type="number"
                  className="pos_input"
                  min={-180}
                  max={180}
                  step={0.000001}
                  precision={8}
                  onChange={() => validateInputs()}
                  decimalSeparator="."
                  ref={saveInputNumberRef}
                  onPressEnter={e => handleSecondEnterPress(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Altura antena (m):"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  className="pos_input"
                  min={0}
                  max={10000000000}
                  precision={3}
                  defaultValue={2.092}
                  onChange={e => {
                    setAltitudeXRTK(
                      Number(e) +
                        (Number(
                          form.getFieldValue('altitude-marcador-insert')
                        ) || 0)
                    );
                    setAlturaAntena(Number(e));
                    validateInputs();
                  }}
                  ref={saveInputAlturaNumberRef}
                  onPressEnter={e => handleThirdEnterPress(e)}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="altitude-marcador-insert"
                label="Altitude marcador (m)"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  type="number"
                  className="pos_input"
                  step={1}
                  precision={3}
                  onChange={e => {
                    setAltitudeXRTK(alturaAntena + Number(e));
                    validateInputs();
                  }}
                  ref={saveInputAltitudeNumberRef}
                  onPressEnter={() => {
                    if (enableConfig) {
                      sendBaseConfig();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Altitude XRTK (m):"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  className="pos_input"
                  min={0}
                  max={10000000000}
                  precision={3}
                  value={altitudeXRTK}
                  defaultValue={altitudeXRTK}
                  disabled
                  style={{ color: 'darkgrey' }}
                />
                <Tooltip title="Altitude marcador + Altura antena">
                  <QuestionCircleOutlined
                    style={{
                      fontSize: '16px',
                      marginLeft: '0.3rem',
                      color: '#FFFFFFF2'
                    }}
                  />
                </Tooltip>
              </Form.Item>
            </Col>

            {!altitudeXRTK && (
              <div style={{ color: '#e6e6e6' }}>
                <WarningOutlined className="altWarning" />
                Não altere o campo "Altitude (m)" para que a própria altura do
                XRTK seja utilizada após deslocamento
              </div>
            )}
          </Row>
        </Form>
      </Col>

      <div
        style={{ display: 'flex' }}
        className={tutorial ? 'tutorial-drone-button' : ''}
      >
        {!isLoading ? (
          <Button
            className="base-card-btn"
            disabled={false}
            key="send-corrections-button"
            type="primary"
            onClick={() => {
              sendreq(store);
            }}
          >
            Recalcular ponto de avanço
          </Button>
        ) : (
          <Spin className="spin" />
        )}
        {/* ternario */}

        {tutorial && (
          <Button
            className="back-button"
            type="primary"
            onClick={() => onPositionSucess(false)}
          >
            Voltar
          </Button>
        )}

        <Button
          className="base-card-btn-config"
          disabled={!enableConfig}
          key="send-configuration-button"
          type="primary"
          onClick={() => sendBaseConfig()}
        >
          Configurar
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = ({ gps, websocket, layout }: ApplicationState) => ({
  latitude: Number(gps.position.latitude),
  longitude: Number(gps.position.longitude),
  altitude: gps.position.altitude,
  positionUuid: gps.position.uuid,
  modeType: layout.modeType,
  enableSendCorrection: websocket.enableCorrection
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(DronePositionPanel);
