import React, { useEffect, useState } from 'react';
import { Typography, Menu, Button, Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import { Dispatch, bindActionCreators } from 'redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { LayoutTypes } from '../../types/Layout';
import * as WebsocketActions from '../../store/websocket/actions';
import * as GpsAction from '../../store/gps/actions';

import './style.css';
import { ApplicationState } from '../../store/rootReducer';
import relativoNovo from './images/relativoNovo.png';
import absolutoComInternet from './images/absolutoComInternet.png';
import basePosProcessado from './images/basePosProcessado.png';
import transporteDaBase from './images/transporteDeBase.png';
import vooPosProcessado from './images/vooPosProcessado.png';
import coordenadaManual from './images/coordenadaManual.png';
import relativoMarcado from './images/relativoMarcado.png';

const { Paragraph } = Typography;

interface DispatchProps {
  connectToWebsocket(): void;

  selectGpsModel(model: string): void;
}
interface StateProps {
  connectionStatus: number;
}

type Props = StateProps & DispatchProps;

const Main = ({
  connectionStatus,
  connectToWebsocket,
  selectGpsModel
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [current, setCurrent] = useState('relativoNovo');
  const [currentSubMenu, setCurrentSubMenu] = useState(0);

  const menuItems = [
    { key: 'relativoNovo', title: 'Relativo novo' },
    { key: 'relativoMarcado', title: 'Relativo marcado' },
    { key: 'absolutoComInternet', title: 'Absoluto com internet' },
    { key: 'basePosProcessado', title: 'Ponto pós-processado' },
    { key: 'vooPosProcessado', title: 'Voo pós-processado' },
    { key: 'transporteDeBase', title: 'Transporte de base' },
    { key: 'coordenadaManual', title: 'Coordenada manual' }
  ];

  // useEffect(() => {
  //   if (connectionStatus === 0) {
  //     selectGpsModel('184b');
  //     connectToWebsocket();
  //   }
  // }, [connectionStatus]);

  const handleClick = (e: any) => {
    setCurrent(e.key);

    const chave = e.key;
    const index = menuItems.findIndex(item => item.key === chave);

    setCurrentSubMenu(index);
  };

  const [modalVisible, setModalVisible] = useState(false);

  const handleLinkClick = (e: { preventDefault: () => void }) => {
    if (!modalVisible) {
      e.preventDefault();
      setModalVisible(true);
    }
  };

  const handleModalConfirm = () => {
    setModalVisible(false);
    dispatch({
      type: LayoutTypes.SET_MODE_TYPE,
      payload: 'Avançado'
    });

    history.push('/extra');
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <div className="container">
      <div className="header">
        <Paragraph className="instruction">
          Selecione um modo de operação
          <Link to="#" onClick={handleLinkClick} style={{ color: '#6aa944' }}>
            Pular tutorial
          </Link>
          <Modal
            visible={modalVisible}
            onOk={handleModalConfirm}
            onCancel={handleModalCancel}
            okText="Sim, avançar"
            cancelText="Não, cancelar"
            okButtonProps={{ className: 'next-button' }}
            cancelButtonProps={{ className: 'back-button' }}
          >
            <h5 className="card_h5">Pular tutorial</h5>
            <span style={{ color: '#ffffffa6' }}>
              Ao pular o tutorial você entrará no modo avançado do XGBAS. Deseja
              continuar?
            </span>
          </Modal>
        </Paragraph>
        <Menu
          onClick={handleClick}
          selectedKeys={[current]}
          mode="horizontal"
          className="top-menu"
        >
          {menuItems.map(item => (
            <Menu.Item key={item.key} className="top-menu-item">
              <span className="top-menu-item-text">{item.title}</span>
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className="content">
        <div className="image-box">
          {current === 'relativoNovo' && (
            <div
              style={{
                backgroundImage: `url(${relativoNovo})`,
                height: '74vh',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className="button-container">
                <span className="button-container-title"> Relativo novo </span>
                <span className="button-container-text">
                  Quando a marcação será feita
                  <br />
                  pela primeira vez em um novo local.
                </span>
                <Link
                  to="/guia-relativo"
                  onClick={e => {
                    e.preventDefault();
                    dispatch({
                      type: LayoutTypes.SET_MODE_TYPE,
                      payload: 'Relativo novo'
                    });
                    history.push('/guia-relativo');
                  }}
                >
                  <Button className="button">
                    <span className="button-text">Escolher modo</span>
                  </Button>
                </Link>
              </div>
            </div>
          )}
          {current === 'relativoMarcado' && (
            <div
              style={{
                backgroundImage: `url(${relativoMarcado})`,
                height: '74vh',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className="button-container">
                <span className="button-container-title">Relativo marcado</span>
                <span className="button-container-text">
                  Quando você ja marcou o local anteriormente.
                </span>
                <Link
                  to="/guia-relativo"
                  onClick={e => {
                    e.preventDefault();
                    dispatch({
                      type: LayoutTypes.SET_MODE_TYPE,
                      payload: 'Relativo marcado'
                    });
                    history.push('/guia-relativo');
                  }}
                >
                  <Button className="button">
                    <span className="button-text">Escolher modo</span>
                  </Button>
                </Link>
              </div>
            </div>
          )}
          {current === 'absolutoComInternet' && (
            <div
              style={{
                backgroundImage: `url(${absolutoComInternet})`,
                height: '74vh',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className="button-container">
                <span className="button-container-title">
                  Absoluto com internet
                </span>
                <span className="button-container-text">
                  Quando você deseja pulverizar ou mapear com posicionamento
                  absoluto a partir de referência precisa recebida do IBGE
                  (NTRIP).
                </span>
                <Link
                  to="/guia-relativo"
                  onClick={e => {
                    e.preventDefault();
                    dispatch({
                      type: LayoutTypes.SET_MODE_TYPE,
                      payload: 'Absoluto com internet'
                    });
                    history.push('/guia-relativo');
                  }}
                >
                  <Button className="button">
                    <span className="button-text">Escolher modo</span>
                  </Button>
                </Link>
              </div>
            </div>
          )}
          {current === 'basePosProcessado' && (
            <div
              style={{
                backgroundImage: `url(${basePosProcessado})`,
                height: '74vh',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className="button-container">
                <span className="button-container-title">
                  Ponto pós-processado
                </span>
                <span className="button-container-text">
                  Quando você deseja materializar (medir a coordenada) um novo
                  ponto de alta precisão com base em pós processamento no IBGE.
                </span>
                <Link
                  to="/guia-relativo"
                  onClick={e => {
                    e.preventDefault();
                    dispatch({
                      type: LayoutTypes.SET_MODE_TYPE,
                      payload: 'Ponto pós-processado'
                    });
                    history.push('/guia-relativo');
                  }}
                >
                  <Button className="button">
                    <span className="button-text">Escolher modo</span>
                  </Button>
                </Link>
              </div>
            </div>
          )}
          {current === 'vooPosProcessado' && (
            <div
              style={{
                backgroundImage: `url(${vooPosProcessado})`,
                height: '74vh',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className="button-container">
                <span className="button-container-title">
                  Voo pós-processado
                </span>
                <span className="button-container-text">
                  Quando você deseja realizar o mapeamento e pós processar o
                  mapa.
                </span>
                <Link
                  to="/guia-relativo"
                  onClick={e => {
                    e.preventDefault();
                    dispatch({
                      type: LayoutTypes.SET_MODE_TYPE,
                      payload: 'Voo pós processado'
                    });
                    history.push('/guia-relativo');
                  }}
                >
                  <Button className="button">
                    <span className="button-text">Escolher modo</span>
                  </Button>
                </Link>
              </div>
            </div>
          )}
          {current === 'transporteDeBase' && (
            <div
              style={{
                backgroundImage: `url(${transporteDaBase})`,
                height: '74vh',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className="button-container">
                <span className="button-container-title">
                  Transporte de base
                </span>
                <span className="button-container-text">
                  Quando você deseja mudar o seu local de operação e
                  consequentemente mudar a posição da sua base XRTK para
                  concluir este serviço. Este procedimento possui um erro de até
                  10cm.
                </span>
                <Link
                  to="/guia-relativo"
                  onClick={e => {
                    e.preventDefault();
                    dispatch({
                      type: LayoutTypes.SET_MODE_TYPE,
                      payload: 'Transporte de base'
                    });
                    history.push('/guia-relativo');
                  }}
                >
                  <Button className="button">
                    <span className="button-text">Escolher modo</span>
                  </Button>
                </Link>
              </div>
            </div>
          )}
          {current === 'coordenadaManual' && (
            <div
              style={{
                backgroundImage: `url(${coordenadaManual})`,
                height: '74vh',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div className="button-container">
                <span className="button-container-title">
                  Coordenada manual
                </span>
                <span className="button-container-text">
                  Quando você conhece a coordenada do marcador de solo onde vai
                  instalar o XRTK.
                </span>
                <Link
                  to="/guia-relativo"
                  onClick={e => {
                    e.preventDefault();
                    dispatch({
                      type: LayoutTypes.SET_MODE_TYPE,
                      payload: 'Coordenada manual'
                    });
                    history.push('/guia-relativo');
                  }}
                >
                  <Button className="button">
                    <span className="button-text">Escolher modo</span>
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="menu-button-container">
          <Button
            className="menu-button"
            onClick={() => {
              const newIndex =
                (currentSubMenu - 1 + menuItems.length) % menuItems.length;
              setCurrentSubMenu(newIndex);
              setCurrent(menuItems[newIndex].key);
            }}
          >
            <LeftOutlined />
          </Button>
          <span className="menu-button-text">
            {currentSubMenu + 1}
            {'  de  '}
            {menuItems.length}
          </span>
          <Button
            className="menu-button"
            onClick={() => {
              const newIndex = (currentSubMenu + 1) % menuItems.length;
              setCurrentSubMenu(newIndex);
              setCurrent(menuItems[newIndex].key);
            }}
          >
            <RightOutlined />
          </Button>
        </div>
        <span className="version">v{process.env.REACT_APP_XGBAS_VERSION}</span>
      </div>
    </div>
  );
};

const mapStateToProps = ({ websocket, gps }: ApplicationState) => ({
  connectionStatus: websocket.connectionStatus
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions, ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Main);
