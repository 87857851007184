export enum WebsocketTypes {
  START_CONNECTION = '@Websocket/START_CONNECTION',
  START_DISCONNECTION = '@Websocket/START_DISCONNECTION',
  IS_CONNECTING = '@Websocket/IS_CONNECTING',
  IS_CONNECTED = '@Websocket/IS_CONNECTED',
  ISNT_CONNECTED = '@Websocket/ISNT_CONNECTED',
  CHANGE_SENDING_CORRECTIONS = '@Websocket/CHANGE_SENDING_CORRECTIONS',
  CHANGE_CAPTURING = '@Websocket/CHANGE_CAPTURING',
  IS_SENDING_VALID_POSITION = '@Websocket/IS_SENDING_VALID_POSITION',
  ISNT_SENDING_VALID_POSITION = '@Websocket/ISNT_SENDING_VALID_POSITION',
  ENABLE_CORRECTION = '@Websocket/ENABLE_CORRECTION',
  REMOVE_FILE = '@Websocket/REMOVE_FILE',
  DOWNLOAD_FILE = '@Websocket/DOWNLOAD_FILE',
  SET_DOWNLOAD_PERCENTAGE = '@Websocket/SET_DOWNLOAD_PERCENTAGE',
  CHANGE_DOWNLOAD_STATUS = '@Websocket/CHANGE_DOWNLOAD_STATUS',
  SET_RINEX_FILENAME = '@Websocket/SET_RINEX_FILENAME',
  SET_SOCKET_RESPONSE_IBGE = '@Websocket/SET_SOCKET_RESPONSE_IBGE',
  TOGGLE_RINEX_LOG = '@Websocket/TOGGLE_RINEX_LOG',
  FIX_BASE_HOTSPOT = '@Websocket/FIX_BASE_HOTSPOT',
  OPEN_IBGE_SOCKET = '@Websocket/OPEN_IBGE_SOCKET',
  FIX_BASE_NTRIP = '@Websocket/FIX_BASE_NTRIP',
  UNFIX_BASE_HOTSPOT = '@Websocket/UNFIX_BASE_HOTSPOT',
  CLOSE_IBGE_SOCKET = '@Websocket/CLOSE_IBGE_SOCKET',
  OPEN_FETCH_FILE = '@Websocket/OPEN_FETCH_FILE',
  CLOSE_FETCH_FILE = '@Websocket/CLOSE_FETCH_FILE',
  UNFIX_BASE_NTRIP = '@Websocket/UNFIX_BASE_NTRIP',
  NEAR_POSITIONS = '@Websocket/NEAR_POSITIONS',
  SECONDS_TO_CAPTURE = '@Websocket/SECONDS_TO_CAPTURE',
  GREY_OPERATION = '@Websocket/GREY_OPERATION',
  SECONDS_IN_COLETE = '@Websocket/SECONDS_IN_COLETE'
}

interface WebsocketStartConnectionAction {
  type: typeof WebsocketTypes.START_CONNECTION;
}

interface WebsocketStartDisconnectionAction {
  type: typeof WebsocketTypes.START_DISCONNECTION;
}

interface WebsocketIsConnectingAction {
  type: typeof WebsocketTypes.IS_CONNECTING;
}
interface WebsocketIsConnectedAction {
  type: typeof WebsocketTypes.IS_CONNECTED;
}

interface WebsocketIsntConnectedAction {
  type: typeof WebsocketTypes.ISNT_CONNECTED;
}

interface WebsocketIsSendingCorrectionAction {
  type: typeof WebsocketTypes.CHANGE_SENDING_CORRECTIONS;
}

interface WebsocketIsCapturingAction {
  type: typeof WebsocketTypes.CHANGE_CAPTURING;
}

interface WebsocketIsSendingValidPositionAction {
  type: typeof WebsocketTypes.IS_SENDING_VALID_POSITION;
}
interface WebsocketIsntSendingValidPositionAction {
  type: typeof WebsocketTypes.ISNT_SENDING_VALID_POSITION;
}

interface WebsocketEnableCorrectionAction {
  type: typeof WebsocketTypes.ENABLE_CORRECTION;
}

interface WebsocketRemoveFileAction {
  type: typeof WebsocketTypes.REMOVE_FILE;
  payload: {
    fileName: string;
  };
}

interface WebsocketDownloadFileAction {
  type: typeof WebsocketTypes.DOWNLOAD_FILE;
  payload: {
    downloadingFileKey: string;
    fileName: string;
  };
}

interface SetDownloadPercentage {
  type: typeof WebsocketTypes.SET_DOWNLOAD_PERCENTAGE;
  payload: {
    percentage: number;
  };
}

interface WebsocketChangeDownloadStatusAction {
  type: typeof WebsocketTypes.CHANGE_DOWNLOAD_STATUS;
  payload: {
    downloadStatus: 'active' | 'exception' | 'success';
  };
}

interface WebsocketSetRinexFilenameAction {
  type: typeof WebsocketTypes.SET_RINEX_FILENAME;
  payload: {
    fileName: string;
  };
}

interface WebsocketSetSocketResponseIbge {
  type: typeof WebsocketTypes.SET_SOCKET_RESPONSE_IBGE;
  payload: {
    responseIbge: string;
  };
}

export interface FixBaseHotConnection {
  type: typeof WebsocketTypes.FIX_BASE_HOTSPOT;
}

export interface OpenIBGEsocketAction {
  type: typeof WebsocketTypes.OPEN_IBGE_SOCKET;
}

export interface FixBaseNtripConnection {
  type: typeof WebsocketTypes.FIX_BASE_NTRIP;
}

export interface UnfixBaseHotConnection {
  type: typeof WebsocketTypes.UNFIX_BASE_HOTSPOT;
}

export interface CloseIBGEsocketAction {
  type: typeof WebsocketTypes.CLOSE_IBGE_SOCKET;
}

export interface OpenFetchFileAction {
  type: typeof WebsocketTypes.OPEN_FETCH_FILE;
}

export interface CloseFetchFileAction {
  type: typeof WebsocketTypes.CLOSE_FETCH_FILE;
}

export interface UnfixBaseNtripConnection {
  type: typeof WebsocketTypes.UNFIX_BASE_NTRIP;
}

interface WebsocketSetSecondsToCapture {
  type: typeof WebsocketTypes.SECONDS_TO_CAPTURE;
  payload: {
    secondsToCapture: string;
  };
}

interface WebsocketSetSecondsInColete {
  type: typeof WebsocketTypes.SECONDS_IN_COLETE;
  payload: {
    secondsInColete: string;
  };
}

interface WebsocketSetGreyOperation {
  type: typeof WebsocketTypes.GREY_OPERATION;
  payload: {
    greyOperation: string;
  };
}

export interface PositionMessageAction {
  type: typeof WebsocketTypes.NEAR_POSITIONS;
  payload: {
    positions: Array<{
      latitude: string;
      longitude: string;
      altitude: string;
      data: string;
      dist: string;
    }>;
  };
}

export type WebsocketActions =
  | WebsocketStartConnectionAction
  | WebsocketStartDisconnectionAction
  | WebsocketIsConnectingAction
  | WebsocketIsConnectedAction
  | WebsocketIsntConnectedAction
  | WebsocketIsSendingCorrectionAction
  | WebsocketIsCapturingAction
  | WebsocketIsSendingValidPositionAction
  | WebsocketIsntSendingValidPositionAction
  | WebsocketEnableCorrectionAction
  | WebsocketRemoveFileAction
  | WebsocketDownloadFileAction
  | SetDownloadPercentage
  | WebsocketChangeDownloadStatusAction
  | WebsocketSetRinexFilenameAction
  | WebsocketSetSocketResponseIbge
  | FixBaseHotConnection
  | FixBaseNtripConnection
  | UnfixBaseHotConnection
  | UnfixBaseNtripConnection
  | OpenIBGEsocketAction
  | CloseIBGEsocketAction
  | OpenFetchFileAction
  | CloseFetchFileAction
  | PositionMessageAction
  | WebsocketSetSecondsToCapture
  | WebsocketSetSecondsInColete
  | WebsocketSetGreyOperation;

export interface WebsocketState {
  fixBaseHotspot: number;
  openIBGEsocket: number;
  openFetchFile: number;
  fixBaseNtrip: number;
  responseIbge: string;
  isCapturing: boolean;
  connectionStatus: number;
  validPositions: boolean;
  isSendingorrections: boolean;
  enableCorrection: boolean;
  downloadingFileKey: string;
  downloadPercentage: number;
  downloadStatus: 'active' | 'exception' | 'success';
  fileName: string;
  positions: {
    latitude: string;
    longitude: string;
    altitude: string;
    data: string;
    dist: string;
  }[];
  secondsToCapture: string;
  secondsInColete: string;
  greyOperation: string;
}
