/* eslint-disable no-await-in-loop */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

import { MiddlewareAPI } from 'redux';
import { message, notification } from 'antd';
import { ApplicationState } from '../store/rootReducer';
import XrtkDecoder from './XrtkDecoder';
import NmeaDecoder from './NmeaDecoder';
import { WebsocketTypes } from '../types/Websocket';
import { getMacAddr } from './NtripRequest';
import { GpsTypes } from '../types/Gps';

export default class XrtkWebsocket {
  origin: 'click' | null;

  readBuffer: string;

  store: MiddlewareAPI;

  interval: NodeJS.Timeout | null;

  counter: number = 0;

  promiseControl: boolean = false;

  constructor(store: MiddlewareAPI) {
    this.origin = null;
    this.readBuffer = '';
    this.store = store;
    this.interval = null;
    this.counter = 0;
  }

  disconnect() {
    this.origin = 'click';
    localStorage.setItem('connectionStatus', '0');
    this.store.dispatch({
      type: WebsocketTypes.ISNT_CONNECTED
    });

    notification.info({
      message: 'Conexão encerrada',
      description: 'A conexão com a base foi encerrada'
    });
  }

  isRtcmData(websocketCharacteristc: string) {
    if (websocketCharacteristc.includes('GGA')) {
      this.store.dispatch({
        type: GpsTypes.RTCM_MESSAGE,
        payload: {
          isRtcm: true
        }
      });
    } else {
      this.store.dispatch({
        type: GpsTypes.RTCM_MESSAGE,
        payload: {
          isRtcm: false
        }
      });
    }
    //a lógica de rtcm esta invertida!
  }

  processMessagesCharacteristics(websocketCharacteristc: string) {
    if (websocketCharacteristc) {
      for (const c of websocketCharacteristc) {
        if (c === '\n') {
          const data = this.readBuffer.trim();
          this.readBuffer = '';

          if (data) {
            const { websocket } = this.store.getState() as ApplicationState;
            if (data.includes('$X,')) {
              const decodedMessage = new XrtkDecoder(data).processMessage();
              if (this) {
                this.store.dispatch(decodedMessage);
              }
            } else {
              const decodedMessage = new NmeaDecoder(data).processMessage();
              // console.log('Mensagem NMEA decodificada:', decodedMessage);

              if (decodedMessage && typeof decodedMessage !== 'undefined') {
                if (
                  decodedMessage.type === '@gps/POSITION_MESSAGE' &&
                  decodedMessage.payload.longitude !== '0' &&
                  !websocket.validPositions &&
                  websocket.connectionStatus === 2
                ) {
                  message.destroy();
                  this.store.dispatch({
                    type: WebsocketTypes.IS_SENDING_VALID_POSITION
                  });
                }
                if (websocket.validPositions) {
                  this.store.dispatch(decodedMessage);
                }
              }
            }
          }
        } else {
          this.readBuffer += c;
        }
      }
    }
    return new Error('Caracteristica não encontrada');
  }

  getURLTelemetry(source: string) {
    return 'http://localhost:8080/telemetry';
  }

  makeHttpRequest(source: string, retryCount: number = 0) {
    const controller = new AbortController();
    const ms = 5000;
    const timeoutId = setTimeout(() => controller.abort(), ms);

    // fetch('http://192.168.4.1/telemetry', { signal: controller.signal }) // ip da base
    fetch(this.getURLTelemetry(source), { signal: controller.signal })
      .then(response => {
        clearTimeout(timeoutId);
        return response.text();
      })
      .then(data => {
        // console.log(data);
        this.isRtcmData(data);
        this.processMessagesCharacteristics(data);
        localStorage.setItem('connectionStatus', '2');

        this.store.dispatch({
          type: WebsocketTypes.IS_CONNECTED
        });
      })
      .catch(error => {
        clearTimeout(timeoutId);
        console.log(`catch: ${error} ${(this.counter += 1)}`);

        localStorage.setItem('connectionStatus', '0');
        // message.destroy();
        this.store.dispatch({
          type: WebsocketTypes.ISNT_CONNECTED
        });
      });
  }

  connect() {
    this.origin = null;
    this.makeHttpRequest('base');
  }
}
function then(arg0: (response: any) => any) {
  throw new Error('Function not implemented.');
}
