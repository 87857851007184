import { GpsTypes, XrtkInformationMessageAction } from '../types/Gps';

class XrtkDecoder {
  message: string[];

  constructor(message: string) {
    this.message = message.split(',');
  }

  processMessage(): XrtkInformationMessageAction {
    const batteryPercentage = (Number(this.message[1]) * 100) / 8.4;
    const isCharging = this.message[3] === '1';
    const isOverheated = this.message[4] === '1';
    return {
      type: GpsTypes.XRTK_INFO_MESSAGE,
      payload: {
        batteryPercentage,
        isCharging,
        isOverheated
      }
    };
  }
}

export default XrtkDecoder;
