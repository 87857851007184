import { action } from 'typesafe-actions';
import {
  GpsTypes,
  ConfigurationLocationTypes,
  ConfigurationTypes
} from '../../types/Gps';

export const selectGpsModel = (model: string) =>
  action(GpsTypes.SELECT_GPS_MODEL, {
    model
  });

export const selectSfType = (sf: string) =>
  action(GpsTypes.SELECT_SF_TYPE, {
    sf
  });

export const configGps = (
  location: ConfigurationLocationTypes,
  type: ConfigurationTypes,
  commands?: DataView
) =>
  action(GpsTypes.CONFIG_GPS, {
    location,
    type,
    commands
  });

export const startLora = () => action(GpsTypes.START_LORA);
export const stopLora = () => action(GpsTypes.STOP_LORA);

export const setConfigType = (configType: ConfigurationLocationTypes) =>
  action(GpsTypes.SET_CONFIG_TYPE, {
    configType
  });

export const setConfirmRinexOpen = (isOpen: boolean) =>
  action(GpsTypes.SET_CONFIRM_RINEX_OPEN, {
    isOpen
  });

export const SetRtcmMessage = (isRtcm: boolean) =>
  action(GpsTypes.RTCM_MESSAGE, {
    isRtcm
  });
