import React from 'react';
import { connect } from 'react-redux';

import { Card } from 'antd';
import { ApplicationState } from '../../store/rootReducer';

type Props = {
  latitude: number;
  latitudeError: number;
  greyOperation: string;
};
const Latitude = ({ latitude, latitudeError, greyOperation }: Props) => (
  <span style={Number(greyOperation) ? { color: '#9b9b9b' } : {}}>
    {latitude.toFixed(8)}
    &nbsp; &#xB1;
    {latitudeError.toFixed(3)}
  </span>
);

const mapStateToProps = ({ gps, websocket }: ApplicationState) => ({
  latitude: Number(gps.position.latitude),
  latitudeError: Number(gps.positionError.latitude),
  greyOperation: websocket.greyOperation
});

export default connect(mapStateToProps)(Latitude);
