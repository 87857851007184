export function convertLongitudeDmToDdd(
  longitude: string,
  indicadorLongitude: string
): string {
  let finalLongitude = 0.0;

  const degreessAndMinute: string = longitude.substring(
    0,
    longitude.indexOf('.')
  );

  let degree = 0;
  let minute = 0;
  if (degreessAndMinute.length === 5) {
    degree = Number(longitude.substring(0, 3));
    minute = Number(longitude.substring(3, longitude.length));
  } else if (degreessAndMinute.length === 4) {
    degree = Number(longitude.substring(0, 2));
    minute = Number(longitude.substring(2, longitude.length));
  }
  finalLongitude = degree + minute / 60;

  if (indicadorLongitude.match('W')) {
    finalLongitude *= -1;
  }
  return finalLongitude.toString();
}
