/* eslint-disable no-bitwise */
import React from 'react';

import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Col, Row } from 'antd';

import './style.css';

import * as GpsAction from '../../../../store/gps/actions';
import { ApplicationState } from '../../../../store/rootReducer';

import Lat from '../../../status/lat';
import Long from '../../../status/long';
import Alt from '../../../status/alt';
import Sat from '../../../status/sat';
import Sol from '../../../status/sol';

interface Position {
  latitude: string;
  longitude: string;
  altitude: string;
  data: string;
  dist: string;
}

type Props = {
  isRtcmMessage: boolean;
  positions: Position[];
  greyOperation: string;
};

const DinamicPositionPanel = ({
  isRtcmMessage,
  positions,
  greyOperation
}: Props) => {
  const [form] = Form.useForm();

  // escolhe a posição com menor distancia
  const validPositions = positions.filter(
    position =>
      Number(position.latitude.split('+')[0]) !== 0 &&
      Number(position.longitude.split('+')[0]) !== 0
  );
  validPositions.sort((a, b) => Number(a.dist) - Number(b.dist));

  const firstPosition = validPositions[0];

  return (
    <>
      <Form form={form} wrapperCol={{ span: 24 }} name="position-insert">
        <Row>
          <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            <div className="flex-content">
              <Form.Item name="latitude-insert" label="Latitude (DD)">
                {Number(greyOperation) && firstPosition?.latitude ? (
                  <span>{firstPosition?.latitude}</span>
                ) : (
                  <Lat />
                )}
              </Form.Item>
            </div>
            <div className="flex-content">
              <Form.Item name="longitude-insert" label="Longitude (DD)">
                {Number(greyOperation) && firstPosition?.longitude ? (
                  <span>{firstPosition?.longitude}</span>
                ) : (
                  <Long />
                )}
              </Form.Item>
            </div>
            <div className="flex-content">
              <Form.Item name="altura-insert" label="Altitude elipsoidal (m)">
                {Number(greyOperation) && firstPosition?.altitude ? (
                  <span>{firstPosition?.altitude}</span>
                ) : (
                  <Alt />
                )}
              </Form.Item>
            </div>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            <Form.Item name="satelite-insert" label="Satélites">
              <Sat />
            </Form.Item>
            <Form.Item name="solucao-insert" label="Solução">
              <Sol />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = ({ gps, websocket }: ApplicationState) => ({
  latitude: Number(gps.position.latitude),
  longitude: Number(gps.position.longitude),
  altitude: gps.position.altitude,
  positionUuid: gps.position.uuid,
  enableSendCorrection: websocket.enableCorrection,
  isRtcmMessage: gps.isRtcmMessage,
  positions: websocket.positions,
  greyOperation: websocket.greyOperation
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...GpsAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(DinamicPositionPanel);
