import { action } from 'typesafe-actions';
import { LayoutTypes, NotificationTypes } from '../../types/Layout';

export const toggleSideBar = () => action(LayoutTypes.TOGGLE_SIDEBAR);
export const showNotifications = (
  type: NotificationTypes,
  message: string,
  description: string
) =>
  action(LayoutTypes.SHOW_NOTIFICATION, {
    type,
    message,
    description
  });
