/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  Button,
  Form,
  Row,
  Col,
  PageHeader,
  Collapse,
  Card,
  Tooltip
} from 'antd';
import { useForm } from 'antd/lib/form/util';
import Icon, {
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
  WifiOutlined,
  WarningFilled,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ApplicationState } from '../../store/rootReducer';
import * as WebsocketActions from '../../store/websocket/actions';
import * as GpsAction from '../../store/gps/actions';
import {
  ConfigurationTypes,
  ConfigurationLocationTypes
} from '../../types/Gps';
import tutorialImg from '../initial-view/images/relativo-img.png';

// import relativoNovo from '../initial-view/images/relativoNovo.jpg';
// import absolutoComInternet from '../initial-view/images/absolutoComInternet.jpg';
// import basePosProcessado from '../initial-view/images/basePosProcessado.jpg';
// import transporteDaBase from '../initial-view/images/transporteDeBase.jpg';
// import vooPosProcessado from '../initial-view/images/vooPosProcessado.jpg';

import './style.css';

const { Panel } = Collapse;

interface DispatchProps {
  connectToWebsocket(): void;
  disconnectFromWebsocket(): void;
  configGps(
    location: ConfigurationLocationTypes,
    type: ConfigurationTypes
  ): void;
  selectGpsModel(model: string): void;
  SetRtcmMessage(isRtcm: boolean): void;
}
interface StateProps {
  connectionStatus: number;
  gpsModel: string | null;
  configType: ConfigurationLocationTypes;
  modeType: string;
  isRtcmMessage: boolean;
  fixBaseHotspot: number;
  fixBaseNtrip: number;
  secondsToCapture: string;
  secondsInColete: string;
  greyOperation: string;
}

type Props = StateProps & DispatchProps;

const Extra = ({
  connectionStatus,
  gpsModel,
  modeType,
  connectToWebsocket,
  disconnectFromWebsocket,
  selectGpsModel,
  isRtcmMessage,
  SetRtcmMessage,
  fixBaseHotspot,
  fixBaseNtrip,
  secondsToCapture,
  secondsInColete,
  greyOperation
}: Props) => {
  const [form] = useForm();

  useEffect(() => {
    if (connectionStatus === 0) {
      selectGpsModel('184b');
    }

    // const timer = setInterval(() => {
    //   setColor(prevColor => (prevColor === '#00c880' ? '#00e670' : '#00c880'));
    // }, 500);
    // return () => clearInterval(timer);
  }, [connectionStatus]);

  const handleConnectButtonClick = () => {
    selectGpsModel('184b');

    if (connectionStatus >= 2) {
      disconnectFromWebsocket();
    } else {
      connectToWebsocket();
    }
  };

  const handleConnectionStatus = () => {
    switch (connectionStatus) {
      case 0:
        return 'Conectar';
      case 1:
        return 'Conectando';
      default:
        return 'Desconectar';
    }
  };

  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkSteps, setCheckSteps] = useState(false);
  const [checkWiFi, SetCheckWiFi] = useState(false);
  const [checkNtrip, SetCheckNtrip] = useState(false);
  const [firmwareVersion, setFirmwareVersion] = useState('v.1.0.0');

  useEffect(() => {
    if (connectionStatus >= 2 && checkWiFi && checkNtrip) setCheckSteps(true);
  }, [connectionStatus, checkWiFi, checkNtrip]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  // // Tipagem para os modos e suas respectivas imagens
  // type ModeTypeImages = {
  //   'Absoluto com internet': string;
  //   'Ponto pós-processado': string;
  //   'Relativo novo': string;
  //   'Transporte de base': string;
  //   'Voo pós processado': string;
  // };

  // // Mapeamento das imagens para os modos
  // const modeTypeImages: ModeTypeImages = {
  //   'Absoluto com internet': absolutoComInternet,
  //   'Ponto pós-processado': basePosProcessado,
  //   'Relativo novo': relativoNovo,
  //   'Transporte de base': transporteDaBase,
  //   'Voo pós processado': vooPosProcessado
  // };

  // // Garante que modeType seja uma chave válida em ModeTypeImages
  // const modeTypeImg =
  //   modeTypeImages[modeType as keyof ModeTypeImages] || tutorialImg;
  //modificar la em baixo a tutorialImg para modeTypeImg

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        //  TESTE MOCK
        // const mockHtmlResponse = `
        //   <!DOCTYPE html>
        //   <html lang="pt">
        //   <head>
        //     <meta charset="UTF-8">
        //     <title>Firmware</title>
        //   </head>
        //   <body>
        //     <form method="post" action='/firmware'>
        //       <label for="Versao">v2.9.0</label><br>
        //       <label for="SSID">SSID:</label><br>
        //       <input type="text" id="SSID" name="SSID" required><br><br>
        //       <label for="password">password:</label><br>
        //       <input type="password" id="password" name="password" required><br><br>
        //       <label for="link">link:</label><br>
        //       <input type="text" id="link" name="link" value="74.235.201.172" required><br><br>
        //       <label for="endpoint">endpoint:</label><br>
        //       <input type="text" id="endpoint" name="endpoint" value="firmware.bin" required><br><br>
        //       <input type="submit" value="Atualizar">
        //     </form>
        //   </body>
        //   </html>
        // `;
        // const text = mockHtmlResponse;

        const response = await fetch('http://localhost:8080/firmware');
        const text = await response.text();

        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const versionLabel = doc.querySelector('label[for="Versao"]');
        if (versionLabel !== null) {
          setFirmwareVersion(
            versionLabel.textContent?.trim() || 'Versão não encontrada!'
          );
        }
      } catch (error) {
        console.error('Erro ao buscar a versão:', error);
      }
    };

    fetchVersion();
  }, []);

  const sendFirmwareUpdate = async () => {
    const ssid = localStorage.getItem('ssidValue');
    const password = localStorage.getItem('passwordSsidValue');

    const formData = new URLSearchParams();
    formData.append('SSID', ssid?.toString() || 'admin');
    formData.append('password', password?.toString() || 'admin');
    formData.append('link', '74.235.201.172');
    formData.append('endpoint', 'firmware.bin');

    try {
      const response = await fetch('http://localhost:8080/firmware', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData.toString()
      });
      if (!response.ok) {
        console.error('Erro ao Atualizar Firmware:', response.statusText);
        return;
      }
      const data = await response.text();
      console.log(data);
      if (data.includes('Erro')) {
        console.error('Erro ao Atualizar Firmware:', response.statusText);
        return;
      }
      console.log('Firmware Atualizado com sucesso!');
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };

  return (
    <>
      <PageHeader title="Conexão de dispositivos">
        <Row className="header-class">
          <Col>
            <h4 className="h4-class"></h4>
            <div style={{ display: 'flex' }}>
              <p className="p-class">Atualmente você está no modo</p>
              <p className="p-mode-class"> {modeType || 'Avançado'}</p>
            </div>
          </Col>
          <Col>
            <div className="buttons-menu">
              {/* <Link
                to="/"
                className="menuopt"
                onClick={e => {
                  e.preventDefault();
                  history.push('/');
                }}
              >
                <Button key="header-btn" type="primary" className="header-btn">
                  Alterar modo
                </Button>
              </Link> */}
              <Link
                to="/"
                onClick={e => {
                  e.preventDefault();
                  history.push('/guia-relativo');
                }}
                className="menuopt"
              >
                {modeType !== 'Avançado' && (
                  <Button key="header-btn" type="primary" className="manualopt">
                    Tutorial
                  </Button>
                )}
              </Link>
            </div>
          </Col>
        </Row>

        {isRtcmMessage &&
        connectionStatus >= 2 &&
        Number(secondsToCapture) >= 0 ? (
          <div className="segmented_picker">
            <WarningFilled
              className="segmented_picker_img"
              style={{ fontSize: '2rem' }}
            />
            <div>
              <h5 className="segmented_picker_h5">Base em captura</h5>
              <p className="segmented_picker_p">
                Aguarde até {Math.floor(parseInt(secondsToCapture) / 60)} minuto
                {Math.floor(parseInt(secondsToCapture) / 60) !== 1
                  ? 's'
                  : ''} e {parseInt(secondsToCapture) % 60} segundo
                {parseInt(secondsToCapture) % 60 !== 1 ? 's' : ''} para o fim da
                captura
              </p>
            </div>
          </div>
        ) : connectionStatus < 2 ? (
          <div className="segmented_picker">
            <WarningFilled
              className="segmented_picker_img"
              style={{ fontSize: '2rem' }}
            />
            <div>
              <h5 className="segmented_picker_h5">Base Offline</h5>
              <p className="segmented_picker_p">
                Sua base precisa estar ligada para coletar dados de
                posicionamento.
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}

        {parseInt(secondsInColete, 0) > 0 &&
          Number(greyOperation) &&
          modeType !== 'Relativo novo' &&
          modeType !== 'Relativo marcado' &&
          modeType !== 'Absoluto com internet' && (
            <div className="segmented_picker">
              <WarningFilled
                className="segmented_picker_img"
                style={{ fontSize: '2rem' }}
              />
              <div>
                <h5 className="segmented_picker_h5">Coletando dados</h5>
                {parseInt(secondsInColete, 0) > 7200 ? (
                  <p className="segmented_picker_p">
                    Sua base já coletou dados suficiente
                  </p>
                ) : (
                  <p className="segmented_picker_p">
                    <p className="segmented_picker_p">
                      Passaram-se{' '}
                      {Math.floor(parseInt(secondsInColete, 0) / 3600)} hora
                      {Math.floor(parseInt(secondsInColete, 0) / 3600) !== 1
                        ? 's'
                        : ''}{' '}
                      {Math.floor((parseInt(secondsInColete, 0) % 3600) / 60)}{' '}
                      minuto
                      {Math.floor(
                        (parseInt(secondsInColete, 0) % 3600) / 60
                      ) !== 1
                        ? 's'
                        : ''}{' '}
                      e {parseInt(secondsInColete, 0) % 60} segundo
                      {parseInt(secondsInColete, 0) % 60 !== 1 ? 's' : ''} para
                      o fim da coleta
                    </p>
                  </p>
                )}
              </div>
            </div>
          )}

        <Card className="connect-card">
          <Card.Grid className="img-grid" hoverable={false}>
            <img
              alt="./relativo-img.png"
              src={tutorialImg}
              className="img-extra"
            />
          </Card.Grid>

          <Card.Grid className="header-grid" hoverable={false}>
            <h5 className="card_h5">Base</h5>
            <Button
              key="card-btn-firmware"
              type="primary"
              className="card-btn-firmware"
              onClick={sendFirmwareUpdate}
              disabled={fixBaseHotspot <= 0}
            >
              Atualizar Firmware
            </Button>
          </Card.Grid>

          <Card.Grid className="status-grid" hoverable={false}>
            <p className="card_p">Status</p>
            <span className="card_status">
              {connectionStatus >= 2 ? (
                <Row>
                  <Col>
                    <FontAwesomeIcon icon={faCircle} className="faCircle" />
                  </Col>
                  <Col style={{ marginLeft: '0.5rem' }}>Online</Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="faCircle-Desc"
                    />
                  </Col>
                  <Col style={{ marginLeft: '0.5rem' }}>Offline</Col>
                </Row>
              )}
            </span>
          </Card.Grid>
          <Card.Grid className="model-grid" hoverable={false}>
            <p className="card_p"> Dispositivo </p>
            <p className="card_status"> XRTK184C </p>
          </Card.Grid>
          <Card.Grid className="model-grid" hoverable={false}>
            <p className="card_p"> Firmware </p>
            <p className="card_status">{firmwareVersion}</p>
          </Card.Grid>
        </Card>
        {modeType === 'Absoluto com internet' || modeType === 'Avançado' ? (
          <Row className="internet-card-row">
            <Card className="internet-card">
              <Card.Grid
                className="header-grid"
                hoverable={false}
                style={{ width: '100%' }}
              >
                <h5 className="card_h5">Internet</h5>
                <Link
                  to="/"
                  className="menuopt"
                  onClick={e => {
                    e.preventDefault();
                    history.push({
                      pathname: '/ntrip',
                      state: { status: 'Internet' }
                    });
                  }}
                >
                  <Button
                    key="card-btn"
                    type="primary"
                    className="card-btn"
                    onClick={showModal}
                    disabled={connectionStatus < 2}
                  >
                    Configurar
                  </Button>
                </Link>
              </Card.Grid>
              <div className="half-grid">
                <Card.Grid
                  className="status-grid"
                  hoverable={false}
                  style={{ width: '50%' }}
                >
                  <p className="card_p">Status</p>
                  <span className="card_status" style={{ display: 'flex' }}>
                    {fixBaseHotspot > 0 ? (
                      <Row>
                        <Col>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="faCircle"
                          />
                        </Col>
                        <Col style={{ marginLeft: '0.5rem' }}>Online</Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="faCircle-Desc"
                          />
                        </Col>
                        <Col style={{ marginLeft: '0.5rem' }}>Offline</Col>
                      </Row>
                    )}
                  </span>
                </Card.Grid>
                {/* <Card.Grid className="model-grid" hoverable={false}>
                <p className="card_p"> Rede </p>
                <p className="card_status"> - </p>
              </Card.Grid> */}
              </div>
            </Card>
            <Card className="internet-card">
              <Card.Grid
                className="header-grid"
                hoverable={false}
                style={{ width: '100%' }}
              >
                <h5 className="card_h5">Conta NTRIP</h5>
                <Tooltip title="Você deve configurar o Wi-Fi antes de configurar sua conta NTRIP.">
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Tooltip>
                <Link
                  to="/"
                  className="menuopt"
                  onClick={e => {
                    e.preventDefault();
                    history.push({
                      pathname: '/ntrip',
                      state: { status: 'NTRIP' }
                    });
                  }}
                >
                  <Button
                    key="card-btn"
                    type="primary"
                    className="card-btn"
                    onClick={showModal}
                    disabled={fixBaseHotspot <= 0}
                  >
                    Configurar
                  </Button>
                </Link>
              </Card.Grid>
              <div className="half-grid">
                <Card.Grid
                  className="status-grid"
                  hoverable={false}
                  style={{ width: '50%' }}
                >
                  <p className="card_p">Status</p>
                  <span className="card_status">
                    {fixBaseNtrip > 0 ? (
                      <Row>
                        <Col>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="faCircle"
                          />
                        </Col>
                        <Col style={{ marginLeft: '0.5rem' }}>Online</Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="faCircle-Desc"
                          />
                        </Col>
                        <Col style={{ marginLeft: '0.5rem' }}>Offline</Col>
                      </Row>
                    )}
                  </span>
                </Card.Grid>
              </div>
            </Card>
          </Row>
        ) : (
          <></>
        )}
      </PageHeader>
    </>
  );
};
const mapStateToProps = ({ websocket, layout, gps }: ApplicationState) => ({
  connectionStatus: websocket.connectionStatus,
  gpsModel: gps.model,
  configType: gps.configType,
  modeType: layout.modeType,
  isRtcmMessage: gps.isRtcmMessage,
  fixBaseHotspot: websocket.fixBaseHotspot,
  fixBaseNtrip: websocket.fixBaseNtrip,
  secondsToCapture: websocket.secondsToCapture,
  secondsInColete: websocket.secondsInColete,
  greyOperation: websocket.greyOperation
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions, ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Extra);
