/* eslint-disable no-bitwise */
import React, { useState, useEffect, useRef } from 'react';

import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  message,
  Button,
  Form,
  InputNumber,
  Input,
  Statistic,
  Col,
  Checkbox,
  notification,
  Spin,
  Tooltip,
  Row
} from 'antd';
import { QuestionCircleOutlined, WarningFilled } from '@ant-design/icons';
import './style.css';

import * as GpsAction from '../../../../store/gps/actions';
import { ApplicationState } from '../../../../store/rootReducer';
import {
  ConfigurationTypes,
  ConfigurationLocationTypes
} from '../../../../types/Gps';
import {
  toBytesInt32,
  getSignedByte
} from '../../../../utils/FunctionalsUtils';
import { getMacAddr } from '../../../../utils/NtripRequest';
import { Column } from '@antv/g2plot';
import Password from 'antd/lib/input/Password';

const { Countdown } = Statistic;

const countDownValue = 5 * 60 * 1000;

interface DispatchProps {
  startLora(): void;
  stopLora(): void;
  configGps(
    location: ConfigurationLocationTypes,
    type: ConfigurationTypes,
    commands?: DataView
  ): void;
  closeModal(): void;
  tutorial: boolean;
  mode: number;
  onPositionSucess(data: any): void;
}
interface StateProps {
  latitude: number;
  longitude: number;
  altitude: number;
  positionUuid: string;
  enableSendCorrection: boolean;
  modeType: string;
  connectionStatus: number;
}

type Props = StateProps & DispatchProps;

const PositionPanel = ({
  latitude,
  longitude,
  altitude,
  positionUuid,
  configGps,
  startLora,
  stopLora,
  enableSendCorrection,
  closeModal,
  tutorial,
  mode,
  modeType,
  connectionStatus,
  onPositionSucess
}: Props) => {
  const [form] = Form.useForm();
  const [enableConfig, setEnableConfig] = useState(false);
  const [fileName, setFileName] = useState('');
  const [altitudeXRTK, setAltitudeXRTK] = useState(Number(altitude.toFixed(3)));
  const [alturaAntena, setAlturaAntena] = useState(2.092);

  const [isLoading, setIsLoading] = useState(false);

  const fileNameRef = useRef<Input>(null);

  const handleEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    ref: React.MutableRefObject<Input | Password | null>
  ) => {
    if (e.key === 'Enter' && ref.current) {
      ref.current.focus();
    }
  };

  let inputNumberRef: { focus: () => void } | null = null;
  let inputAltitudeNumberRef: { focus: () => void } | null = null;
  let inputAlturaNumberRef: { focus: () => void } | null = null;

  const saveInputNumberRef = (input: any) => {
    if (input) {
      inputNumberRef = input;
    }
  };

  const saveInputAltitudeNumberRef = (input: any) => {
    if (input) {
      inputAltitudeNumberRef = input;
    }
  };

  const saveInputAlturaNumberRef = (input: any) => {
    if (input) {
      inputAlturaNumberRef = input;
    }
  };

  const handleLongEnterPress = (e: { key: string }) => {
    if (e.key === 'Enter' && inputNumberRef) {
      inputNumberRef.focus();
    }
  };

  const handleAlturaEnterPress = (e: { key: string }) => {
    if (e.key === 'Enter' && inputAlturaNumberRef) {
      inputAlturaNumberRef.focus();
    }
  };

  const handleSecondEnterPress = (e: { key: string }) => {
    if (e.key === 'Enter' && inputAltitudeNumberRef) {
      inputAltitudeNumberRef.focus();
    }
  };

  const getFixBaseCommand = (
    latitudeForm: number,
    longitudeForm: number,
    altitudeForm: number,
    antenaHeightForm: number
  ): DataView => {
    const commands: number[] = [];
    if (process.env.REACT_APP_MB_FIX_BASE_HEADER) {
      const hexasRoverConfig = process.env.REACT_APP_MB_FIX_BASE_HEADER.split(
        ' '
      );
      hexasRoverConfig.forEach(command => {
        commands.push(parseInt(command, 16));
      });
    }

    let finalAltitude = altitudeForm;
    if (antenaHeightForm !== null && typeof antenaHeightForm !== 'undefined') {
      finalAltitude += Number(antenaHeightForm);
    }
    const latitude7 = Math.trunc(latitudeForm * 1e7);
    const longitude7 = Math.trunc(longitudeForm * 1e7);
    const altitude2 = Math.trunc(finalAltitude * 1e2);

    let bytesArray: Uint8Array = toBytesInt32(latitude7);

    bytesArray.forEach(byte => {
      commands.push(byte);
    });

    bytesArray = toBytesInt32(longitude7);

    bytesArray.forEach(byte => {
      commands.push(byte);
    });

    bytesArray = toBytesInt32(altitude2);

    bytesArray.forEach(byte => {
      commands.push(byte);
    });

    const latitudePrecision = Math.trunc(
      (latitudeForm * 1e7 - latitude7) * 1e2
    );
    bytesArray = toBytesInt32(latitudePrecision);
    commands.push(bytesArray[0]);

    const longitudePrecision = Math.trunc(
      (longitudeForm * 1e7 - longitude7) * 1e2
    );
    bytesArray = toBytesInt32(longitudePrecision);
    commands.push(bytesArray[0]);

    const altitudePrecision = Math.trunc(
      Number((finalAltitude * 1e2 - altitude2).toFixed(2)) * 1e2
    );
    bytesArray = toBytesInt32(altitudePrecision);
    commands.push(bytesArray[0]);

    if (process.env.REACT_APP_MB_FIX_BASE_FOOTER) {
      const hexasRoverConfig = process.env.REACT_APP_MB_FIX_BASE_FOOTER.split(
        ' '
      );
      hexasRoverConfig.forEach(command => {
        commands.push(parseInt(command, 16));
      });
    }
    let chkA = 0;
    let chkB = 0;
    for (let i = 2; i < commands.length; i += 1) {
      chkA += getSignedByte(8, commands[i] || 0);
      chkB = (getSignedByte(8, chkB & 0xff) + (chkA & 0xff)) & 0xff;
    }
    commands.push(toBytesInt32(chkA)[0]);
    commands.push(chkB);

    const arrayBuffer = new ArrayBuffer(commands.length);
    const dataView = new DataView(arrayBuffer);
    let i = 0;
    commands.forEach(command => {
      dataView.setUint8(i, command);
      i += 1;
    });
    return dataView;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 0-indexed (0 for January, 11 for December)
    const day = currentDate.getDate();

    return `${year}_${month}_${day}`;
  };

  const getCurrentHour = () => {
    const currentDate = new Date();

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    return `${hours}_${minutes}_${seconds}`;
  };

  const getPostConfigURL = (source: string) => {
    if (source === 'base') return 'http://localhost:8080/base_mode';
    return 'https://xrtk.xmobots.com/api/config_rtk';
  };

  function makeConfigPost(
    source: string,
    requestOptions: RequestInit | undefined
  ) {
    const controller = new AbortController();
    const ms = source === 'base' ? 8000 : 8000;
    const timeoutId = setTimeout(() => controller.abort(), ms);
    const reqOpt = { ...requestOptions, signal: controller.signal };

    fetch(getPostConfigURL(source), reqOpt)
      .then(response => {
        clearTimeout(timeoutId);
        return response.text();
      })
      .then(data => {
        if (data !== 'Modo base configurado.') {
          notification.error({
            message: 'Erro',
            description:
              'Não foi possível realizar a captura, tente novamente!',
            className: 'error'
          });
        } else {
          notification.success({
            message: 'Sucesso',
            description: `Posição capturada com sucesso!`
          });
        }
        setIsLoading(false);
        closeModal();
        onPositionSucess(true);
      })
      .catch(error => {
        clearTimeout(timeoutId);
        console.log(error);
        // if (source === 'base') makeConfigPost('cloud', requestOptions);
        notification.error({
          message: 'Erro',
          description: 'Não foi possível realizar a captura, tente novamente!',
          className: 'error'
        });
        setIsLoading(false);
        closeModal();
        onPositionSucess(true);
      });
  }

  const validateFileName = (value: string) => {
    if (value.length === 0) return true;
    const pattern = /^[A-Za-z0-9_]+(?:\s+)?$/;

    return pattern.test(value);
  };

  const sendBaseConfig = async () => {
    if (mode === 0) {
      if (altitudeXRTK !== undefined) {
        form.setFieldsValue({
          'latitude-insert': latitude.toFixed(8),
          'longitude-insert': longitude.toFixed(8)
        });
        setAltitudeXRTK(Number(altitude.toFixed(3)));
      } else {
        form.setFieldsValue({
          'latitude-insert': latitude.toFixed(8),
          'longitude-insert': longitude.toFixed(8)
        });
        setAltitudeXRTK(Number(altitude.toFixed(3)));
        setAlturaAntena(0);
      }

      validateInputs();
    }
    const isValidFileName = validateFileName(fileName);

    if (!isValidFileName) {
      notification.error({
        message: 'Erro',
        description:
          'Nome do arquivo inválido, não utilize caracteres especiais.',
        className: 'error'
      });
      return;
    }

    setIsLoading(true);
    const fields = form.getFieldsValue();
    const fixBaseCommand = getFixBaseCommand(
      Number(fields['latitude-insert']),
      Number(fields['longitude-insert']),
      Number(altitude.toFixed(3)),
      alturaAntena
    );
    configGps('base', 'fix_base', fixBaseCommand);
    const formData = new URLSearchParams();
    if (alturaAntena) {
      const alturaArquivo = alturaAntena * 1000;
      formData.append(
        'nome',

        (fileName.length === 0 ? 'xrtk' : fileName) +
          '_H' +
          alturaArquivo.toString() +
          '_' +
          getCurrentDate() +
          '_' +
          getCurrentHour()
      );
    } else
      formData.append(
        'nome',

        (fileName.length === 0 ? 'xrtk' : fileName) + '_H0' + getCurrentDate()
      );

    formData.append('latitude', fields['latitude-insert'].toString());
    formData.append('longitude', fields['longitude-insert'].toString());

    formData.append('altitude', Number(altitude.toFixed(3)).toString());

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formData.toString()
    };

    // console.log(formData);

    makeConfigPost('base', requestOptions);
  };

  const positions = useRef({
    latitude: 0,
    longitude: 0,
    altitude: 0,
    total: 0,
    uuid: ''
  });

  const [isCapturing, setIsCapturing] = useState(false);

  const validateInputs = () => {
    const fields = form.getFieldsValue();
    setEnableConfig(false);

    if (
      fileName !== '' &&
      typeof fields['latitude-insert'] !== 'undefined' &&
      fields['latitude-insert'] !== null &&
      fields['latitude-insert'] !== '' &&
      typeof fields['longitude-insert'] !== 'undefined' &&
      fields['longitude-insert'] !== null &&
      fields['longitude-insert'] !== '' &&
      altitudeXRTK
    )
      setEnableConfig(true);
    if (
      fileName === '' &&
      typeof fields['latitude-insert'] !== 'undefined' &&
      fields['latitude-insert'] !== null &&
      fields['latitude-insert'] !== '' &&
      typeof fields['longitude-insert'] !== 'undefined' &&
      fields['longitude-insert'] !== null &&
      fields['longitude-insert'] !== '' &&
      altitudeXRTK
    ) {
      // setFileName(getCurrentDate);
      setEnableConfig(true);
    }
  };

  const showCounterPPP = () => {
    if (
      connectionStatus >= 2 &&
      modeType === 'Voo pós processado' &&
      Number(localStorage.getItem('timeConnected')) <= 900
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (isCapturing) {
      positions.current.latitude += latitude;
      positions.current.longitude += longitude;
      positions.current.altitude += altitude;
      positions.current.total += 1;
      positions.current.uuid = positionUuid;
    }
  }, [altitude, isCapturing, latitude, longitude, positionUuid]);

  useEffect(() => {
    validateInputs();
  }, [fileName]);

  return (
    <>
      <div style={{ display: mode === 1 ? 'flex' : 'none' }}>
        <Button
          className="cap-pos-button"
          disabled={false}
          key="send-corrections-button"
          type="primary"
          onClick={() => {
            form.setFieldsValue({
              'latitude-insert': latitude.toFixed(8),
              'longitude-insert': longitude.toFixed(8),
              'altitude-marcador-insert': Number(altitude.toFixed(3)) - 2.092
            });
            setAltitudeXRTK(Number(altitude.toFixed(3)));
            setAlturaAntena(2.092);

            validateInputs();
          }}
        >
          Capturar posição atual
        </Button>
      </div>
      <Form
        form={form}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        name="position-insert"
        scrollToFirstError
      >
        <Row gutter={20} style={{ display: 'flex', position: 'relative' }}>
          <Row
            style={{
              display: mode === 1 ? '' : 'none',
              marginLeft: mode === 1 ? '1rem' : ''
            }}
          >
            <Col span={8}>
              <Form.Item
                name="latitude-insert"
                label="Latitude (DD)"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  type="number"
                  className="pos_input"
                  min={-90}
                  max={90}
                  step={0.000001}
                  precision={8}
                  onChange={() => validateInputs()}
                  decimalSeparator="."
                  onPressEnter={e => handleLongEnterPress(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="longitude-insert"
                label="Longitude (DD)"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  type="number"
                  className="pos_input"
                  min={-180}
                  max={180}
                  step={0.000001}
                  precision={8}
                  onChange={() => validateInputs()}
                  decimalSeparator="."
                  ref={saveInputNumberRef}
                  onPressEnter={e => handleAlturaEnterPress(e)}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Altura antena (m):"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  className="pos_input"
                  min={0}
                  max={10000000000}
                  precision={3}
                  defaultValue={2.092}
                  onChange={e => {
                    setAltitudeXRTK(
                      Number(e) +
                        (Number(
                          form.getFieldValue('altitude-marcador-insert')
                        ) || 0)
                    );
                    setAlturaAntena(Number(e));
                    validateInputs();
                  }}
                  ref={saveInputAlturaNumberRef}
                  onPressEnter={e => handleSecondEnterPress(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="altitude-marcador-insert"
                label="Altitude marcador (m)"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  type="number"
                  className="pos_input"
                  step={1}
                  precision={3}
                  onChange={e => {
                    setAltitudeXRTK(alturaAntena + Number(e));
                    validateInputs();
                  }}
                  ref={saveInputAltitudeNumberRef}
                  onPressEnter={() => {
                    if (enableConfig) {
                      sendBaseConfig();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Altitude XRTK (m):"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  className="pos_input"
                  min={0}
                  max={10000000000}
                  precision={3}
                  value={altitudeXRTK}
                  defaultValue={altitudeXRTK}
                  disabled
                  style={{ color: 'darkgrey' }}
                />
                <Tooltip title="Altitude marcador + Altura antena">
                  <QuestionCircleOutlined
                    style={{
                      fontSize: '16px',
                      marginLeft: '0.3rem',
                      color: '#FFFFFFF2'
                    }}
                  />
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
          <Col
            span={8}
            style={{
              display: mode !== 1 ? '' : 'none'
            }}
          >
            <Form.Item
              label="Altura antena (m):"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <InputNumber
                className="pos_input"
                min={0}
                max={10000000000}
                precision={3}
                defaultValue={2.092}
                onChange={e => {
                  setAltitudeXRTK(
                    Number(e) +
                      (Number(form.getFieldValue('altitude-marcador-insert')) ||
                        0)
                  );
                  setAlturaAntena(Number(e));
                  validateInputs();
                }}
                onPressEnter={e => {
                  if (modeType === 'Coordenada manual' && enableConfig) {
                    sendBaseConfig();
                  } else handleEnterPress(e, fileNameRef);
                }}
              />
            </Form.Item>
          </Col>
          <div
            style={{
              display: modeType === 'Coordenada manual' ? 'none' : '',
              height: '4.4rem'
            }}
          >
            <Form.Item
              name="name-insert"
              label="Nome do arquivo:"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ marginBottom: '5rem', width: '80%' }}
            >
              <Input
                type="text"
                onChange={event => {
                  setFileName(event.target.value);
                  validateInputs();
                }}
                style={{
                  width: '100%'
                }}
                defaultValue=""
                className="pos_input"
                ref={fileNameRef}
                onPressEnter={e => {
                  if (enableConfig) sendBaseConfig();
                  else if (tutorial && mode === 0) sendBaseConfig();
                }}
              />
            </Form.Item>
          </div>
          {showCounterPPP() ? (
            <span className="counter-ppp">
              <p className="segmented_picker_p">
                <WarningFilled
                  className="segmented_picker_img"
                  style={{ fontSize: '1rem', marginRight: '0.2rem' }}
                />
                Aguarde{' '}
                {Math.floor(
                  (900 -
                    parseInt(localStorage.getItem('timeConnected') || '0')) /
                    60
                )}{' '}
                minuto
                {Math.floor(
                  (900 -
                    parseInt(localStorage.getItem('timeConnected') || '0')) /
                    60
                ) !== 1
                  ? 's'
                  : ''}{' '}
                e{' '}
                {Math.floor(
                  (900 -
                    parseInt(localStorage.getItem('timeConnected') || '0')) %
                    60
                )}{' '}
                segundo
                {Math.floor(
                  (900 -
                    parseInt(localStorage.getItem('timeConnected') || '0')) %
                    60
                ) !== 1
                  ? 's'
                  : ''}{' '}
                para realizar a configuração da base.
              </p>
            </span>
          ) : (
            <div />
          )}
          <div className={tutorial ? 'position-buttons-div' : 'position-div'}>
            {!tutorial && (
              <Button
                disabled={false}
                onClick={closeModal}
                className="base-card-btn"
                style={
                  modeType === 'Absoluto com internet'
                    ? {
                        width: '10rem'
                      }
                    : {}
                }
              >
                Cancelar
              </Button>
            )}
            {isLoading ? (
              <Button
                className="spin"
                disabled
                key="send-configuration-button"
                type="primary"
                style={{ border: 'none', background: '#2a2a2a' }}
              >
                <Spin className="spin" />
              </Button>
            ) : tutorial && mode === 0 ? (
              <div className="tutorial-div">
                <Button
                  type="primary"
                  onClick={() => onPositionSucess(false)}
                  className="back-button"
                >
                  Voltar
                </Button>
                <Button
                  className="base-card-btn-config"
                  key="send-configuration-button"
                  type="primary"
                  onClick={() => sendBaseConfig()}
                  disabled={showCounterPPP()}
                  style={{ marginLeft: '0.5rem' }}
                >
                  Configurar
                </Button>
              </div>
            ) : (
              <div className={tutorial ? 'tutorial-buttons-div' : ''}>
                {tutorial && (
                  <Button
                    onClick={() => onPositionSucess(false)}
                    className="back-button"
                  >
                    Voltar
                  </Button>
                )}

                <Button
                  disabled={!enableConfig}
                  key="send-configuration-button"
                  type="primary"
                  onClick={() => sendBaseConfig()}
                  className="base-card-btn"
                  style={
                    modeType === 'Absoluto com internet'
                      ? {
                          width: '10rem'
                        }
                      : {}
                  }
                >
                  {!enableSendCorrection ? 'Definir posição' : 'Reconfigurar'}
                </Button>
              </div>
            )}
          </div>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = ({ gps, websocket, layout }: ApplicationState) => ({
  latitude: Number(gps.position.latitude),
  longitude: Number(gps.position.longitude),
  altitude: gps.position.altitude,
  positionUuid: gps.position.uuid,
  enableSendCorrection: websocket.enableCorrection,
  modeType: layout.modeType,
  connectionStatus: websocket.connectionStatus
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(PositionPanel);
