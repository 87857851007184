export type DataCoord = {
  key?: string;
  id: number;
  name: string;
  lat: number;
  long: number;
  alt: number;
  errorLatitude: number;
  errorLongitude: number;
  errorAltitude: number;
  totalSatellites: number;
  solution: string;
  type: 'cinematic' | 'static' | 'snapshot';
  time: string;
};

export enum RoverTypes {
  CHANGE_COUNTDOWN_VISIBILITY = '@rover/CHANGE_COUNTDOWN_VISIBILITY ',
  CHANGE_SNAPSHOT_CAPTURING = '@rover/CHANGE_SNAPSHOT_CAPTURING',
  INSERT_DATA_ROVER_TABLE = '@rover/INSERT_DATA_ROVER_TABLE',
  REMOVE_ALL_DATA_ROVER_TABLE = '@rover/REMOVE_ALL_DATA_ROVER_TABLE',
  SET_SELECTED_POINT = '@rover/SET_SELECTED_POINT',
  REMOVE_POINT = '@rover/REMOVE_POINT',
  EDIT_POINT = '@rover/EDIT_POINT'
}

interface StartRover {
  type: typeof RoverTypes.CHANGE_COUNTDOWN_VISIBILITY;
}

interface RemoveAllDataRoverTable {
  type: typeof RoverTypes.REMOVE_ALL_DATA_ROVER_TABLE;
}

interface InsertDataRoverTableAction {
  type: typeof RoverTypes.INSERT_DATA_ROVER_TABLE;
  payload: {
    id: number;
    name: string;
    lat: number;
    long: number;
    alt: number;
    errorLatitude: number;
    errorLongitude: number;
    errorAltitude: number;
    totalSatellites: number;
    solution: string;
    type: 'cinematic' | 'static' | 'snapshot';
    time: string;
  };
}

interface RemoveDataRoverTableAction {
  type: typeof RoverTypes.REMOVE_POINT;
  payload: {
    key: string;
    type: 'cinematic' | 'static' | 'snapshot';
  };
}

interface EditDataRoverTableAction {
  type: typeof RoverTypes.EDIT_POINT;
  payload: {
    key: string;
    name: string;
    type: 'cinematic' | 'static' | 'snapshot';
  };
}

interface SetSelectedPointAction {
  type: typeof RoverTypes.SET_SELECTED_POINT;
  payload: {
    name: string;
    latitude: number;
    longitude: number;
    altitude: number;
  };
}

export type RoverActions =
  | StartRover
  | InsertDataRoverTableAction
  | RemoveAllDataRoverTable
  | SetSelectedPointAction
  | RemoveDataRoverTableAction
  | EditDataRoverTableAction;

export interface RoverState {
  countdownVisibility: boolean;
  dataRover: {
    cinematic: DataCoord[];
    static: DataCoord[];
    snapshot: DataCoord[];
  };
  selectedPoint: {
    name: string;
    latitude: number;
    longitude: number;
    altitude: number;
  };
}
