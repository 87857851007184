import React, { useEffect, useRef, useState } from 'react';
import { Progress, Button, Select, Spin, Modal, Carousel } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from '../../store/rootReducer';
import * as WebsocketActions from '../../store/websocket/actions';
import * as GpsAction from '../../store/gps/actions';

import passo1 from './images/passo1.jpg';
import passo2 from './images/passo2.jpg';
import passo3 from './images/passo31.jpg';
import passo31 from './images/passo3.jpg';
import passo4 from './images/passo4.jpg';
import passo5 from './images/passo5.jpg';
import passo6 from './images/passo6.jpg';
import passo8 from './images/passo8.jpg';
import passo7 from './images/passo7.jpg';
import passo22 from './images/passo22.jpg';
import passo23 from './images/passo23.jpg';
import removaDrone from './images/removaDrone.png';
import rtk from './images/rtk.png';
import wifi from './images/wifi.png';
import ntrip from './images/ntrip.png';
import hotspot from './images/hotspot.png';
import conHotspot from './images/conHotspot.png';
import coloqueDrone from './images/coloqueDrone.png';

import './style.css';

import CloseSpotsPanel from '../../components/base/panels/position/CloseSpotsPanel';

import PositionPanel from '../../components/base/panels/position/PositionPanel';
import DronePositionPanel from '../../components/base/panels/position/DronePositionPanel';
import NtripControl from '../ntrip-control/NtripControl';

interface Position {
  latitude: string;
  longitude: string;
  altitude: string;
  data: string;
  dist: string;
}

interface StateProps {
  modeType: string;
  connectionStatus: number;
  positions: Position[];
}

interface DispatchProps {
  connectToWebsocket(): void;

  selectGpsModel(model: string): void;
}

type Props = StateProps & DispatchProps;

const { Option } = Select;

const RelativoControl = ({
  modeType,
  connectionStatus,
  connectToWebsocket,
  selectGpsModel,
  positions
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const closeModal = () => {
    setIsModalVisible(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [current, setCurrent] = useState(0);
  const [buttonText, setButtonText] = useState('Pular instalação');
  const [buttonClass, setButtonClass] = useState('jump-button');
  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    // if (connectionStatus === 0) {
    //   selectGpsModel('184b');
    //   connectToWebsocket();
    // }
    if (positions.length === 0 && modeType === 'Relativo marcado')
      setShowButton(false);
    else setShowButton(true);
  }, [connectionStatus]);

  const handlePositionSucess = (data: boolean) => {
    if (data) {
      if (!(current < modeSteps.length - 1)) {
        history.push('/extra');
      } else {
        setCurrent(current + 1);

        if (
          current >= modeSteps.length - 2 &&
          modeType !== 'Coordenada manual'
        ) {
          setButtonText('Finalizar Instalação');
          setButtonClass('final-button');
        }

        setPercent(prevPercent => {
          const newPercent = prevPercent + 10;
          if (newPercent > 100) {
            return 100;
          }
          return newPercent;
        });
      }
    } else {
      setCurrent(current - 1);

      setButtonText('Pular instalação');
      setButtonClass('jump-button');

      setPercent(prevPercent => {
        const newPercent = prevPercent - 10;
        if (newPercent < 0) {
          return 0;
        }
        return newPercent;
      });
    }
    console.log('Dados recebidos do PositionPanel:', data);
  };

  const steps = [
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Encontre a marcação</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Encontre o marcador (estaca ou piquete) que você já marcou
            anteriormente no solo.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={passo22} alt="Marcação" className="img" />
    }, // modo 2 + 6 PASSO 0
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Marcação</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Finque um marcador (estaca ou piquete) no solo.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={passo1} alt="Marcação" className="img" />
    }, // modo 1 + 4 + 4.2 + 5 PASSO 1

    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Montagem</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">Monte o tripé.</p>
        </div>
      ),
      extra: true,
      image: <img src={passo23} alt="Marcação" className="img" />
    }, // modo 2 + 1 + 3 + 4 + 4.2 + 5  + 6 PASSO 2
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Nivelamento</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Rosquear o XRTK no bastão e Rosquear os bastões
          </p>
        </div>
      ),
      extra: true,
      image: (
        <Carousel autoplay className="img">
          <div>
            <img src={passo3} alt="Outra Marcação" className="img" />
          </div>
          <div>
            <img src={passo31} alt="Marcação" className="img" />
          </div>
        </Carousel>
      )
    }, // modo 2 + 1 + 3 + 4 + 4.2 + 5  + 6 PASSO 3
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Alinhamento do laser</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">Coloque o bastão no tripe.</p>
        </div>
      ),
      extra: true,
      image: <img src={passo4} alt="Marcação" className="img" />
    }, // modo 2 + 1 + 4 + 4.2 + 5 + 6 PASSO 4
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Instalação da base</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">Nivele o bastão com o nível da bolha.</p>
        </div>
      ),
      extra: true,
      image: <img src={passo5} alt="Marcação" className="img" />
    }, // modo 2 + 1 + 3 + 4 + 4.2 + 5 + 6 PASSO 5
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Ligando a base</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Ligando a XRTK.
            <br />
            Certifique-se de que a mesma está carregada. Para saber se está
            carregada, o LED deve estar piscando em verde. Lembre-se de carregar
            um dia antes com seu carregador portátil fornecido exclusivamente
            pela XMobots.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={passo6} alt="Marcação" className="img" />
    }, // modo 2 + 1 + 3 + 4 + 4.2 + 5 + 6 PASSO 6
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Conexão da base ao controle do drone</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Conecte o controle do drone na base XRTK utilizando o wifi, busque
            pela rede “XRTK” e insira a senha “12345678”.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={wifi} alt="Marcação" className="img" />
    }, // modo 2 + 1 + 3* + 4 + 4.2 + 5 + 6 PASSO 7
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Dados da captura</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            <span style={{ display: 'flex', height: '1rem' }}>
              Complete as informações abaixo para definir seu arquivo de
              captura.
            </span>
          </p>
          <PositionPanel
            closeModal={closeModal}
            tutorial={true}
            mode={0}
            onPositionSucess={handlePositionSucess}
          />
        </div>
      ),
      extra: false,
      image: false
    }, // modo 4 + 4.2 PASSO 8
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Escolha um novo local para a base</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Coloque o drone sobre o novo marcador (estaca ou piquete), para
            capturar a nova coordenada de avanço.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={coloqueDrone} alt="Marcação" className="img" />
    }, // modo 5 PASSO 9
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Definindo o novo local</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Confirme as coordenadas do seu drone abaixo como ponto de avanço,
            este será o novo local da sua base
            <DronePositionPanel
              closeModal={closeModal}
              tutorial={true}
              onPositionSucess={handlePositionSucess}
            />
          </p>
        </div>
      ),
      extra: false,
      image: false
    }, // modo 5 PASSO 10
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Marcação da base no novo local</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Finque um marcador (estaca ou piquete) no solo do novo local da sua
            base.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={passo1} alt="Marcação" className="img" />
    }, // modo 5 PASSO 11
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Instalação da base no novo local</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Remova o drone da nova posição a no mínimo 2m de distância.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={removaDrone} alt="Marcação" className="img" />
    }, // modo 5 PASSO 12
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Habilitando o hotspot ou wifi</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Compartilhe a internet do seu celular ou certifique-se de que esteja
            no alcance de uma rede wifi para continuar.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={hotspot} alt="Marcação" className="img" />,
      wifi: true
    }, // modo 3 caso ja nao esteja conectado PASSO 13
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Conecte a base à internet</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Preencha os campos abaixo para conectar sua base à internet.
            <NtripControl
              tutorialWifi={true}
              tutorialNTRIP={false}
              onPositionSucess={handlePositionSucess}
            />
          </p>
        </div>
      ),
      extra: false,
      wifi: true,
      image: false
    }, // modo 3 caso ja nao esteja conectado PASSO 14
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Login com NTRIP para acesso ao IBGE</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Preencha os campos abaixo para conectar sua base ao servidor do
            IBGE.
            <NtripControl
              tutorialWifi={false}
              tutorialNTRIP={true}
              onPositionSucess={handlePositionSucess}
            />
          </p>
        </div>
      ),
      extra: false,
      ntrip: true,
      image: false
    }, // modo 3 caso ja nao esteja conectado PASSO 15
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Insira sua coordenada</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Preencha os campos abaixo para concluir sua instalação.
            <PositionPanel
              closeModal={closeModal}
              tutorial={true}
              mode={1}
              onPositionSucess={handlePositionSucess}
            />
          </p>
        </div>
      ),
      extra: false,
      image: false
    }, // modo 6 PASSO 18
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Aguarde a captura</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Em 5 minutos o XRTK vai entrar em modo base e enviará correções
            automaticamente para o Drone.
            <br />
            Você pode observar este processo finalizando este tutorial.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={passo7} alt="Marcação" className="img" />
    }, // modo 1, modo 4 PASSO 19
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Pontos próximos encontrados</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Pontos próximos encontrados com base na sua última operação. Sua
            base usará como referência o primeiro da lista, o mais perto de
            você.
          </p>
          <span className="constelation">
            <CloseSpotsPanel />
          </span>
        </div>
      ),
      extra: true,
      image: false
    }, // modo 2, PASSO 20
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Aguarde a captura</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Aguarde no mínimo 2 horas para a captura gerar dados brutos.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={passo7} alt="Marcação" className="img" />
    }, // modo 4, passo 21
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Siga para o modo Relativo Marcado</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            1. Agora desligue o XRTK e faça a mudança de local.
            <br />
            2. Ligue a base no novo local e siga o passo a passo do modo
            relativo marcado.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={passo6} alt="Marcação" className="img" />
    } // modo transporte de base, passo 20
  ];

  const passoExtra = [
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Aguarde a captura</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Aguarde no mínimo 2 horas para a captura gerar dados brutos.
            Enquanto isso você pode operar seu drone normalmente.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={passo7} alt="Marcação" className="img" />,
      wifi: false
    },
    {
      title: (
        <div className="step-content-box">
          <p className="mode-text">Conecte-se a internet</p>
        </div>
      ),
      content: (
        <div className="step-content-box">
          <p className="description">
            Conecte-se a rede Wi-Fi ou Hotspot no seu controle.
          </p>
        </div>
      ),
      extra: true,
      image: <img src={conHotspot} alt="Marcação" className="img" />,
      wifi: false
    }
  ];

  let modeSteps = steps;
  let modeTittle = modeType;

  if (modeType === 'Relativo novo') {
    modeSteps = steps.filter(
      (_, index) =>
        (index > 0 && index < 8) || (index > 15 && index < 16) || index === 17
    );
    modeTittle = 'Relativo Novo';
  } else if (modeType === 'Relativo marcado') {
    modeSteps = steps.filter(
      (_, index) => (index !== 1 && index < 8) || index === 18
    );
    modeTittle = 'Relativo Marcado';
  } else if (modeType === 'Absoluto com internet') {
    modeSteps = steps.filter(
      (_, index) => (index > 1 && index < 8) || (index > 12 && index < 16)
    );
    modeSteps.splice(8, 0, passoExtra[1]);
    modeTittle = 'Absoluto (com internet)';
  } else if (modeType === 'Ponto pós-processado') {
    modeSteps = steps.filter(
      (_, index) => (index !== 0 && index < 9) || index === 19
    );

    modeTittle = 'Ponto pós-processado';
  } else if (modeType === 'Voo pós processado') {
    modeSteps = steps.filter(
      (_, index) => (index !== 0 && index < 9) || (index > 15 && index < 17)
    );
    modeSteps.splice(8, 0, passoExtra[0]);
    modeTittle = 'Voo pós processado';
  } else if (modeType === 'Transporte de base') {
    modeSteps = steps.filter(
      (_, index) =>
        (index !== 11 && index > 8 && index < 13) || index === 1 || index === 20
    );
    modeTittle = 'Transporte de base';
  } else if (modeType === 'Coordenada manual') {
    modeSteps = steps.filter(
      (_, index) => (index !== 1 && index < 8) || (index > 15 && index < 17)
    );
    modeTittle = 'Coordenada manual';
  }

  const next = () => {
    setCurrent(current + 1);

    if (current >= modeSteps.length - 2 && modeType !== 'Coordenada manual') {
      setButtonText('Finalizar Instalação');
      setButtonClass('final-button');
    }

    setPercent(prevPercent => {
      const newPercent = prevPercent + 10;
      if (newPercent > 100) {
        return 100;
      }
      return newPercent;
    });
  };

  const prev = () => {
    setCurrent(current - 1);

    setButtonText('Pular instalação');
    setButtonClass('jump-button');

    setPercent(prevPercent => {
      const newPercent = prevPercent - 10;
      if (newPercent < 0) {
        return 0;
      }
      return newPercent;
    });
  };

  const history = useHistory();

  return (
    <div className="page">
      <div className="page-header">
        <div className="progress-content">
          <Progress
            type="circle"
            percent={((current + 1) / modeSteps.length) * 100}
            strokeColor="#6AA944"
            width={48}
            format={() => (
              <span style={{ color: 'white' }}>
                {`${current + 1}/${modeSteps.length}`}
              </span>
            )}
          />
          <div className="progress-content-text">
            {modeSteps[current].title}

            <p className="intro-text">
              Modo
              <span className="intro-text" style={{ marginLeft: '0.2rem' }}>
                {modeTittle}
              </span>
            </p>
          </div>
        </div>
        {!(modeTittle === 'Absoluto (com internet)' && current === 9) && (
          <Button
            onClick={async e => {
              if (modeType === 'Relativo marcado' && current === 7) {
                Modal.confirm({
                  title: (
                    <span style={{ color: 'white' }}>Configure seu drone</span>
                  ),
                  content: (
                    <div style={{ width: '26rem' }}>
                      <img
                        src={rtk}
                        alt="Marcação"
                        className="img"
                        style={{ width: '200px' }}
                      />

                      <p style={{ color: 'white' }}>
                        Acesse o aplicativo DJI Pilot 2 (Modelo Mavic) ou DJI
                        Agras (T40), clique no ícone de configuração, acesse a
                        aba RTK e ative o sistema.
                      </p>
                    </div>
                  ),
                  okText: 'Próximo',
                  cancelText: 'Cancelar',
                  okButtonProps: {
                    className: 'next-button'
                  },
                  cancelButtonProps: {
                    className: 'back-button'
                  },
                  onOk() {
                    Modal.confirm({
                      title: '',
                      content: (
                        <div>
                          <img src={ntrip} alt="Marcação" className="img" />

                          <p style={{ color: 'white' }}>
                            Busque pela aba NTRIP e insira as seguintes
                            credenciais:
                            <ul>
                              <li>IP: 127.0.0.1</li>
                              <li>Porta: 2101</li>
                              <li>Usuário: admin</li>
                              <li>Senha: admin</li>
                            </ul>
                          </p>
                        </div>
                      ),
                      okText: 'Próximo',
                      cancelText: 'Cancelar',
                      okButtonProps: {
                        className: 'next-button'
                      },
                      cancelButtonProps: {
                        className: 'back-button'
                      },
                      onOk() {
                        if (modeType === 'Relativo marcado') {
                          e.preventDefault();
                          history.push('/extra');
                        } else next();
                      },
                      onCancel() {
                        closeModal();
                      }
                    });
                  },
                  onCancel() {
                    closeModal();
                  }
                });
              } else if (modeType === 'Transporte de base') {
                e.preventDefault();
                history.push('/');
              } else {
                e.preventDefault();
                history.push('/extra');
              }
            }}
            className={buttonClass}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <div className="app">
        <div className="steps-box">
          <div style={{ width: '100%' }}>{modeSteps[current].content}</div>

          <div className="image-box" style={{ position: 'relative' }}>
            <div className="image-box" style={{ position: 'relative' }}>
              {modeSteps[current].image && (
                <>
                  {modeSteps[current].extra && current > 0 && (
                    <Button
                      className="arrow-btn"
                      icon={<ArrowLeftOutlined className="arrow" />}
                      onClick={() => prev()}
                      disabled={!(current > 0)}
                      style={{
                        position: 'absolute',
                        top: '45%',
                        left: '10px'
                      }}
                    />
                  )}
                  <div className="steps-content">
                    {modeSteps[current].image}
                  </div>
                  {modeSteps[current].extra && (
                    <Button
                      className="arrow-btn"
                      icon={<ArrowRightOutlined className="arrow" />}
                      onClick={e => {
                        if (
                          ((modeType === 'Relativo novo' ||
                            modeType === 'Coordenada manual') &&
                            current === 6) ||
                          (modeType === 'Voo pós processado' &&
                            current === 8) ||
                          (modeType === 'Relativo marcado' && current === 7)
                        ) {
                          Modal.confirm({
                            title: (
                              <span style={{ color: 'white' }}>
                                Configure seu drone
                              </span>
                            ),
                            content: (
                              <div style={{ width: '26rem' }}>
                                <img src={rtk} alt="Marcação" className="img" />

                                <p style={{ color: 'white' }}>
                                  Acesse o aplicativo DJI Pilot 2 (Modelo Mavic)
                                  ou DJI Agras (T40), clique no ícone de
                                  configuração, acesse a aba RTK e ative o
                                  sistema.
                                </p>
                              </div>
                            ),
                            okText: 'Próximo',
                            cancelText: 'Cancelar',
                            okButtonProps: {
                              className: 'next-button'
                            },
                            cancelButtonProps: {
                              className: 'back-button'
                            },
                            onOk() {
                              Modal.confirm({
                                title: '',
                                content: (
                                  <div>
                                    <img
                                      src={ntrip}
                                      alt="Marcação"
                                      className="img"
                                    />

                                    <p style={{ color: 'white' }}>
                                      Busque pela aba NTRIP e insira as
                                      seguintes credenciais:
                                      <ul>
                                        <li>IP: 127.0.0.1</li>
                                        <li>Porta: 2101</li>
                                        <li>Usuário: admin</li>
                                        <li>Senha: admin</li>
                                      </ul>
                                    </p>
                                  </div>
                                ),
                                okText: 'Próximo',
                                cancelText: 'Cancelar',
                                okButtonProps: {
                                  className: 'next-button'
                                },
                                cancelButtonProps: {
                                  className: 'back-button'
                                },
                                onOk() {
                                  if (
                                    modeType === 'Voo pós processado' ||
                                    modeType === 'Relativo marcado'
                                  ) {
                                    e.preventDefault();
                                    history.push('/extra');
                                  } else next();
                                },
                                onCancel() {
                                  closeModal();
                                }
                              });
                            },
                            onCancel() {
                              closeModal();
                            }
                          });
                        } else if (modeSteps[current].wifi) {
                          if (current === 6) {
                            Modal.confirm({
                              title: (
                                <span style={{ color: 'white' }}>
                                  Confirmação
                                </span>
                              ),
                              content: (
                                <span style={{ color: 'white' }}>
                                  Você irá usar uma rede WiFi já configurada?
                                </span>
                              ),
                              okText: 'Sim',
                              cancelText: 'Não',
                              okButtonProps: {
                                className: 'next-button'
                              },
                              cancelButtonProps: {
                                className: 'back-button'
                              },

                              onOk() {
                                setCurrent(current + 2);

                                setPercent(prevPercent => {
                                  const newPercent = prevPercent + 20;
                                  return newPercent;
                                });
                              },
                              onCancel() {
                                next();
                              }
                            });
                          }
                        } else if (
                          modeType === 'Transporte de base' &&
                          !(current < modeSteps.length - 1)
                        ) {
                          e.preventDefault();
                          history.push('/');
                        } else if (!(current < modeSteps.length - 1)) {
                          e.preventDefault();
                          history.push('/extra');
                        } else {
                          next();
                        }
                      }}
                      style={{
                        position: 'absolute',
                        top: '45%',
                        right: '10px'
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <div
              className={
                modeSteps[current].image
                  ? 'buttons-step-box'
                  : current === 7 && modeType === 'Relativo marcado'
                  ? 'buttons-step-box-table'
                  : ''
              }
            >
              {!(
                (modeTittle === 'Ponto pós-processado' && current === 7) ||
                (modeTittle === 'Voo pós processado' && current === 7) ||
                (modeTittle === 'Transporte de base' && current === 2) ||
                (modeTittle === 'Coordenada manual' && current === 7) ||
                (modeTittle === 'Absoluto (com internet)' &&
                  (current === 7 || current === 9))
              ) && (
                <Button
                  onClick={e => {
                    if (current === 0) {
                      e.preventDefault();
                      history.push('/');
                    } else prev();
                  }}
                  className="back-button"
                >
                  Voltar
                </Button>
              )}

              {modeSteps[current].extra && current < modeSteps.length - 1 && (
                <Button
                  onClick={async e => {
                    if (!(current < modeSteps.length - 1)) {
                      e.preventDefault();
                      history.push('/extra');
                    } else if (
                      ((modeType === 'Relativo novo' ||
                        modeType === 'Coordenada manual') &&
                        current === 6) ||
                      (modeType === 'Voo pós processado' && current === 8) ||
                      (modeType === 'Relativo marcado' && current === 7)
                    ) {
                      Modal.confirm({
                        title: (
                          <span style={{ color: 'white' }}>
                            Configure seu drone
                          </span>
                        ),
                        content: (
                          <div style={{ width: '26rem' }}>
                            <img src={rtk} alt="Marcação" className="img" />

                            <p style={{ color: 'white' }}>
                              Acesse o aplicativo DJI Pilot 2 (Modelo Mavic) ou
                              DJI Agras (T40), clique no ícone de configuração,
                              acesse a aba RTK e ative o sistema.
                            </p>
                          </div>
                        ),
                        okText: 'Próximo',
                        cancelText: 'Cancelar',
                        okButtonProps: {
                          className: 'next-button'
                        },
                        cancelButtonProps: {
                          className: 'back-button'
                        },
                        onOk() {
                          Modal.confirm({
                            title: '',
                            content: (
                              <div>
                                <img
                                  src={ntrip}
                                  alt="Marcação"
                                  className="img"
                                />

                                <p style={{ color: 'white' }}>
                                  Busque pela aba NTRIP e insira as seguintes
                                  credenciais:
                                  <ul>
                                    <li>IP: 127.0.0.1</li>
                                    <li>Porta: 2101</li>
                                    <li>Usuário: admin</li>
                                    <li>Senha: admin</li>
                                  </ul>
                                </p>
                              </div>
                            ),
                            okText: 'Próximo',
                            cancelText: 'Cancelar',
                            okButtonProps: {
                              className: 'next-button'
                            },
                            cancelButtonProps: {
                              className: 'back-button'
                            },
                            onOk() {
                              if (
                                modeType === 'Voo pós processado' ||
                                modeType === 'Relativo marcado'
                              ) {
                                e.preventDefault();
                                history.push('/extra');
                              } else next();
                            },
                            onCancel() {
                              closeModal();
                            }
                          });
                        },
                        onCancel() {
                          closeModal();
                        }
                      });
                    } else if (modeSteps[current].wifi) {
                      if (current === 6) {
                        Modal.confirm({
                          title: (
                            <span style={{ color: 'white' }}>Confirmação</span>
                          ),
                          content: (
                            <span style={{ color: 'white' }}>
                              Você irá usar uma rede WiFi já configurada?
                            </span>
                          ),
                          okText: 'Sim',
                          cancelText: 'Não',
                          okButtonProps: {
                            className: 'next-button'
                          },
                          cancelButtonProps: {
                            className: 'back-button'
                          },

                          onOk() {
                            setCurrent(current + 2);

                            setPercent(prevPercent => {
                              const newPercent = prevPercent + 20;
                              return newPercent;
                            });
                          },
                          onCancel() {
                            next();
                          }
                        });
                      }
                    } else {
                      next();
                    }
                  }}
                  className="next-button"
                  style={
                    isLoading ? { border: 'none', background: 'none' } : {}
                  }
                >
                  {isLoading ? <Spin /> : 'Avançar'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ layout, websocket }: ApplicationState) => ({
  modeType: layout.modeType,
  connectionStatus: websocket.connectionStatus,
  positions: websocket.positions
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions, ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(RelativoControl);
