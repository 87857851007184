/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  Row,
  PageHeader,
  Card,
  Badge,
  Spin,
  Button,
  message,
  Modal,
  Progress,
  notification
} from 'antd';

import {
  ThunderboltOutlined,
  BarsOutlined,
  WarningOutlined,
  LoadingOutlined,
  UploadOutlined
} from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
import { ApplicationState } from '../../store/rootReducer';
import * as WebsocketActions from '../../store/websocket/actions';
import * as GpsAction from '../../store/gps/actions';
import {
  ConfigurationTypes,
  ConfigurationLocationTypes
} from '../../types/Gps';
import store from '../../index';

import './style.css';
import { WebsocketTypes } from '../../types/Websocket';

interface DispatchProps {
  configGps(
    location: ConfigurationLocationTypes,
    type: ConfigurationTypes
  ): void;
  selectGpsModel(model: string): void;
}
interface StateProps {
  connectionStatus: number;
  gpsModel: string | null;
  fixBaseHotspot: number;
  responseIbge: string;
  openIBGEsocket: number;
}

type Props = StateProps & DispatchProps;

const Processamento = ({
  connectionStatus,
  selectGpsModel,
  fixBaseHotspot,
  responseIbge,
  openIBGEsocket
}: Props) => {
  const [uploading, setUploading] = useState(false);
  const [modalPage, setModalPage] = useState(0);

  const [url, setUrl] = useState('');

  const [statusMessage, setStatusMessage] = useState('');
  const [lastFetch, setStatusLastFetch] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [uploadToServer, setUploadToServer] = useState(false);
  const [uploadToBase, setUploadToBase] = useState(false);
  const [error, setError] = useState(false);

  const [downloadStatus, setDownloadStatus] = useState(0);
  let downloadTimeout;

  const abortController = new AbortController();
  const { signal } = abortController;

  const handleCancel = () => {
    store.dispatch({
      type: WebsocketTypes.CLOSE_IBGE_SOCKET
    });

    abortController.abort();

    setUploading(false);
    setUploadToServer(false);
    setDownloadStatus(0);
    setUrl('');

    setStatusMessage('');
    setStatusLastFetch(false);
    setNewUrl('');
  };

  const atualizarStatus = () => {
    if (responseIbge) {
      if (responseIbge.startsWith('UUID:')) {
        setDownloadStatus(20);
        setStatusMessage('Enviando para o servidor da xmobots');
      } else if (responseIbge.startsWith('COORD:')) {
        setDownloadStatus(100);
        setStatusMessage('Aguarde cerca de 8 minutos o download do arquivo...');
      } else {
        switch (responseIbge) {
          case 'Baixando a coordenada de base pós processada':
            setDownloadStatus(100);
            break;
          case 'Pós processando a coordenada':
            setDownloadStatus(80);
            break;
          case 'Enviando para o IBGE':
            setDownloadStatus(60);
            break;
          case 'Processando binário':
            setDownloadStatus(40);
            break;
          case 'Enviando para o servidor da xmobots':
            setDownloadStatus(20);
            break;
          default:
            console.log('mensagem inesperada...');
        }
      }
    }
  };

  function downloadBlob(blob: any, name = 'respostaIBGE.zip') {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  const handleCoordToBaseUpload = () => {
    if (newUrl !== '' && lastFetch) {
      setUploadToBase(true);

      const newRequestOptions: RequestInit = {
        method: 'POST'
      };

      fetch(newUrl, newRequestOptions)
        .then(response => {
          setStatusMessage('Corrigindo a coordenada no XRTK');
          setStatusMessage(
            'Pronto, a coordenada pós processada foi corrigida.'
          );
          notification.success({
            message: 'Sucesso',
            description: `Coordenada corrigida`
          });
        })
        .catch(error => {
          notification.error({
            message: 'Falha',
            description: `Falha no envio das cordenadas para a base`
          });
          handleCancel();
          setUploading(false);
          setUploadToServer(false);
          setDownloadStatus(0);
          setUrl('');

          setStatusMessage('');
          setStatusLastFetch(false);
          setNewUrl('');
        })
        .finally(() => {
          setStatusMessage('Download Concluido.');

          setDownloadStatus(100);
          setUploading(false);
          setUploadToServer(false);

          setUrl('');

          setStatusLastFetch(false);
          setNewUrl('');

          store.dispatch({
            type: WebsocketTypes.CLOSE_IBGE_SOCKET
          });
        });
    }
  };

  const handleFileToServerUpload = () => {
    if (file && url) {
      setUploadToServer(true);
      const fileName = file.name;
      const timestampRegex = /_H\d{4}_\d{4}_\d{1,2}_\d{1,2}_\d{1,2}_\d{1,2}_\d{1,2}(\.bin)?$/;
      const timestampInFileName = fileName.match(timestampRegex);

      if (!timestampInFileName) {
        message.error(
          'O nome do arquivo deve conter um timestamp no formato nome_Hxxxx_aaaa_mm_dd_hh_mm_ss'
        );
        store.dispatch({
          type: WebsocketTypes.CLOSE_IBGE_SOCKET
        });
        handleCancel();
        return;
      }

      const timestamp = fileName.split('_');

      const [
        x,
        alturaAntena,
        y,
        dataPrefix,
        data,
        horaPrefix,
        hora
      ] = timestamp;

      const [year, month, day] = data.split('-').map(Number);
      const [hour, minute] = hora.split('-').map(Number);

      const fileTime = new Date(year, month - 1, day, hour, minute, 0);
      const currentTime = new Date();

      const timeDifferenceInMilliseconds = Math.abs(
        currentTime.getTime() - fileTime.getTime()
      );
      const timeDifferenceInHours =
        timeDifferenceInMilliseconds / (1000 * 60 * 60);

      if (timeDifferenceInHours < 3) {
        message.error(
          'Aguarde pelo menos 3 horas para enviar para o nosso servidor.'
        );
        return;
      }

      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          Authorization: 'Basic fa6e3a7a-9eb8-4f40-967c-6d83c71d98b7'
        },
        body: formData
      };
      fetch(url, { ...requestOptions, signal })
        .then(response => {
          if (response.status !== 200) {
            message.error('Falha no upload do arquivo para o servidor');
            setError(true);
            return;
          }

          if (signal.aborted) {
            return;
          } else return response.blob();
        })
        .catch(error => {
          if (signal.aborted) {
            return;
          }
          message.error('Falha no upload do arquivo para o servidor');
          notification.error({
            message: 'Falha',
            description: `Falha no upload do arquivo para o servidor.`
          });

          handleCancel();
        })
        .then(blob => {
          if (signal.aborted) {
            return;
          }
          if (!error) {
            notification.success({
              message: 'Sucesso',
              description: `Upload do arquivo finalizado`
            });
          }

          downloadBlob(blob);

          if (!(fixBaseHotspot > 0)) {
            setDownloadStatus(100);
            setUploading(false);
            setUploadToServer(false);

            setUrl('');

            setStatusLastFetch(true);
            setNewUrl('');
          } else setStatusLastFetch(true);
        })
        .catch(error => {
          if (signal.aborted) {
            return;
          }
          notification.error({
            message: 'Falha',
            description: `Falha no upload do arquivo para o servidor.`
          });

          handleCancel();
        })
        .finally(() => {
          store.dispatch({
            type: WebsocketTypes.CLOSE_IBGE_SOCKET
          });
          setUploading(false);
          setUploadToServer(false);
          setDownloadStatus(0);
          setUrl('');

          setStatusMessage('');
          setStatusLastFetch(false);
          setNewUrl('');
        });
    }
  };

  const handleStartSocket = async () => {
    setDownloadStatus(0);
    setStatusMessage('');
    store.dispatch({
      type: WebsocketTypes.OPEN_IBGE_SOCKET
    });
    setUploading(true);
    setUploadToServer(false);
    atualizarStatus();
  };

  useEffect(() => {
    store.dispatch({
      type: WebsocketTypes.CLOSE_IBGE_SOCKET
    });

    abortController.abort();
    setUploading(false);
    setUploadToServer(false);
    setDownloadStatus(0);
    setUrl('');

    setStatusMessage('');
    setStatusLastFetch(false);
    setNewUrl('');
  }, []);

  useEffect(() => {
    if (file && url !== '' && !uploadToServer) {
      handleFileToServerUpload();
    }

    if (newUrl !== '' && lastFetch && !uploadToBase) {
      handleCoordToBaseUpload();
    }
  }, [file, url, lastFetch, newUrl]);

  useEffect(() => {
    if (responseIbge && responseIbge !== '') {
      const parts = responseIbge.split(':');
      if (parts[0] === 'UUID' && parts[1]) {
        atualizarStatus();

        setUrl(`https://xrtk.xmobots.com/api/rinex/${parts[1]}`);
      } else if (parts[0] === 'COORD' && parts[1]) {
        atualizarStatus();
        const coords = parts[1].split(';');

        setNewUrl(
          `http://localhost:8080/add_adv_pos?lat=${coords[0]}&lon=${coords[1]}&alt=${coords[2]}&mode=PPP`
        );
      } else {
        atualizarStatus();
        setStatusMessage(responseIbge);
      }
    }
  }, [responseIbge]);

  return (
    <>
      {/* {fixBaseHotspot > 0 ? ( */}
      <PageHeader title="Enviar para IBGE">
        <p className="header-subtitle">
          Aqui você pode enviar sua coordenada pós-processada para que ela seja
          corrigida.
        </p>

        <Row>
          <Button
            className="trash-button"
            onClick={() => {
              const input = document.createElement('input');
              input.type = 'file';
              input.onchange = event => {
                const selectedFile = (event.target as HTMLInputElement | null)
                  ?.files?.[0];
                if (selectedFile) {
                  setFile(selectedFile);
                  handleStartSocket();
                }
              };
              input.click();
            }}
            disabled={uploading}
          >
            {uploading ? (
              <span style={{ color: 'white' }}>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        color: 'white',
                        marginRight: '0.4rem'
                      }}
                      spin
                    />
                  }
                />
                Processando...
              </span>
            ) : (
              <>
                <UploadOutlined />
                Enviar Arquivo
              </>
            )}
          </Button>
          {uploading && (
            <Button
              className="trash-button"
              onClick={() => {
                handleCancel();
              }}
              style={{
                marginLeft: '1rem'
              }}
            >
              Cancelar
            </Button>
          )}

          <Progress
            className="progess-message"
            percent={downloadStatus}
            strokeColor="#6aa944"
          />
          <p className="progess-message">{statusMessage}</p>
        </Row>
      </PageHeader>
      {/* ) : ( */}
      {/* <PageHeader title="Estado do XRTK">
        <p className="header-subtitle">
          Sua base precisa estar conectada ao wi-fi para continuar.
        </p>
        <Button onClick={handleFailClick} className="next-button">
          Verifique o estado de conexão
        </Button>
      </PageHeader> */}
      {/* )} */}
    </>
  );
};
const mapStateToProps = ({ websocket, gps }: ApplicationState) => ({
  connectionStatus: websocket.connectionStatus,
  gpsModel: gps.model,
  fixBaseHotspot: websocket.fixBaseHotspot,
  responseIbge: websocket.responseIbge,
  openIBGEsocket: websocket.openIBGEsocket
});

const mapDispacthToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WebsocketActions, ...GpsAction }, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Processamento);
